
.notification-list>div {
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid rgb(230, 230, 230);
    padding: 10px 0;

    .title {
        font-weight: 500;
        font-size: 1.1em;
        color: black;
    }

    .body {
        font-size: 1em;
    }

    .icon {
        min-width: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: .95em;
        line-height: 2.2;
    }

    .actions {
        display: flex;
        gap: 5px;
    }

    .btn {
        margin: 0;
    }

    &.unread {
        .title {
            font-weight: 900;
        }

        .icon {
            background: #2a5788;
            color: white;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    flex-grow: 1;
    gap: 1px;
}

.metadata {
    display: flex;
    gap: 5px;
    align-items: center;
}

.small-layout {
    .primary {
        flex-direction: column;
    }

    .content {
        flex-direction: column;
        gap: 0;
        line-height: 1.5;
        font-size: .9em;
    }

    .notification-list>div {
        margin-bottom: 6px;
    }
}

.scroll {
    margin: -8px;

    .notification-list {
        max-height: clamp(150px, 45vh, 800px);
        overflow-y: auto;

        padding: 6px;
    }
}
