<template>
    <div class="patient-dashboard-view">
        <card title="Patient Dashboard">
            <b-row align-v="end">
                <b-col>
                    <SearchComponent
                        v-model="patient"
                        :label="`Search`"
                        search-u-r-l="/Patient"
                        :shouldFocus="searchShouldFocus"
                        create-new-object
                        create-new-label="Create New Patient"
                        @createNewObject="showPatientForm = true"
                    />
                </b-col>
                <b-col cols="1">
                    <b-button block class="my-0" variant="danger" @click="clearClick">
                        Clear
                    </b-button>
                </b-col>
            </b-row>
            <b-modal v-model="showPatientForm"
                     size="xl"
                     hide-header-close
                     hide-footer
                     @hidden="patientFormHidden">
                <PatientFormComponent
                    :button-titles="patientButtonTitles"
                    create-new-patient
                    hide-insurance
                    @close="showPatientForm = false"
                    @created="onPatientCreated"></PatientFormComponent>
            </b-modal>
        </card>
        <b-row>
            <b-col>
                <b-alert v-model="showPatientAlert" dismissible variant="danger">
                    {{ patientAlertText }}
                </b-alert>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-alert v-model="showCasesNotification" dismissible style="color: black" variant="warning">
                    There are open or recently closed patient cases:
                    <b-link href="#" @click.prevent="scrollCaseHistoryIntoView" style="color: black">
                        Click here to view.
                    </b-link>
                </b-alert>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-alert v-model="showActiveTransferAlert" dismissible style="color: black">
                    This patient has an active rx transfer request:
                    <a v-b-modal="'RxTransferListModal'" href="#" style="color: black">Click here to view.</a>
                </b-alert>
            </b-col>
        </b-row>

        <!-- Doctor Follow-up alert -->
        <DoctorFollowUpCasesAlertComponent v-if="patient && patient.id" :patientId="+patient.id">
        </DoctorFollowUpCasesAlertComponent>

        <b-row v-if="patient.id">
            <b-col cols="4">
                <card id="patient-demo-card">
                    <b-row>
                        <b-col cols="9">
                            <h4 class="card-subtitle">{{ patientName }}</h4>
                            <b-alert variant="danger">Danger Alert</b-alert>
                        </b-col>
                        <b-col class="text-right">
                            <router-link :to="{
                                name: 'PatientDetails',
                                params: { id: patient.id },
                            }">
                                <span>Edit</span> <i class="fa fa-edit" />
                            </router-link>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-dropdown id="patientActions" size="sm" text="Actions" variant="info">
                                <b-dropdown-item v-if="!outboundCallOptOut" @click="ChangeOutboundOptOut(true)">
                                    Opt Out Of Refill Calls
                                </b-dropdown-item>
                                <b-dropdown-item v-if="outboundCallOptOut" @click="ChangeOutboundOptOut(false)">
                                    Opt Into Refill Calls
                                </b-dropdown-item>
                                <b-dropdown-item @click="SendInsuranceRequest">Send Insurance Request
                                </b-dropdown-item>
                                <b-dropdown-item @click="StartRxTransfer">Start Transfer...
                                </b-dropdown-item>
                                <b-dropdown-item @click="StartIRSReport">Send IRS Report...
                                </b-dropdown-item>
                                <b-dropdown-item @click="openNewConsultationModal">New Consultation...
                                </b-dropdown-item>
                                <b-dropdown-item v-b-modal.new-rx-request-fax>Request New Rx...
                                </b-dropdown-item>
                                <b-dropdown-item v-b-modal.patient_documents_modal>Documents...
                                </b-dropdown-item>
                                <b-dropdown-item v-b-modal.online-orders-modal>Online Orders...
                                </b-dropdown-item>
                                <b-dropdown-item @click="sendNpcText(false)">Send Npc Text
                                </b-dropdown-item>
                                <!--                                <b-dropdown-item @click="sendNpcText(true)">Send Npc Text (DermaDash)</b-dropdown-item>-->
                                <b-dropdown-item @click="sendRefillText(false)">Send Refill Text
                                </b-dropdown-item>
                                <!--                                <b-dropdown-item @click="sendRefillText(true)">Send Refill Text (DermaDash)</b-dropdown-item>-->
                                <b-dropdown-item @click="showPapTextModal">Send Pap Text...</b-dropdown-item>
                                <b-dropdown-item v-b-modal.pharmacy-text-modal>Send Pharmacy Info
                                    Text...</b-dropdown-item>
                                <b-dropdown-item @click="openCovidModal">Create COVID Test Script...</b-dropdown-item>
                                <b-dropdown-item @click="paymentMethodsModal">Payment Methods...</b-dropdown-item>
                                <b-dropdown-item @click="resetPatientAppModal"
                                    v-if="patient.patientAppUserName != null">
                                    Reset Patient App Password...
                                </b-dropdown-item>
                                <b-dropdown-item v-b-modal.duplicate-modal>
                                    Is Duplicate...
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                        <b-col class="text-right">
                            <a v-if="hasOldPortal" :href="oldPortalLink" target="_blank">View in Old Portal
                                <i class="fas fa-external-link-alt"></i></a>
                        </b-col>
                    </b-row>
                    <hr />

                    <PatientDemographicsComponent v-model="patient"></PatientDemographicsComponent>
                </card>
                <card>
                    <!-- Notes -->
                    <h4 class="card-subtitle">Notes</h4>
                    <NotesForm v-if="patient" :entityId="+patient.id" :entityType="patientEntityType"
                        @saved="refreshNotesList"></NotesForm>
                    <NotesList ref="patientNotesList" :entityId="+patient.id" :entityType="patientEntityType"
                        :pageSize="5"></NotesList>
                </card>
                <card>
                    <h4 class="card-subtitle">Shipments</h4>
                    <a v-if="hasOldPortal" :href="oldPortalLink" target="_blank">View in Old Portal
                        <i class="fas fa-external-link-alt"></i></a>
                    <!-- Shipments -->
                    <div v-if="patientShipments.length > 0">
                        <card v-for="shipment in patientShipments" :key="shipment.id">
                            <b>Shipped: </b>{{ convertDate(shipment.verified) }}
                            <br />
                            <b>Shipment Type: </b>{{ shipment.shipmentType }}
                            <br />
                            <b>Tracking: </b><b-link :href="shipment.trackingUrl" target="_blank">{{
                                shipment.trackingNumber }}</b-link>
                            <br />
                            <a href="" @click.prevent="showShippingTicket(shipment)">Shipping Ticket</a>
                            <br />
                            <div v-if="shipment.returnDate" class="text-center bg-warning">
                                <b>RETURNED: </b>{{ convertDate(shipment.returnDate) }}
                            </div>
                        </card>
                    </div>
                    <div v-else>No Shipments On Record</div>
                </card>

                <card>
                    <b-row>
                        <b-col>
                            <!-- Insurance Plans -->
                            <h5>Insurance Plans</h5>
                            <PatientPlansComponent v-model="patient" />
                        </b-col>
                    </b-row>
                </card>
                <emergency-contact-list id="emergencyContacts" :patient="patient"></emergency-contact-list>
                <emergency-contact-list id="linkedPatients" :patient="patient"
                                        :getByContactMode="true"></emergency-contact-list>

                <card>
                    <h4 class="card-subtitle">Allergies</h4>
                    <b-row>
                        <b-col>
                            <!-- ALLERGIES -->
                            <ul v-if="allergies && allergies.length > 0">
                                <li v-for="a in allergies" :key="allergies.indexOf(a)">
                                    {{
                                        a.allergyName +
                                        " - " +
                                        (a.allergyType == 0
                                            ? "Class"
                                            : "Ingredient")
                                    }}
                                </li>
                            </ul>
                            <div v-else>No known drug allergies</div>
                        </b-col>
                    </b-row>
                </card>
                <!-- INSURANCE IMAGES -->
                <card v-if="insuranceUploaded">
                    <b-row>
                        <b-col>
                            <h4 class="card-subtitle">Insurance Upload</h4>
                            <a v-b-modal="'uploadInsuranceModal'" href="#" style="color: black">Uploaded: {{
                                insuranceUploadedDisplayDate }}</a>
                        </b-col>
                    </b-row>
                </card>
                <!-- CASE HISTORY -->
                <card id="patient-case-history-card">
                    <b-row>
                        <b-col>
                            <h4 class="card-subtitle">Case History</h4>
                            <CasesListComponent :default-items="casesList" small hide-patient show-status
                                :page-size="10" @update="getCasesForPatient" />
                        </b-col>
                    </b-row>
                </card>
            </b-col>

            <b-col cols="8">
                <!-- ORDERS -->
                <card>
                    <b-row>
                        <b-col>
                            <h4 class="card-subtitle">Orders</h4>
                        </b-col>
                        <b-col class="text-right" variant="primary">
                            <!-- <router-link :to="{name:'OrderDetails', params:{orderId:  props.row.id}}"> -->
                            <router-link :to="{
                                name: 'Order',
                                params: { patientId: patient.id },
                            }">
                                <span>New Order&nbsp;</span>
                                <i class="fas fa-plus-circle"></i>
                            </router-link>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <SearchComponent v-model="filterStore" :label="`Pharmacy`" :option-label="storeLabel"
                                :search-u-r-l="`/Store`" clearable prepopulate @input="loadOrders(patient.id)">
                            </SearchComponent>
                        </b-col>
                        <b-col class="text-right" cols="6">
                            <checkbox v-model="showCancelled" inline @change="loadOrders(patient.id)">Show Cancelled
                            </checkbox>
                            <checkbox v-model="showShipped" inline @change="loadOrders(patient.id)">Show Shipped
                            </checkbox>
                        </b-col>
                    </b-row>

                    <OrdersListComponent :orders="orders" expandable :busy="loadingOrders" @change="loadOrders" />
                </card>
                <!-- AUTOREFILL SUBSCRIPTIONS-->
                <card>
                    <h4 class="card-subtitle">Autorefill Subscriptions</h4>
                    <RXSubscriptionsGrid ref="rxSubscriptionsGrid" v-model="patient.id" />
                </card>
                <!-- PRESCRIPTIONS -->
                <card>
                    <div class="d-flex">
                        <h4 class="card-subtitle flex-fill">Scripts</h4>

                        <HelpPopup label="Color Codes">
                            <b-table-simple>
                                <b-tbody>
                                    <b-tr class="table-info">
                                        <b-td>Transfer Out</b-td>
                                    </b-tr>
                                    <b-tr class="table-dark strikethrough">
                                        <b-td>Void</b-td>
                                    </b-tr>
                                    <b-tr class="table-danger">
                                        <b-td>Expired</b-td>
                                    </b-tr>
                                    <b-tr class="table-dark">
                                        <b-td>Not Refillable / On Hold</b-td>
                                    </b-tr>
                                    <b-tr class="table-warning">
                                        <b-td>Fill Date After Today</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </HelpPopup>
                    </div>

                    <PrescriptionsListComponent :actionButtons="prescriptionListActionButtons"
                        :prescriptions="prescriptions" :patient="patient" colorCodePrescriptions refillBatchMode
                        showProccessQueueButton showReportProblemButton showSubscriptionButton showTransferButton
                        @batchFinished="loadPrescriptions" @newSubscriptionSaved="loadAutoRefillData">
                    </PrescriptionsListComponent>
                </card>
                <!--Prior Authorization Creation-->
                <PriorAuthorizationListComponent v-model="patient"></PriorAuthorizationListComponent>
                <ElectronicPriorAuthorizationListPage v-model="patient"></ElectronicPriorAuthorizationListPage>
                <card id="consultations-card">
                    <!-- CONSULTATIONS -->
                    <h4 class="card-subtitle">Consultations</h4>
                    <ConsultationListComponent ref="consultationList" :enable-cancel="true" :enable-edit="false"
                        :patient-id="+patient.id" :hide-patient="true" />
                </card>

                <card id="patient-assistance-program-card">
                    <h4 class="card-subtitle">Patient Assistance Program</h4>
                    <b-row>
                        <b-col>
                            <b-button variant="success" @click="showPapManualModal">
                                Add Manually
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <patient-assistance-program-table :table-filter="papFilter" ref="pap-table"
                                api-url="PatientAssistanceProgram/Patient" allow-removal />
                        </b-col>
                    </b-row>
                </card>
            </b-col>
        </b-row>
        <b-row>
            <b-modal
                id="cases-list"
                size="xl"
                title="Open or Recently Closed Patient Complaints"
            >
                <el-table :data="casesList" class="table-shopping">
                    <el-table-column
                        label="Error Type"
                        property="problem"
                    ></el-table-column>
                    <el-table-column
                        label="Date Opened"
                        property="created"
                    ></el-table-column>
                    <el-table-column label="Status" property="">
                        <template v-slot="props">
                            {{ props.row.accepted != null ? "Closed" : "Opened" }}
                        </template>
                    </el-table-column>
                    <el-table-column width="50">
                        <template v-slot="props">
                            <i v-b-modal="'case-detail'" class="fa fa-info" @click="selectCase(props.row)"/>
                        </template>
                    </el-table-column>
                </el-table>
            </b-modal>
            <CaseDetailComponent
                :caseSelected="caseSelected"
                :editable="false"
            ></CaseDetailComponent>
        </b-row>

        <b-row>
            <b-modal
                id="RxTransferListModal"
                size="xl"
                title="Rx Transfer Requests"
            >
                <b-table-simple fixed>
                    <b-thead head-variant="dark">
                        <b-tr>
                            <b-th>Entered</b-th>
                            <b-th>In/Out</b-th>
                            <b-th>Status</b-th>
                            <b-th>Reason</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="transfer in unfinishedRxTransfers" :key="`${transfer.id}`" style="cursor: pointer"
                            @click="viewTransferDetails(+transfer.id)">
                            <b-td>{{ convertDate(transfer.timeStamp) }}</b-td>
                            <b-td>{{
                                    transfer.inbound == true
                                        ? "Inbound"
                                        : "Outbound"
                                }}
                            </b-td>
                            <b-td>{{ getRxTransferEnum(transfer.transferStatus) }}</b-td>
                            <b-td>{{ transfer.rxTransferReasonName }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-modal>
        </b-row>

        <b-modal id="rxTransferModal" hide-footer size="lg" title="Rx Transfer">
            <RxTransferModalComponent :patient="patient" @complete="$bvModal.hide('rxTransferModal')" />
        </b-modal>

        <b-modal id="rxTransferModalEdit" hide-footer title="Rx Transfer">
            <RxTransferModalComponent :TransferIdDetails="+selectedRxTransfer" :patient="patient"
                @complete="$bvModal.hide('rxTransferModalEdit')" />
        </b-modal>

        <b-modal id="irsReportModal" hide-footer size="lg" title="Send IRS Report">
            <IRSReportComponent :patient="patient"></IRSReportComponent>
        </b-modal>

        <b-modal id="newConsultationModal" hide-footer no-close-on-backdrop title="New Consultation">
            <SearchComponent v-model="newConsultationStore" label="Store" prepopulate search-u-r-l="/Store" />
            <NewConsultationFormComponent v-if="newConsultationStore.id" :patient="patient"
                :store="newConsultationStore" @updateList="updateConsultationsList"></NewConsultationFormComponent>
        </b-modal>

        <!-- Insurance Image Modal -->
        <b-modal id="uploadInsuranceModal" prefix="uploadInsuranceModal" hide-footer title="Upload Insurance">
            <PatientInsuranceModalComponent v-model="patient.id"></PatientInsuranceModalComponent>
        </b-modal>

        <!-- Shipping Ticket Modal -->
        <b-modal id="shippingTicketModal" ref="shippingTicketModal" hide-footer size="xl" title="Shipping Ticket">
            <ShippingTicketComponent :patient="this.patient" v-model="shipmentToViewInTicket"></ShippingTicketComponent>
        </b-modal>

        <b-modal id="createCovidTestScript" ref="createCovidTestScript" size="lg" title="Create Script for COVID Tests"
            hide-footer>
            <SearchComponent v-model="covidStore" label="Pharmacy" :option-label="storeLabel" search-u-r-l="/Store"
                clearable prepopulate></SearchComponent>
            <b-dropdown id="insurance-selection" variant="success" :text="this.covidPlan.toString()">
                <b-dropdown-item v-for="plan in this.covidPlans" :key="plan.patientPlan.id">
                    {{ plan.toString() }}
                </b-dropdown-item>
            </b-dropdown>
            <b-button block class="my-0" variant="success" :disabled="covidDisabled" @click="createCovidScript">Create
                Script
            </b-button>
        </b-modal>

        <!-- New Rx Request Modal -->
        <FaxToPhysicianFormModal modal-id="new-rx-request-fax" :patient="patient"
            :form-type="faxFormTypeRequestEnum.NewRxRequest" @saved="fetchFollowUpCases"></FaxToPhysicianFormModal>

        <b-modal id="pap-documents" title="Patient Documents" size="xl" @ok="addPap">
            <SearchComponent v-model="selectedProgramForPap" label="Program (optional)" search-u-r-l="/Program"
                :params="{ active: true }" clearable prepopulate />
            <PatientDocumentsForm :patient="patient" @selectedImageIdsUpdate="selectedPapDocuments = $event"
                selectable />
        </b-modal>

        <PatientDocumentsModal :patient="patient"></PatientDocumentsModal>
        <OnlineOrdersModal :patient="patient"></OnlineOrdersModal>
        <b-modal id="PaymentMethodModalMaintenance" hide-footer size="xl" title="Payment Methods">
            <PatientPaymentMethodsModal :patient="patient"></PatientPaymentMethodsModal>
        </b-modal>
        <b-modal id="ResetPatientAppModal" size="xl" hide-footer hide-header-close>
            <ResetPatientAppPasswordComponent :patient="patient" @close="hidePatientAppModal" />
        </b-modal>
        <b-modal id="SendPapTextModal" centered title="Send PAP Text" ok-title="Send Text" @ok="sendPapText">
            <SearchComponent v-model="selectedProgramForPap" label="Program (optional)" search-u-r-l="/Program"
                :params="{ active: true }" clearable prepopulate>
            </SearchComponent>
        </b-modal>
        <b-modal id="pharmacy-text-modal" @ok="sendPharmacyInfoText">
            <search-component v-model="storeInfo" search-u-r-l="/store" prepopulate />
        </b-modal>
        <b-modal id="duplicate-modal" title="Create Patient Merge Request" @ok="addMergeQueue" no-fade
            @show="showDuplicateModal">
            <search-component v-model="mergeRequestTargetPatient" search-u-r-l="/patient" prepopulate
                label="Target Patient" />

            <PatientRecentlyViewed @change="mergeRequestTargetPatient = $event" :selected="mergeRequestTargetPatient"
                :hide-list="hideMergeRecentList" :disable-patient="patient">
                <template #title>
                    <a href="#" @click.prevent="hideMergeRecentList = !hideMergeRecentList">
                        <i :class="{ 'fa': true, 'fa-chevron-right': true, 'fa-rotate-90': !hideMergeRecentList }"
                            aria-hidden="true" style="transition: all .2s;"></i>
                        Recently Viewed Patients
                    </a>
                </template>
            </PatientRecentlyViewed>
        </b-modal>

        <PatientRecentlyViewed :tracking="true" :patient="patient" />
    </div>
</template>
<script lang="ts">
import Checkbox from "@/components/Inputs/Checkbox.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import PrescriptionsListComponent from "@/components/Prescription/PrescriptionsListComponent.vue";
import OrdersListComponent from "@/components/Order/OrdersListComponent.vue";
import ShippingTicketComponent from "@/components/Shipping/ShippingTicketComponent.vue";
import NotesList from "@/components/Note/NotesList.vue";
import NotesForm from "@/components/Note/NotesForm.vue";
import { Patient, PatientProgram } from "@/models/Patient";
import { Prescription } from "@/models/Prescription";
import RxTransferModalComponent from "@/components/RxTransfer/RxTransferModalComponent.vue";
import DoctorFollowUpCasesAlertComponent from "@/components/Doctor/DoctorFollowUpCasesAlertComponent.vue";
import { CaseDetail } from "@/models/Case";
import { Store } from "@/models/Store";
import { Order } from "@/models/Order";
import { LockHandler } from "@/mixins/LockHandler";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { DatePicker, Table, TableColumn } from "element-ui";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import Card from "@/components/Cards/Card.vue";
import PriorAuthorizationListComponent from "@/components/PriorAuthorization/PriorAuthorizationListComponent.vue";
import moment from "moment";
import { GSDDPatientAllergy } from "@/models/GSDD";
import PatientPlansComponent from "@/components/Insurance/PatientPlansComponent.vue";
import { RxTransfer, transferStatus } from "@/models/RxTransfer";
import IRSReportComponent from "@/components/EmbeddedReports/IRSReportComponent.vue";
import NewConsultationFormComponent from "@/components/Consultation/NewConsultationFormComponent.vue";
import ConsultationListComponent from "@/components/Consultation/ConsultationListComponent.vue";
import { Shipment } from "@/models/Shipment";
import PatientDocumentsModal from "@/components/Patient/PatientDocumentsModal.vue";
import PatientDemographicsComponent from "@/components/Patient/PatientDemographicsComponent.vue";
import FaxToPhysicianFormModal from "@/components/Prescription/FaxToPhysicianFormModal.vue";
import { FaxType } from "@/models/PrescriberFollowUpCase";
import OnlineOrdersModal from "@/components/Patient/OnlineOrdersModal.vue";
import PatientInsuranceModalComponent from "@/components/Patient/PatientInsuranceModalComponent.vue";
import { PatientInsuranceUpload } from "@/models/PatientInsuranceUpload";
import RXSubscriptionsGrid from "@/components/Prescription/RXSubscriptionsGrid.vue";
import ElectronicPriorAuthorizationListPage from "@/pages/PriorAuthorization/ElectronicPriorAuthorizationListPage.vue";
import { CombinedPlan } from "@/models/CombinedPlan";
import { Plan } from "@/models/Plan";
import { PatientPlan } from "@/models/PatientPlan";
import PatientPaymentMethodsModal from '@/components/Patient/PatientPaymentMethodsModal.vue';
import ResetPatientAppPasswordComponent from '@/components/Patient/ResetPatientAppPasswordComponent.vue';
import PatientAssistanceProgramTable from "@/components/PatientAssistanceProgram/PatientAssistanceProgramTable.vue";
import EmergencyContactList from "@/components/Patient/EmergencyContactList.vue";
import { Prescriber } from "@/models/Prescriber";
import PatientDocumentsForm from "@/components/Patient/PatientDocumentsForm.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { EntityType } from "@/models/EntityType";
import CasesListComponent from '@/components/Cases/CasesListComponent.vue';
import HelpPopup from "@/components/HelpPopup.vue";
import { Program } from "@/models/Program";
import { BvModalEvent } from 'bootstrap-vue';
import PatientRecentlyViewed from "@/components/Patient/PatientRecentlyViewed.vue";
import PatientFormComponent from "@/components/Patient/PatientFormComponent.vue";
import CaseDetailComponent from "@/components/Cases/CaseDetailComponent.vue";

enum searchType {
    Unknown = 1,
    Empty = 2,
    String = 3,
    Phone = 4,
    Rx = 5,
}

@Component({
    components: {
        CaseDetailComponent,
        PatientFormComponent,
        PatientDocumentsForm,
        ElectronicPriorAuthorizationListPage,
        SearchComponent,
        Checkbox,
        PrescriptionsListComponent,
        OrdersListComponent,
        NotesList, NotesForm,
        Card,
        [DatePicker.name]: DatePicker,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        ConsultationListComponent,
        PriorAuthorizationListComponent,
        PatientPlansComponent,
        RxTransferModalComponent,
        IRSReportComponent,
        NewConsultationFormComponent,
        ShippingTicketComponent,
        PatientDocumentsModal,
        PatientDemographicsComponent, FaxToPhysicianFormModal,
        OnlineOrdersModal, DoctorFollowUpCasesAlertComponent,
        PatientInsuranceModalComponent, RXSubscriptionsGrid,
        PatientPaymentMethodsModal,
        ResetPatientAppPasswordComponent,
        PatientAssistanceProgramTable,
        EmergencyContactList,
        CasesListComponent,
        HelpPopup,
        PatientRecentlyViewed,
    },
})
export default class PatientDashboardPage extends Mixins(LockHandler) {
    @Ref('doctor-followup-alerts') doctorFollowupAlerts!: DoctorFollowUpCasesAlertComponent;

    protected showPatientForm: boolean = false;
    protected searchShouldFocus: boolean = false;
    public patient: Patient = new Patient();
    protected primaryPrescriber?: Prescriber;
    protected patientPrograms: PatientProgram[] = [];
    private prescriptions: Prescription[] = [];
    protected orders: Order[] = [];
    protected loadingOrders = false;
    protected allergies: GSDDPatientAllergy[] = [];
    private casesList: CaseDetail[] = [];
    protected caseSelected: CaseDetail | any = {};
    private storeInfo: Store = new Store();

    private displayName = "";
    private searchValue = "";
    protected showActiveTransferAlert = false;
    protected selectedRxTransfer: number | null = null;
    protected showPatientAlert = false;
    private showCancelled = false;
    private showShipped = true;
    protected showCasesNotification = false;
    protected outboundCallOptOut = false;
    private unfinishedRxTransfers: RxTransfer[] = [];
    protected newConsultationStore: Store = new Store();
    protected patientShipments: Shipment[] = [];

    protected faxFormTypeRequestEnum = FaxType;
    protected selectedProgramForPap: Program = new Program();
    protected mergeRequestTargetPatient = new Patient();
    protected hideMergeRecentList = true;

    protected patientSearchType: searchType = searchType.Unknown;

    private filterStore: Store = {} as Store;
    protected patientEntityType = EntityType.Patient;
    private latestInsuranceUpload: PatientInsuranceUpload =
        {} as PatientInsuranceUpload;
    private selectedPapDocuments: number[] = [];

    protected patientButtonTitles = {
        new: "",
        clear: "",
        save: "Save Patient",
        cancel: "Close Patient Form",
    };

    protected prescriptionListActionButtons: Array<string> = [];
    @Ref() private consultationList!: ConsultationListComponent;
    protected shipmentToViewInTicket: Shipment = new Shipment();

    @Ref('pap-table') private papTable!: PatientAssistanceProgramTable;

    get patientAlertText(): string {
        if (!this.patient || !this.patient.alert) return "";

        return `Patient Memo: ${this.patient.alert}`;
    }

    patientFormHidden() {
        this.patient = new Patient();
    }

    onPatientCreated(newPatient: Patient) {
        console.log("create new patient: ", newPatient);
        this.showPatientForm = false;
        this.patient = newPatient;
    }

    get routePatientId(): number {
        let res: number;
        res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get insuranceUploaded(): boolean {
        return this.latestInsuranceUpload &&
            moment(this.latestInsuranceUpload.uploaded).isValid();
    }

    get insuranceUploadedDisplayDate(): string {
        return moment(this.latestInsuranceUpload.uploaded).fromNow();
    }

    get hasOldPortal(): boolean {
        return this.patient.createdBy?.indexOf(":") > 0;
    }

    get oldPortalLink(): string {
        if (this.patient.createdBy?.indexOf(":") > 0) {
            const oldPatIds = this.patient.createdBy.split(":");
            return `https://portal.cphrx1.local/Patient/${oldPatIds[0]}/${oldPatIds[1]}`;
        }
        return "";
    }

    get patientName(): string {
        let res = "";
        if (this.patient) res = this.patient.displayNamesForPerson();
        return res;
    }

    created() {
        this.prescriptionListActionButtons.push("edit");
        this.prescriptionListActionButtons.push("subscribe");
        this.prescriptionListActionButtons.push("suppress");

        // If route as a PatientId as argument, load its data by default.
        if (this.routePatientId) {
            axios.get(`/Patient/${this.routePatientId}`)
                .then((response) => {
                    this.patient = new Patient(response.data);
                })
                .catch((error) => {
                    this.$notification(NotificationOptions.error(error));
                    console.error("Problem loading patient information by route Id", {
                        error,
                        response: error?.response,
                    });
                });
        } else {
            this.searchShouldFocus = true;
        }
    }

    @Watch("$route.params.id") onParamChanged(value: string, oldValue: string) {
        if (value != oldValue) {
            this.patient = Object.assign(new Patient(), { id: value });
        }
    }

    @Watch("patient") onPatientChanged(
        value: Patient | null,
        oldValue: Patient | null
    ) {
        if (!value) {
            this.displayName = "";
            return;
        }
        const patient: Patient = value as Patient;
        if (!patient.id) {
            patient.updateNamesForPersonFromDisplayName(this.displayName);
        } else {
            if (!oldValue || oldValue.id != patient.id) {
                if (this.$route?.params.id != patient.id.toString())
                    this.$router.push({
                        name: "PatientDashboard",
                        params: { id: patient.id.toString() },
                    });

                this.clearData();
                // Get the full patient object instead of the tiny stub we get from backend.
                axios
                    .get(`/Patient/${patient.id}`)
                    .then((response) => {
                        this.patient = Object.assign(
                            new Patient(),
                            response.data
                        );
                        this.displayName = patient.id
                            ? patient.displayNamesForPerson()
                            : "";
                        if (!this.routePatientId)
                            this.$router.push({
                                name: "PatientDashboard",
                                params: { id: patient.id.toString() },
                            });
                        if (this.patient.primaryPrescriberId) {
                            axios.get(`/Prescriber/${this.patient.primaryPrescriberId}`).then(response => {
                                this.primaryPrescriber = new Prescriber(response.data);
                            });
                        }
                        axios.get<PatientProgram[]>(`/Patient/${this.patient.id}/programs`)
                            .then(resp => {
                                this.patient.programs = resp.data;
                            });
                    })
                    .catch((error) => {
                        console.error(
                            "Problem loading patient information its selection on the search component",
                            {
                                error,
                                response: error?.response,
                            }
                        );
                    });

                //Get programs associated to the patient
                axios
                    .get(`/Patient/${patient.id}/programs`)
                    .then((response) => {
                        this.patientPrograms = response.data;
                    })
                    .catch((error) => {
                        console.error("Problem loading programs", {
                            error,
                            response: error?.response,
                        });
                    });

                this.loadPrescriptions();
                this.loadOrders(patient.id);

                //Get patient's allergies
                axios
                    .get(`Allergy/GetGSDDAllergiesForPatient/${patient.id}`)
                    .then((response) => {
                        this.allergies = response.data;
                    })
                    .catch((error) => {
                        console.error("Problem loading allergies", {
                            error,
                            response: error?.response,
                        });
                    });

                this.getCasesForPatient();

                axios
                    .get(`/Patient/${patient.id}/GetOutboundOptStatus`)
                    .then((response) => {
                        this.outboundCallOptOut = response.data;
                    })
                    .catch((_error) => {
                        console.error("Error Getting Opt Status");
                    });

                axios
                    .get(`/RxTransfer/${patient.id}/GetUnfinishedByPatient`)
                    .then((response) => {
                        this.unfinishedRxTransfers = response.data;
                    })
                    .catch((error) => {
                        console.error("Error Getting RxTransfers", {
                            error,
                            response: error?.response,
                        });
                    });

                axios
                    .get(`/Shipping/GetShipmentsByPatient/${patient.id}`)
                    .then((response) => {
                        this.patientShipments = response.data;
                    })
                    .catch((error) => {
                        console.error("Error Getting Shipments", {
                            error,
                            response: error?.response,
                        });
                    });

                //getLatestInsuranceUpload
                axios
                    .get<PatientInsuranceUpload>(
                        `/PatientInsuranceUpload/GetLatest/${patient.id}`
                    )
                    .then((response) => {
                        this.latestInsuranceUpload = response.data;
                        this.latestInsuranceUpload.uploaded;
                    });
            }
        }
    }

    async getCasesForPatient() {
        try {
            const response = await axios.get(`/Patient/${this.patient.id}/Cases`);
            this.casesList = response.data;
        } catch (error) {
            console.error("Problem loading insurance Patient Cases", { error, response: error?.response });
        }
    }

    clearData() {
        this.patientPrograms = [];
        this.prescriptions = [];
        this.orders = [];
        this.loadingOrders = false;
        this.showCasesNotification = false;
        this.showActiveTransferAlert = false;
        this.allergies = [];
        let rxSubscriptionsGrid = this.$refs
            .rxSubscriptionsGrid as RXSubscriptionsGrid;
        if (rxSubscriptionsGrid) rxSubscriptionsGrid.clearData();
    }

    clearClick() {
        this.clearData();
        this.patient = new Patient();
    }

    loadPrescriptions() {
        if (!this.patient?.id) return;

        //Get patient's prescriptions and format the data to be shown in the grid.
        axios
            .get(`/Patient/${this.patient.id}/prescriptions`)
            .then((response) => {
                this.prescriptions = response.data.map((rx: Prescription) => new Prescription(rx));
                this.loadRxsAvailableForAutorefill();
            })
            .catch((error) => {
                console.error("Problem loading prescriptions", {
                    error,
                    response: error?.response,
                });
            });
    }

    get papFilter(): AxiosRequestConfig {
        let filter: AxiosRequestConfig;
        filter = {
            params: {
                id: this.patient.id
            }
        };
        return filter;
    }

    loadRxsAvailableForAutorefill() {
        this.$http
            .get<Array<Prescription>>(
                `autorefill/available-for-autorefill/${this.patient.id}`
            )
            .then((res) => {
                if (res.data?.length) {
                    const availableRxsForAutorefill = res.data.map((rx) =>
                        Object.assign(new Prescription(), rx)
                    );
                    this.prescriptions.forEach((rx) => {
                        rx.isAvailableForAutorefill =
                            availableRxsForAutorefill.some(
                                (rx2) => rx2.rxID == rx.rxID
                            );
                    });
                }
            })
            .catch((err) => {
                console.error(
                    "Error loading Rxs available for subscription to auto refill.",
                    { err, response: err?.response }
                );
            });
    }

    loadAutoRefillData() {
        this.loadRxsAvailableForAutorefill();
        let rxSubscriptionsGrid = this.$refs
            .rxSubscriptionsGrid as RXSubscriptionsGrid;
        if (rxSubscriptionsGrid) rxSubscriptionsGrid.loadData();
    }

    loadOrders(patientId?: string | number) {
        patientId = patientId || this.patient.id;
        let storeId =
            this.filterStore?.id == 0 || this.filterStore?.id
                ? this.filterStore?.id
                : "";
        //Get patient's prescriptions and format the data to be shown in the grid.
        this.loadingOrders = true;
        axios
            .get(
                `/Patient/${patientId}/orders/${this.showCancelled}/${this.showShipped}/${storeId}`
            )
            .then((response) => {
                this.orders = response.data;
                this.orders.sort((a: Order, b: Order) => {
                    return (
                        new Date(b.created).getTime() -
                        new Date(a.created).getTime()
                    );
                });
            })
            .catch((error) => {
                console.error("Problem loading patient orders", {
                    error,
                    response: error?.response,
                });
            })
            .finally(() => {
                this.loadingOrders = false;
            });
    }

    @Watch("patient.alert") onAlertChange(value: string, oldValue: string) {
        this.showPatientAlert = !!value && !oldValue;
    }

    @Watch("unfinishedRxTransfers") onRxTransfersChange() {
        this.showActiveTransferAlert = this.unfinishedRxTransfers.length > 0;
    }

    @Watch("casesList") onCasesListChange() {
        console.log(this.casesList)
        this.showCasesNotification = !!this.casesList?.find(c => !c.resolved || moment(c.accepted).isAfter(moment().subtract(7, "days")));
    }

    @Watch("searchValue") searchValueChange(_value: string, _oldValue: string) {
        this.updatePatientSearchType();
    }

    updatePatientSearchType() {
        let type: searchType;
        if (!this.searchValue.length) {
            type = searchType.Empty;
        } else if (this.searchValue.charAt(0) == "+") {
            type = searchType.Phone;
        } else if (isNaN(Number(this.searchValue))) {
            type = searchType.String;
        } else {
            type = searchType.Rx;
        }
        this.patientSearchType = type;
    }

    refreshNotesList() {
        if (this.$refs.patientNotesList instanceof NotesList)
            (this.$refs.patientNotesList as any).loadNotes();
    }

    storeLabel(object: Store) {
        let res = "";
        if (object.name) res = object.name;

        return res;
    }

    convertDate(date: string | Date) {
        return moment.utc(date).format("L");
    }

    getRxTransferEnum(status: number) {
        return transferStatus[status];
    }

    SendInsuranceRequest() {
        axios
            .post(`/PatientInsuranceUpload/SendRequest/${this.patient.id}`)
            .then(() => {
                this.$notification({
                    message: "Message Sent!",
                    timeout: 3000,
                    icon: "now-ui-icons ui-1_bell-53",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "success",
                });
            })
            .catch((error) => {
                this.$notification({
                    message:
                        "Error Sending Message:" +
                        error.response?.data.substring(0, 100),
                    timeout: 3000,
                    icon: "now-ui-icons ui-1_bell-53",
                    horizontalAlign: "center",
                    verticalAlign: "top",
                    type: "danger",
                });
            });
    }

    ChangeOutboundOptOut(status: boolean) {
        axios
            .post(
                `/Patient/${this.patient.id}/${status}/ChangeOutboundOptStatus`
            )
            .then((_response) => {
                this.outboundCallOptOut = status;
            })
            .catch((error) => {
                console.error("Error Changing Opt Status", {
                    error,
                    response: error?.response,
                });
            });
    }

    StartRxTransfer() {
        this.$bvModal.show("rxTransferModal");
    }

    viewTransferDetails(transferId: number) {
        this.selectedRxTransfer = transferId;
        this.$bvModal.show("rxTransferModalEdit");
    }

    StartIRSReport() {
        this.$bvModal.show("irsReportModal");
    }

    openNewConsultationModal() {
        this.$bvModal.show("newConsultationModal");
    }

    private covidStore: Store = {} as Store;
    private covidPlans: CombinedPlan[] = [];
    private covidPlan: CombinedPlan = {} as CombinedPlan;
    protected covidDisabled: boolean = false;

    openCovidModal() {
        axios
            .get<{ plans: Plan[]; patientPlans: PatientPlan[] }>(
                `Insurance/${this.patient.id}`
            )
            .then((response) => {
                this.covidPlans = response.data.patientPlans
                    .filter((pp) => pp.active)
                    .map((pp) => {
                        return new CombinedPlan({
                            patientPlan: new PatientPlan(pp),
                            plan: new Plan(
                                response.data.plans.find(
                                    (p) => p.id == pp.planId
                                )
                            ),
                        } as CombinedPlan);
                    });

                this.covidPlan = this.covidPlans[0];
                this.$bvModal.show("createCovidTestScript");
            })
            .catch((error) => {
                console.error("Error while loading the insurance plans", {
                    error,
                    response: error?.response,
                });
            });
    }

    createCovidScript() {
        this.covidDisabled = true;
        if (this.covidPlan.patientPlan?.id && this.covidStore?.id) {
            axios
                .post(
                    `patient/${this.patient.id}/${this.covidStore.id}/${this.covidPlan.patientPlan.id}`
                )
                .then((response) => {
                    let script = Object.assign(
                        new Prescription(),
                        response.data
                    ) as Prescription;
                    if (script.rxID) {
                        this.$router.push({
                            name: "PrescriptionDetailsId",
                            params: { rxid: script.rxID },
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error creating Covid Test Script", {
                        error,
                        response: error?.response,
                    });
                    this.$notification({
                        message:
                            "Error Sending Message:" + error.response?.data,
                        timeout: 3000,
                        icon: "now-ui-icons ui-1_bell-53",
                        horizontalAlign: "center",
                        verticalAlign: "top",
                        type: "danger",
                    });
                });
        }
    }

    updateConsultationsList() {
        this.$bvModal.hide("newConsultationModal");
        (this.consultationList as any).getConsultation();
    }

    showShippingTicket(shipment: Shipment) {
        this.shipmentToViewInTicket = new Shipment(shipment);
        this.$bvModal.show("shippingTicketModal");
    }

    showPapManualModal() {
        this.selectedProgramForPap = new Program();
        this.$bvModal.show("pap-documents");
    }

    async addPap(event: any) {
        event.preventDefault();

        if (!this.selectedPapDocuments.length) {
            this.$notification(NotificationOptions.error("You must select a document to create this manual PAP record."));
            return;
        }

        try {
            const data = this.selectedPapDocuments.map(documentId => {
                return { id: documentId };
            });
            const programId = this.selectedProgramForPap?.id;

            const resp = await axios.post(`api/PatientAssistanceProgram/Create/${this.patient.id}`, data, {
                params: {
                    programId: programId > 0 ? programId : null
                }
            });

            console.log("resp : ", resp);
            this.$notification(NotificationOptions.successSaveNotificationPreset("Patient Assistance Program"));

            this.$nextTick(() => {
                this.$bvModal.hide("pap-documents");
            });
        } catch (err) {
            console.warn("err: ", err);
            this.$notification(NotificationOptions.error(err));
        } finally {
            this.selectedPapDocuments = [];
            this.papTable.clearAndRefresh();
        }
    }

    showPapTextModal() {
        this.selectedProgramForPap = new Program();
        this.$bvModal.show("SendPapTextModal");
    }

    async sendPharmacyInfoText() {
        try {
            await axios.post(`/api/Patient/${this.patient.id}/SendPharmacyInfoText`, this.storeInfo);
            this.$notification(NotificationOptions.notificationOptionsPreset("Message Sent!", NotificationOptions.NotificationTypes.success));
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    async sendPapText() {
        const programId = this.selectedProgramForPap?.id;

        try {
            await axios.post("patient/SendPatientAssistanceProgramText", this.patient, {
                params: {
                    programId: programId > 0 ? programId : null
                }
            });
            this.$notification(NotificationOptions.notificationOptionsPreset("Message Sent!", NotificationOptions.NotificationTypes.success));
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    async sendNpcText(isDermaDash: boolean) {
        try {
            const prescriptionsRequiringPV1 = this.prescriptions?.filter(p => p.isPV1Required);

            if (prescriptionsRequiringPV1?.length) {
                const h = this.$createElement;

                const promptMessage = `Are you sure you want to send the text?
                    PV1 is required on ${prescriptionsRequiringPV1?.length === 1 ? "this prescription:" : "these prescriptions:"}`;

                const messageNodes = [
                    h('p', { style: "font-weight: bolder" }, promptMessage),
                    ...prescriptionsRequiringPV1.map(p => h('p', {}, `${p.rxID}: ${p.drugName}`))
                ];

                const confirm = await this.$bvModal.msgBoxConfirm(messageNodes, {
                    title: 'Confirm Send - PV1 Required',
                    okVariant: 'warning',
                    centered: true,
                    okTitle: "Send",
                    scrollable: true
                });
                if (!confirm) return;
            }

            const endpoint = `patient/sendNpcText/${isDermaDash ? "DermaDash" : "Carepoint"}`;

            await axios.post(endpoint, this.patient);

            this.$notification({
                message: "Message Sent!",
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
            });
        } catch (err) {
            this.$notification({
                message: `Error Sending Message: ${(err as AxiosError).response?.data}`,
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
            });
        }
    }

    sendRefillText() {
        const fulfilled = (_textResp: any) => {
            this.$notification({
                message: "Message Sent!",
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
            });
            console.log("did this point get hit");
        };
        const rejected = (err: any) => {
            this.$notification({
                message: "Error Sending Message:" + err.response?.data,
                timeout: 3000,
                icon: "now-ui-icons ui-1_bell-53",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "danger",
            });
        };

        axios
            .post("patient/sendRefillText/Carepoint", this.patient)
            .then(fulfilled)
            .catch(rejected);
    }

    fetchFollowUpCases() {
        this.doctorFollowupAlerts?.fetchFollowUpCases();
    }

    paymentMethodsModal() {
        this.$bvModal.show("PaymentMethodModalMaintenance");
    }

    resetPatientAppModal() {
        this.$bvModal.show("ResetPatientAppModal");
    }

    hidePatientAppModal() {
        this.$bvModal.hide("ResetPatientAppModal");
    }

    scrollCaseHistoryIntoView() {
        document.getElementById('patient-case-history-card')?.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    async addMergeQueue(e: BvModalEvent) {
        e.preventDefault();
        try {

            if (!this.mergeRequestTargetPatient?.id) {
                this.$notification(NotificationOptions.error("Must select a target patient for the merge request"));
                return;
            }

            if (this.patient.id === this.mergeRequestTargetPatient.id) {
                this.$notification(NotificationOptions.error("Source and target patient can't be the same record"));
                return;
            }

            const request = `/patient/mergequeue/${this.patient.id}/${this.mergeRequestTargetPatient.id}`;
            await axios.post(request);

            this.$notification(NotificationOptions.notificationOptionsPreset("Patient merge request added", NotificationOptions.NotificationTypes.success));

            this.$bvModal.hide(`duplicate-modal`);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    showDuplicateModal() {
        this.mergeRequestTargetPatient = new Patient();
        this.hideMergeRecentList = true;
    }
}

</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.form-check>.form-check-label {
    color: black;
}

::v-deep(.strikethrough td) {
    text-decoration: line-through;
}
</style>
