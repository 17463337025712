
$scaleSize: 0.95;

@keyframes zoomIn95 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }

    to {
        opacity: 1;
    }
}

.main-panel .zoomIn {
    animation-name: zoomIn95;
}

@keyframes zoomOut95 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.main-panel .zoomOut {
    animation-name: zoomOut95;
}

::v-deep(.sidebar input) {
    background: black;
    border: 1px solid black;
    color: white;

    &:hover,
    &:active,
    &:focus {
        border: 1px solid gray;
        background: black;
        color: white;
    }
}

.mobile-nav-toggle {
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 1100;
    border-radius: 50%;
    padding: 11px;
    border: 1px solid white;
    background: rgba(0, 0, 0, .65);
    color: white;
    display: flex;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: 0 0 5px rgba(0, 0, 0, .7);
    transition: all .3s;

    &.force {
        left: 202px;
        top: 12px;
        background: transparent;
    }
}

.filter-bar-container {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #212120;
    width: calc(100% - 15px);
    margin-top: -25px;
    padding-top: 15px;
    padding-bottom: 15px;

    input {
        background: rgba(0, 0, 0, .95);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        padding: 5px;
        width: calc(100% + 15px);
    }

    input:placeholder-shown {
        text-align: center;
    }

    input:focus:placeholder-shown {
        text-align: left;
    }
}

@media screen and (min-width: 991px) {
    .mobile-nav-toggle {
        display: none;
    }
}
