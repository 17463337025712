<template>
    <div class="prior-authorization-list-view">
        <Card title="Prior Authorizations">
            <div class="stat-grid">
                <div>
                    <b>Total Open:</b>
                    <h4>{{ totalPas }}</h4>
                </div>
                <div v-for="statEnum in paStatusList" :key="statEnum">
                    <span
                        v-b-tooltip.hover="{ title: getManufacturersByStatusHoverText(statEnum), html: true, customClass: 'longToolTip' }">
                        <b>{{ paCodes[statEnum] }}:</b>
                        <h4>{{ countByStatus(statEnum) }}</h4>
                    </span>
                </div>
            </div>
            <hr>
            <div class="filter-grid mb-2">
                <div>
                    <span>Manufacturers</span>
                    <b-form-select v-model="selectedManufacturer" :options="manufacturers" class="mt-1" />
                </div>

                <EnumSelector v-model="selectedStatus" label="Status" :enum-codes="paCodes" :clearable="true"
                    :elementsToDiscard=statusToIgnore :displayNames="[{}]" />

                <b-form-group label="Filter">
                    <b-input placeholder="Prescriber Name / Patient Name / Drug Name / CMM Event" v-model="filterQuery"
                        style="padding: 8px 15px; margin-top: -3px;" />
                </b-form-group>
            </div>
            <div v-if="isLoading">
                <b-spinner label="Spinning" class="mr-2"></b-spinner>
                <strong>Loading...</strong>
            </div>
            <div ref="tableBlock" v-else>
                <b-row>
                    <b-col cols="12">
                        <b-table id="all-pending-pa-table" striped style="cursor:pointer" outlined responsive="true"
                            sort-icon-left :items="filteredPriorAuthorizations" :fields="fields" primary-key="id"
                            @row-clicked="selectPa" :per-page="pageSize" :current-page="pageNumber"
                            @sort-changed="resetPage">
                            <template #cell(patient)="data">
                                <div>
                                    {{ `${data.value.lastName}, ${data.value.firstName}` }}
                                </div>
                                <div>
                                    {{ formatDate(data.value.dateOfBirth) }}
                                </div>
                            </template>
                            <template #cell(prescriber)="data">
                                <div>
                                    {{ `${data.value.lastName}, ${data.value.firstName}` }}
                                </div>
                                <div>
                                    {{ data.value.phoneNumber }}
                                </div>
                                <b-badge v-if="!data.value.allowsPa" variant="danger">
                                    Does Not Allow PA
                                </b-badge>
                            </template>
                            <template #cell(statusName)="data">
                                <span>
                                    {{ data.value }}
                                </span>
                            </template>
                        </b-table>

                        <BottomPagerBar v-model="pageNumber" :total-rows="totalRows" :per-page="pageSize" show-total
                            item-name="prior auth" />
                    </b-col>
                </b-row>
            </div>
        </Card>
        <PriorAuthorizationDetails id="pa-details-modal" :value="selectedPa" />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Ref } from "vue-property-decorator";
import Card from "@/components/Cards/Card.vue";
import PriorAuthorizationDetails from "@/components/PriorAuthorization/PriorAuthorizationDetails.vue";
import EnumSelector from "@/components/EnumSelector.vue";
import moment from 'moment';
import { PriorAuthorizationStatusCode, PriorAuthorization, CoverMyMedsCallback } from '@/models/PriorAuthorization';
import { Prescription } from '@/models/Prescription';
import BottomPagerBar from "@/components/BottomPagerBar.vue";

@Component({
    components: {
        Card,
        PriorAuthorizationDetails,
        EnumSelector,
        BottomPagerBar
    }
})
export default class PriorAuthorizationListPage extends Vue {
    created() {
        this.fetchPriorAuthorizations();
    }

    get totalPas(): number {
        return this.priorAuthorizations.length;
    }

    private paCodes = PriorAuthorizationStatusCode;

    private isLoading = false;
    private selectedStatus: string | null = null;
    private selectedManufacturer: string = "All";
    private selectedPa: PriorAuthorization = {} as PriorAuthorization;
    private filterQuery: string | null = "";

    private priorAuthorizations: Array<PriorAuthorization> = [];

    private pageSize = 100;
    private pageNumber = 1;

    @Ref("tableBlock") protected tableBlock: any;

    private fields: any[] = [
        {
            key: "prescriber",
            label: "Prescriber"
        },
        {
            key: "patient",
            label: "Patient Name"
        },
        { key: "prescription", label: "Rx Id", formatter: (p: Prescription) => `${p.storeID}-${p.rxNumber}-${p.rfNumber}` },
        { key: "prescription.productNameShort", label: "Drug Name", },
        { key: "created", label: "Initiated", formatter: (d: any) => moment.utc(d).local().format("l LT"), sortable: true },
        { key: "updated", label: "Next Touch", formatter: this.formatNextTouch, sortable: true },
        { key: "latestCallback", label: "CMM Event", formatter: this.cmmEventFormatter, },
        { key: "statusName", label: "Status" },
        { key: "completeReason", label: "Reason", },
    ];

    formatDate(d: Date) {
        return moment(d).format("L");
    }

    formatNextTouch(d: Date, key: string, item: PriorAuthorization): string {
        const daysToAdd = item.status === PriorAuthorizationStatusCode.SubmittedToInsurance ? 7 : 1;
        return moment.utc(d).local().add(daysToAdd, "day").format("l LT");
    }

    cmmEventFormatter(cb: CoverMyMedsCallback) {
        return cb?.event_key ? cb.event_key : cb?.error ?? "";
    }

    get totalRows() {
        return this.filteredPriorAuthorizations?.length ?? 0;
    }

    get filteredPriorAuthorizations() {
        const allManufact = this.selectedManufacturer === "All";
        const allStatus = !this.selectedStatus && this.selectedStatus != "0";
        const queryTerms = this.filterQuery?.toLowerCase().split(" ");
        const hasQueryTerms = (this.filterQuery?.trim().length ?? 0) > 0;

        return this.priorAuthorizations.filter(item => {
            const manufact = allManufact || item.drugManufacturer == this.selectedManufacturer;
            const status = allStatus || item.status.toString() == this.selectedStatus;
            const filterMatch = !hasQueryTerms || queryTerms!.every((qt: string) =>
                item.patient?.firstName?.toLowerCase().includes(qt) ||
                item.patient?.lastName?.toLowerCase().includes(qt) ||
                item.prescription?.drugName?.toLowerCase().includes(qt) ||
                item.prescription?.packageID?.toString().includes(qt) ||
                item.completeReason?.toLowerCase().includes(qt) ||
                item.prescription.rxNumber?.toString().includes(qt) ||
                item.statusName.toLowerCase().includes(qt) ||
                item.prescriber?.lastName?.toLowerCase().includes(qt) ||
                item.prescriber?.firstName?.toLowerCase().includes(qt) ||
                this.cmmEventFormatter(item.latestCallback).includes(qt));

            return manufact && status && filterMatch;
        });
    }

    resetPage() {
        this.pageNumber = 1;
    }

    @Watch("pageNumber")
    resetTableScrollPosition() {
        this.tableBlock?.scrollIntoView({ behavior: "smooth" });
    }

    async fetchPriorAuthorizations() {
        try {
            const url = `/PriorAuthorization/`;

            this.isLoading = true;
            const response = await this.$http.get<Array<PriorAuthorization>>(url);

            this.priorAuthorizations = response.data?.map(pa => new PriorAuthorization(pa));
            this.resetPage();
        } catch (err) {
            console.error("Error loading PAs.", err);
        }

        this.isLoading = false;
    }

    get manufacturers() {
        return ["All", ...this.priorAuthorizations.map(p => p.drugManufacturer).filter(this.onlyUnique).sort()];
    }

    get manufacturersByStatus() {
        // Hover text for manufacturers status.
        const groupedList = this.priorAuthorizations.reduce((group: any, pa) => {
            const { status } = pa;
            group[status] = group[status] ?? [];
            group[status].push(pa);
            return group;
        }, {});

        const group = Object.getOwnPropertyNames(groupedList).reduce((grp: any, status: any) => {
            grp[status] = groupedList[status].reduce((group: any, pa: any) => {
                const { drugManufacturer } = pa;
                if (!group.hasOwnProperty(drugManufacturer)) {
                    group[drugManufacturer] = 0;
                }
                group[drugManufacturer]++;
                return group;
            }, {})
            return grp;
        }, {});

        return Object.getOwnPropertyNames(group).map((status: any) => {
            let manus = Object.getOwnPropertyNames(group[status]).sort();
            return { status: Number(status), text: manus.map((name: any) => `${name}: ${group[status][name]}`).join(' <br/> ') }
        });
    }

    private statusToIgnore = [
        PriorAuthorizationStatusCode.Abandoned,
        PriorAuthorizationStatusCode.Approved,
        PriorAuthorizationStatusCode.Denied,
        PriorAuthorizationStatusCode.Cancelled
    ];

    countByStatus(status: PriorAuthorizationStatusCode): number {
        const res = this.priorAuthorizations.filter(pa => pa.status == status)?.length || 0;
        return res;
    }

    get paStatusList(): Array<PriorAuthorizationStatusCode> {
        const res = new Array<PriorAuthorizationStatusCode>();

        for (const st in PriorAuthorizationStatusCode)
            if (Number(st) >= 0 && !this.statusToIgnore.some(st2 => st2 == Number(st)))
                res.push(Number(st) as PriorAuthorizationStatusCode);

        return res;
    }

    getManufacturersByStatusHoverText(status: PriorAuthorizationStatusCode): string {
        const res = this.manufacturersByStatus.find((m: any) => m.status == status)?.text;
        return res || '';
    }

    onlyUnique(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }

    selectPa(item: PriorAuthorization) {
        this.selectedPa = item;
        this.$bvModal.show("pa-details-modal");
    }
}
</script>

<style scoped>
.longToolTip::v-deep .tooltip-inner {
    max-width: 100%;
}

.stat-grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 200px), 1fr));
    text-align: center;
}

.stat-grid h4 {
    margin-top: 0;
}

.filter-grid {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr));
}

@media (min-width: 1600px) {
    .stat-grid {
        grid-template-columns: repeat(5, 1fr);
    }

    .filter-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
