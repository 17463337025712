<template>
    <b-modal :id="modalId" title="Order Details" size="xl" v-on="$listeners">
        <template #modal-footer>
            <b-row>
                <b-col>
                    <b-button block variant="success" @click="printModal">Print</b-button>
                </b-col>
            </b-row>
        </template>
        <b-container id="printArea">
            <b-row>
                <b-col>
                    <h4>
                        {{orderSource}} Order
                    </h4>
                </b-col>
            </b-row>
            <b-row id="header-row">
                <b-col cols="6" id="patient-demo">
                    <b-row>
                        <b-col>
                            {{onlineOrder && onlineOrder.patientName}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            {{onlineOrder && onlineOrder.address1}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            {{onlineOrder && onlineOrder.address2}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            {{onlineOrder && onlineOrder.addressCity}}, {{onlineOrder && onlineOrder.addressState}}
                            {{onlineOrder && onlineOrder.addressZip}}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="6" id="order-info">
                    <b-row>
                        <b-col>
                            New Allergies: {{onlineOrder && onlineOrder.newAllergies ? 'Yes' : 'No'}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            Requesting Consult: {{onlineOrder && onlineOrder.requestingConsult ? 'Yes' : 'No'}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            Charge: {{onlineOrder && onlineOrder.transactionId}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            {{card.brand}} Card ending in {{card.last4}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            Total charged: {{card.total}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            Date: {{onlineOrder && onlineOrder.charged | formatDate('MM-DD-YYYY h:mm:ss a')}}
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row id="scripts-table-row">
                <b-col>
                    <h4>Scripts</h4>
                    <b-table id="online-order-scripts-table"
                             :fields="fields"
                             :filter="ctxFilter"
                             :items="ctxProvider"
                             api-url="/PatientWeb/Order/Prescriptions"
                             empty-filtered-text="There are no prescriptions for this order."
                             no-provider-sorting
                             show-empty
                             sort-icon-left>
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"/>
                                <strong>Loading...</strong>
                            </div>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row id="addOns-table-row">
                <b-col>
                    <h4>Add Ons</h4>
                    <b-table id="online-order-addons-table"
                             :fields="addOnsFields"
                             :filter="ctxFilter"
                             :items="ctxProvider"
                             api-url="/PatientWeb/Order/AddOns"
                             empty-filtered-text="There are no addons for this order."
                             no-provider-sorting
                             show-empty
                             sort-icon-left>
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"/>
                                <strong>Loading...</strong>
                            </div>
                        </template>
                    </b-table>
                </b-col>
            </b-row>

            <b-row v-if="onlineOrder && onlineOrder.signatureData"
                   id="website-order-row">
                <b-col>
                    <b-row>
                        <b-col>
                            {{attestationLanguage}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div v-html="signatureHtml" class="signature-border"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            Date: {{onlineOrder.charged | formatDate('MM-DD-YYYY h:mm:ss a')}}
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row v-else
                   id="default-online-row">
                <b-col>
                    Requested via automated phone system
                </b-col>
            </b-row>

        </b-container>
    </b-modal>
</template>

<script lang="ts">
    import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
    import { BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
    import Axios, { AxiosRequestConfig } from "axios";
    import { ExternalPatientOrder, ExternalPatientOrderSource } from "@/models/PatientWebOrder";
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";

    @Component({
        name: "OnlineOrderDetailsModal"

    })
    export default class OnlineOrderDetailsModal extends Vue {
        @VModel() private onlineOrder?: ExternalPatientOrder;
        @Prop({ default: 'online-order-details-modal' }) private modalId!: string;

        private fields: BvTableFieldArray = [
            { key: "rxNbr", label: "Rx #", },
            { key: "rfNbr", label: "Rf #", },
            { key: "drugName", },
            { key: "doctorName", label: "Prescriber" },
            { key: "amount", label: "Copay", formatter: this.currencyFormatter },
        ];
        private addOnsFields: BvTableFieldArray = [
            { key: "productName", label: "Product", },
            { key: "productAmount", label: "Price", formatter: this.currencyFormatter },
            { key: "taxAmount", label: "Tax", formatter: this.currencyFormatter },
        ];

        private card: { brand: string, last4: string, total: string } = { brand: '', last4: '', total: '' };


        get orderSource(): string {
            const source = this.onlineOrder?.source ?? ExternalPatientOrderSource.NotSpecified;
            return ExternalPatientOrderSource[source];
        }

        get attestationLanguage(): string {
            return this.onlineOrder?.attestationLanguage ??
                `By signing electronically below, I, ${this.onlineOrder?.patientName ?? ''}, authorize Carepoint Pharmacy to process this prescription order and coordinate with my medical provider and any applicable insurance and I attest that I request this medication.`;
        }

        get ctxFilter() {
            return {
                id: this.onlineOrder?.id,
                source: ExternalPatientOrderSource[this.onlineOrder?.source ?? 0],
            };
        }

        get patientName() {
            return this.onlineOrder?.patientName;
        }

        get signatureHtml() {
            let sigData = this.onlineOrder?.signatureData;
            if (sigData != null && sigData.startsWith("<")) {
                return sigData;
            }
            return `<img src="${sigData}" alt="Signature"/>`;
        }

        @Watch('onlineOrder.transactionId')
        transactionIdChange(newValue: string) {
            if (!newValue) return;

            let config: AxiosRequestConfig = {
                params: {
                    storeId: this.onlineOrder?.storeId
                }
            };

            Axios.get(`/Stripe/Charge/${newValue}`, config)
                .then(response => {
                    this.card = {
                        brand: response.data.paymentMethodDetails.card.brand,
                        last4: response.data.paymentMethodDetails.card.last4,
                        total: `$${(response.data.amount / 100).toFixed(2)}`
                    };
                })
                .catch(_ => {
                    this.card = { brand: '', last4: '', total: '' };
                });
        }

        async printModal() {
            await this.$htmlToPaper('printArea');
        }

        currencyFormatter(a: Number) {
            return `$${a?.toFixed(2)}`;
        }

        ctxProvider(ctx: BvTableCtxObject, callback: Function) {
            if (ctx.apiUrl == null) return null;

            const uri = ctx.apiUrl;
            const config: AxiosRequestConfig = {
                params: ctx.filter
            };
            Axios.get(uri, config)
                .then(resp => {
                    callback(resp.data);
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                    callback([]);
                });
            return null;
        }

    }
</script>

<style scoped>
    .signature-border {
        border: thin solid black;
    }
</style>
