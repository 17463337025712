<template>
    <div>
        <checkbox inline v-model="showCanceled">Show Canceled / Expired</checkbox>
        <b-table striped :items="items" :fields="fields" :busy="isLoading" show-empty
            empty-text="No Autorefill Subscriptions" :tbody-tr-class="colorCodeTable">
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle" />
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(nextProcessDate)="data">
                <span>{{ data.item.nextProcessDate | formatDate('L', false) }}</span>
                <div v-if="!data.item.cancellationDate && data.item.orderId">
                    <b-badge variant="primary" @click="$router.push(`/processing/order/${data.item.orderId}`)"
                        style="cursor: pointer;">
                        <i class="fa fa-sync fa-spin mr-1" />
                        Order Processing
                    </b-badge>
                </div>
            </template>
            <template #cell(updated)="data">
                <span v-b-tooltip.hover :title="rowTitle(data.item)"
                    :class="{ 'font-weight-bold': data.item.cancellationDate }">
                    {{ data.item.updated | formatDate('lll') }}
                </span>
            </template>
            <template #cell(actions)="data">
                <div class="table-icon-actions">
                    <a href="#" @click.prevent="openModal(data.item)">
                        <b-icon icon="pencil-square" />
                    </a> 
                </div>
            </template>
        </b-table>

        <!-- RX SUBSCRIPTION MODAL-->
        <b-modal id="rx-subscription-ingrid" size="xl" title="Subscription to Auto-Refill" hide-footer>
            <RXSubscriptionForm rx="rxToSubscribe" :sub="selectedSub" @saved="closeSubscriptionModal" />
        </b-modal>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator';
import { AutoRefillSubscription } from '@/models/AutoRefillSubscription'
import Checkbox from "@/components/Inputs/Checkbox.vue";

import RXSubscriptionForm from '@/components/Prescription/RXSubscriptionForm.vue'

@Component({
    name: "RXSubscriptionsGrid",
    components: {
        RXSubscriptionForm, Checkbox
    },
})
export default class RXSubscriptionsGrid extends Vue {
    @Prop() private value!: number;

    private items: Array<AutoRefillSubscription> = new Array<AutoRefillSubscription>();
    private selectedSub: AutoRefillSubscription = new AutoRefillSubscription();
    private isLoading = false;
    private showCanceled = false;
    private fields: any[] = [
        { key: "StoreRx", label: "Rx" },
        { key: "nextProcessDate", label: "Next Process" },
        { key: "drugName", label: "Drug" },
        { key: "updated", label: "Updated" },
        { key: "updatedBy", label: "Updated By", formatter: (c: any) => { return c.split('-').length == 5 ? 'Patient App' : c.split("@")[0]; } },
        { key: "actions", label: '', class: "actions-col" },
    ];

    created() {
        this.loadData();
    }

    @Watch('showCanceled') showCanceledChanged() {
        this.loadData();
    }

    @Watch('value') loadData() {
        if (!this.value) return;

        this.isLoading = true;
        this.$http.get<Array<AutoRefillSubscription>>(`/autorefill/subcriptions-by-patient/${this.value}`, { params: { showCanceled: this.showCanceled } })
            .then(res => {
                this.items = res.data.map(i => new AutoRefillSubscription(i));
            }).catch(err => {
                console.log("Error Getting auto refill subscriptions", { err, response: err?.response });
            })
            .finally(() => this.isLoading = false);
    }

    clearData() {
        this.items = [];
    }

    openModal(sub: AutoRefillSubscription) {
        this.selectedSub = sub;
        this.$bvModal.show('rx-subscription-ingrid')
    }

    closeSubscriptionModal() {
        this.$bvModal.hide('rx-subscription-ingrid')
        this.loadData();
    }

    rowTitle(row: AutoRefillSubscription) {
        let res = '';
        if (row.cancellationDate) return `Canceled by ${row.updatedBy}`;

        return res;
    }

    colorCodeTable(row: AutoRefillSubscription, type: string) {
        if (!row || type !== 'row')
            return;

        if (row.cancellationDate) return 'table-danger';
    }
}
</script>

<style scoped>
::v-deep(.actions-col) {
    width: 30px;
}
</style>