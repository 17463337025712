
.popover.text-message-template {
    max-width: 420px;

    .popover-body {
        display: flex;
        flex-direction: column;
        gap: 15px;

        h6 {
            margin-bottom: 4px;
        }
    }

    .msg-bubble {
        background: #02ce48;
        color: white;
        padding: 8px;
        border-radius: 9px;
        border-bottom-left-radius: 0;
        font-weight: 500;
        line-height: 1.3;
        max-width: 300px;
        white-space: pre-wrap;
    }

    .tokens {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        code {
            cursor: pointer;
            background: rgb(248, 248, 248);
            border: 1px solid rgb(240, 240, 240);
            padding: 2px 3px;
            border-radius: 4px;
            transition: all .2s;

            &:hover {
                background: rgb(242, 242, 242);
                border-color: rgb(230, 230, 230);
            }
        }
    }

    &.bs-popover-bottom .popover-body {
        flex-direction: column-reverse;
    }
}
