
::v-deep(td.actions) {
    width: 150px;

    div {
        display: flex;
        gap: 6px;
        flex-direction: column;
    }
}
