
::v-deep(li:has(.heading)) {
    font-weight: bolder;
    font-size: .8em;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(221, 221, 221);
    background: rgb(238, 238, 238);
    color: rgb(100, 100, 100);
    cursor: default;
    margin-top: 6px;
    position: sticky;
    top: -5px;
    letter-spacing: .5px;
    box-shadow: 0 0 3px rgb(221, 221, 221);
}

::v-deep(li:has(.heading):first-child) {
    margin-top: -5px;
}

::v-deep(li:has(.heading):hover) {
    background: rgb(238, 238, 238);
}
