<template>
    <div class="program-component">
        <card title="Program Record Maintenance">
            <form>
                <SearchComponent v-model="program" :disabled="!disableFields" label="Program"
                    :paramsInGetById="{ includeDrugs: true, includePrescribers: true }" search-u-r-l="/Program"
                    prepopulate @input="onProgramUpdate" />
                <b-form-row>
                    <b-col>
                        <fg-input v-model="program.name" :disabled="disableFields" label="Name" required>
                        </fg-input>
                    </b-col>
                    <b-col>
                        <fg-input v-model="program.abbreviation" :disabled="disableFields" label="Abbreviation"
                            required>
                        </fg-input>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col cols="3">
                        <fg-input id="audit-chance-input" v-model.number="orderAuditConfiguration.percentageChance"
                            min=0 max=100 :error="auditChanceError" :disabled="disableFields" label="Audit Chance"
                            type="number" />
                    </b-col>
                    <b-col>
                        <fg-input id="audit-min-margin" v-model.number="orderAuditConfiguration.minimumMargin"
                            :disabled="disableFields" label="Minimum Margin" type="number" />
                    </b-col>
                    <b-col>
                        <fg-input id="audit-max-margin" v-model.number="orderAuditConfiguration.maximumMargin"
                            :disabled="disableFields" label="Maximum Margin" type="number" />
                    </b-col>
                    <b-col>
                        <fg-input id="audit-max-copay" v-model.number="orderAuditConfiguration.maximumCopay"
                            :disabled="disableFields" label="Maximum Copay" type="number" />
                    </b-col>
                </b-form-row>
                <b-form-row>
                <b-col cols="3">
                    <b-form-group label="Price Multiplier">

                    <b-form-input v-model.number="program.priceAdjustment"
                                  v-b-tooltip.html
                                  title="
                                  <li>1 is normal pricing.</li>
                                  <li> More than 1 will increase price.</li>
                                  <li> Between 0 and 1 will decrease price.</li>
                                  <li> A value of 1.15 would be a 15% increase in price.</li>
                                  <li> A value of 0.9 would be a 10% discount on price.</li>
                                  "
                                  :disabled="disableFields"
                                  min="0"
                                  step=".01"
                                  type="number"
                                  />
                    </b-form-group>

<!--                    <fg-input label="Price Multiplier"-->
<!--                              type="number"-->
<!--                              v-model.number="program.priceAdjustment"-->
<!--                              title="1 is normal pricing. More than 1 will increase price. Between 0 and 1 will decrease price."-->
<!--                              :disabled="disableFields"/>-->
                </b-col>
                <b-col>
                    <fg-input label="ApiKey"
                              v-model="program.apiKey"
                              :disabled="disableFields"></fg-input>
                </b-col>
            </b-form-row>
            <b-form-row>
                    <b-col>
                        <fg-input label="Memo">
                            <textarea v-model="program.memo" :disabled="disableFields" class="form-control">
                            </textarea>
                        </fg-input>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <checkbox v-model="program.active" :disabled=disableFields inline>Active?
                        </checkbox>
                        <checkbox v-model="program.forceMemo" :disabled=disableFields inline>Force Memo?
                        </checkbox>
                        <checkbox v-model="program.autoprocess" :disabled=disableFields inline>Autoprocess?
                        </checkbox>

                    </b-col>
                </b-form-row>
                <hr>

                <!-- Functionality Flags-->
                <b-form-row>
                    <b-col v-for="f in flags" :key="f.id" cols="3" class="mb-2">
                        <checkbox v-model="f.value" :disabled="disableFields" inline>{{ f.name }}</checkbox>
                    </b-col>
                </b-form-row>
                <hr>

                <!-- Shipping Methods and Price Schedules -->
                <b-form-row>
                    <b-col>
                        <b-form-row>
                            <b-col>
                                <EnumSelector v-model="program.deliveryMethod" :clearable="true"
                                    :disabled="disableFields" :enum-codes="deliveryCodes" label="Delivery" />
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col>
                                <label>Carriers</label>
                                <multiselect v-model="program.carriers" :options="carrierOptions" group-label="label"
                                    group-values="carriers" :close-on-select="false" multiple group-select />
                            </b-col>
                        </b-form-row>

                    </b-col>
                    <b-col>
                        <fg-input label="Price Schedule">
                            <v-select v-model="selectedPriceSchedule" :disabled="disableFields"
                                :options='priceSchedules' class="style-chooser" label='name'></v-select>
                        </fg-input>
                    </b-col>
                </b-form-row>

                <hr>

                <template v-if="hasInventoryLocations">
                    <b-form-row>
                        <b-col>
                            <SearchComponent :id="program.defaultFillingLocation?.id"
                                v-model="program.defaultFillingLocation" :disabled="disableFields"
                                search-u-r-l="/inventory/inventory-locations" :prepopulate="true"
                                label="Default Inventory Filling Location" clearable />
                        </b-col>
                    </b-form-row>

                    <hr>
                </template>

                <!-- Contacts -->
                <label for=""><strong>Business Contact</strong></label>
                <b-form-row>
                    <b-col>
                        <fg-input v-model="businessContact.firstName" :disabled="disableFields" label="First Name">
                        </fg-input>
                    </b-col>
                    <b-col>
                        <fg-input v-model="businessContact.lastName" :disabled="disableFields" label="Last Name">
                        </fg-input>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <fg-input v-model="businessContact.phone" :disabled="disableFields" label="Phone">
                        </fg-input>
                    </b-col>
                    <b-col>
                        <fg-input v-model="businessContact.emailAddress" :disabled="disableFields"
                            label="Email Address">
                        </fg-input>
                    </b-col>
                </b-form-row>
                <hr>

                <label for=""><strong>Program Contact</strong></label>
                <b-form-row>
                    <b-col>
                        <fg-input v-model="programContact.firstName" :disabled="disableFields" label="First Name">
                        </fg-input>
                    </b-col>
                    <b-col>
                        <fg-input v-model="programContact.lastName" :disabled="disableFields" label="Last Name">
                        </fg-input>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <fg-input v-model="programContact.phone" :disabled="disableFields" label="Phone">
                        </fg-input>
                    </b-col>
                    <b-col>
                        <fg-input v-model="programContact.emailAddress" :disabled="disableFields" label="Email Address">
                        </fg-input>
                    </b-col>
                </b-form-row>

                <hr>
                <b-row>
                    <!-- Prescribers Selection -->
                    <b-col>
                        <b-row>
                            <b-col>
                                <SearchComponent v-model="selectedDoctor" :disabled="disableFields"
                                    :label="`Doctors in Program`" :search-u-r-l="`/Prescriber`" clearable
                                    @input="addDoctor">
                                </SearchComponent>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <EntitySelectionList :list="prescribers" @remove-entity="removePrescriber">
                                </EntitySelectionList>
                            </b-col>
                        </b-row>
                    </b-col>

                    <!-- Drugs Selection -->
                    <b-col>
                        <b-row>
                            <b-col>
                                <SearchComponent v-model="selectedDrug" :disabled="disableFields"
                                    label="Drugs in Program" search-u-r-l="/Drug" clearable @input="addDrug">
                                </SearchComponent>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <EntitySelectionList :list="drugs" @remove-entity="removeDrug" :custom-id="getDrugId">
                                    <template v-slot:inputs='props'>
                                        <b-col cols="4">
                                            <EnumSelector v-model="props.item.deliveryCode" label="Delivery"
                                                :enum-codes="deliveryCodes" :disabled="disableFields" :clearable="true">
                                            </EnumSelector>
                                        </b-col>
                                        <b-col cols="2">
                                            <b-form-group label="Autoselect: ">
                                                <b-checkbox v-if="!disableAutoselect(props.item)"
                                                    v-model="props.item.autoselect" size="lg"></b-checkbox>
                                                <span v-else>
                                                    <b>Already set</b> on {{ getAutoselectProgramName(props.item) }}
                                                </span>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="3">
                                            <span>Disable Autorefill: </span> <br>
                                            <Checkbox v-model="props.item.disableAutorefill" />
                                        </b-col>
                                        <b-col cols='3'>
                                            <b-form-group label="Awaits Prior Auth:">
                                                <b-checkbox v-model="props.item.awaitsPriorAuth" size="lg" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-group label="Minimum Margin">
                                                <b-form-input v-model="props.item.minimumMargin" number type="number" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-group label="Maximum Margin">
                                                <b-form-input v-model="props.item.maximumMargin" number type="number" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-group label="Maximum Copay">
                                                <b-form-input v-model="props.item.maximumCopay" number type="number" />
                                            </b-form-group>
                                        </b-col>
                                    </template>
                                </EntitySelectionList>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <hr>
                <b-row id="exclusive-transfer-row">
                    <b-col>
                        <search-component v-model="pharmacy" :id="pharmacy && pharmacy.id" :disabled="disableFields"
                            search-u-r-l="/StoredPharmacy" :params="{ showOnlyActive: true }"
                            label="Exclusive Transfer Pharmacy" clearable @input="pharmacySelected" />
                    </b-col>
                </b-row>

                <hr>
                <!-- CUSTOM PATIENT EDUCATION TEMPLATE  -->
                <label for=""><strong>Custom Patient Education Template</strong></label>
                <b-row v-if="program && program.customTemplateFileId">
                    <b-col>
                        A custom template was already uploaded for this program. Click <a
                            :href="`${serverURL}/Program/custom-template/${program.id}`">here</a> to download it.
                        <i>Last update: {{
                            program.customTemplateUpdated | formatDate('MM-DD-YYYY, h:mm:ss a')
                        }}</i>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <!-- Styled -->
                        <b-form-file v-model="customTemplateFile" :disabled="disableFields" accept=".docx"
                            drop-placeholder="Drop file here..."
                            placeholder="Choose your custom template or drop it here...">
                        </b-form-file>
                    </b-col>
                </b-row>

                <hr>

                <strong>Text Message Templates</strong>
                <TextMessageTemplatesField v-model="program.textMessageTemplates" :disabled="disableFields"
                    :hide-templates="['passwordReset', 'awaitingInsurance', 'welcome', 'outOfNetwork', 'pharmacyInfo', 'transfer', 'shipping']" />
            </form>

            <NewClearSaveButtons :disable="disableFields" :disableClear="disableFields" :disableSave="!isFormValid"
                :titles="buttonTitles" @clearClicked="clearForm" @newClicked="createProgram"
                @saveClicked="saveProgram" />

        </card>
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';

import TextMessageTemplatesField from "@/components/Configuration/TextMessageTemplatesField.vue";
import SearchComponent from '@/components/SearchComponent.vue';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import EntitySelectionList from "@/components/EntitySelectionList.vue";
import NewClearSaveButtons, { NewClearSaveTitles } from '@/components/NewClearSaveButtons.vue';
import EnumSelector from '@/components/EnumSelector.vue';
import Multiselect from "vue-multiselect";
import { Program } from '@/models/Program';
import { Prescriber } from '@/models/Prescriber';
import { Drug, DrugProgram } from '@/models/Drug/Drug';
import { Contact, ContactType } from "@/models/Contact";
import { FunctionalityFlag } from "@/models/FunctionalityFlag";
import { PriceSchedule } from "@/models/PriceSchedule";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { DeliveryCode } from '@/models/DeliveryCode';
import { OrderAuditConfiguration } from "@/models/OrderAudit";
import { DrugProduct, DrugSource } from '@/models/Drug/DrugProduct';

import { EntityType } from "@/models/EntityType";
import { PharmacyRecord } from "@/models/Pharmacy";

@Component({
    name: 'ProgramDetailsPage',
    components: {
        SearchComponent,
        Checkbox,
        NewClearSaveButtons,
        EntitySelectionList,
        EnumSelector,
        Multiselect,
        TextMessageTemplatesField
    }
})
export default class ProgramDetailsPage extends Mixins(DisableAutocompleteMixin) {
    private program: Program = new Program();
    private businessContact: Contact = { type: ContactType.Business } as Contact;
    private programContact: Contact = { type: ContactType.Program } as Contact;
    private flags: FunctionalityFlag[] = [];
    private clearFlags: FunctionalityFlag[] = [];
    private priceSchedules: PriceSchedule[] = [];
    private selectedPriceSchedule: PriceSchedule = {} as PriceSchedule;
    private customTemplateFile: File = new File([], "");
    protected deliveryCodes = DeliveryCode;

    protected selectedDoctor: Prescriber = new Prescriber();
    private prescribers: Array<Prescriber> = new Array<Prescriber>();

    protected selectedDrug: DrugProduct = new Drug();
    private drugs: Array<DrugProgram> = new Array<DrugProgram>();
    private orderAuditConfiguration: OrderAuditConfiguration = new OrderAuditConfiguration();
    protected pharmacy: PharmacyRecord = new PharmacyRecord();

    protected buttonTitles: NewClearSaveTitles = {
        save: "Save Changes",
        new: "New program",
        clear: "Clear",
        cancel: "",
    };

    protected carrierOptions = [{ label: "All Carriers", carriers: ['UPS', 'USPS', 'FedEx'] }];

    private newMode = false;

    get routeProgramId(): number {
        let res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get disableFields(): boolean {
        return !(this.program && this.program.id) && !this.newMode;
    }

    get serverURL() {
        return axios.defaults.baseURL;
    }

    get isFormValid(): boolean {
        return !!(this.program?.name && this.program?.abbreviation);
    }

    get auditChanceError(): string {
        if (this.orderAuditConfiguration.percentageChance == undefined) return "";
        if (this.orderAuditConfiguration.percentageChance > 100) return "Must be 100 or less";
        if (this.orderAuditConfiguration.percentageChance < 0) return "Must be 0 or greater";
        return "";
    }

    created() {
        console.log(`%c Created ${this.$options.name}`, "color: green");

        // Get available Functionality Flag
        axios.get(`/Program/${0}/functionalities`)
            .then(response => {
                this.clearFlags = response.data;
                this.flags = response.data;
            })
            .catch(error => {
                console.warn(error);
            });

        // Get Price Schedules
        axios.get(`/PriceSchedule`)
            .then(response => {
                this.priceSchedules = response.data;
            })
            .catch(error => {
                console.warn(error);
            });

        if (this.routeProgramId) {
            this.fetchProgram(this.routeProgramId);
        }

        this.loadHasInventoryLocations();
    }

    pharmacySelected() {
        console.log("program: ", JSON.stringify(this.program));
        console.log("pharmacy: ", JSON.stringify(this.pharmacy));
        this.program.transferToPharmacyId = this.pharmacy.id;
        console.log("program: ", JSON.stringify(this.program));
    }

    createProgram() {
        this.program = new Program();
        this.program.active = true;
        this.newMode = true;
        this.flags = this.clearFlags;
    }

    clearForm() {
        this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
            title: 'Confirm',
            okVariant: 'danger',
            centered: true,
        })
            .then(value => {
                if (!value) return;
                this.program = new Program();
                this.newMode = false;
                this.businessContact = { type: ContactType.Business } as Contact;
                this.programContact = { type: ContactType.Program } as Contact;
                this.selectedPriceSchedule = {} as PriceSchedule;
                this.flags = this.clearFlags;
                this.selectedDoctor = new Prescriber();
                this.selectedDrug = new Drug();
                this.drugs = new Array<DrugProgram>();
                this.prescribers = new Array<Prescriber>();
                this.orderAuditConfiguration = new OrderAuditConfiguration();
                this.pharmacy = new PharmacyRecord();
            })
            .catch(err => {
                console.log("Error caught in clearForm()");
                console.error(err);
            });
    }

    getAutoselectProgramName(item: DrugProgram) {
        return item.autoselectProgram && item.autoselectProgram.abbreviation;
    }

    saveProgram() {
        let errorMessage: string;
        let typeMessage: NotificationOptions.NotificationTypes;

        if (this.program) {
            const data = this.program;
            data.programContact = this.programContact;
            data.businessContact = this.businessContact;
            data.priceSchedule = this?.selectedPriceSchedule?.name ? this.selectedPriceSchedule : null;
            data.priceScheduleId = this.selectedPriceSchedule?.name ? this.selectedPriceSchedule.id : null;
            data.flags = this.flags;
            data.prescribers = this.prescribers;
            data.drugs = this.drugs;
            const orderAuditConfigurations = this.program.orderAuditConfigurations;

            axios.post<Program>('api/program', data)
                .then(response => {
                    this.program = new Program(response.data);
                    errorMessage = "Program Successfully Saved";
                    typeMessage = NotificationOptions.NotificationTypes.success;

                    // Upload custom table if there is any
                    if (this.customTemplateFile.size > 0) {
                        const formData = new FormData();
                        formData.append('customTemplate', this.customTemplateFile);
                        axios.post<Program>(`api/program/custom-template/${this.program.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                            .then(response => {
                                this.program = new Program(response.data);
                                if (this.program.customTemplateFileId > 0) {
                                    this.$notification(NotificationOptions.successSaveNotificationPreset('Custom Template'));
                                    console.log(response);
                                }
                            })
                            .catch(err => {
                                this.$notification(NotificationOptions.errorSaveNotificationPreset('Custom Template'));
                                console.error(err, { err, response: err?.response });
                            });
                    }

                    this.orderAuditConfiguration.entityId = +this.program.id;
                    this.orderAuditConfiguration.entityType = EntityType.Program;
                    this.orderAuditConfiguration.sanitize();

                    let orderAuditConfigs = [];
                    for (const drug of this.drugs) {
                        let orderAuditConfig = orderAuditConfigurations?.find(v => v.id == drug.orderAuditId)
                        if (!orderAuditConfig) {
                            orderAuditConfig = new OrderAuditConfiguration();
                        } else {
                            orderAuditConfig = new OrderAuditConfiguration(orderAuditConfig);
                        }
                        orderAuditConfig.entityId = +this.program.id;
                        orderAuditConfig.entityType = EntityType.Program;
                        orderAuditConfig.minimumMargin = drug.minimumMargin;
                        orderAuditConfig.maximumMargin = drug.maximumMargin;
                        orderAuditConfig.maximumCopay = drug.maximumCopay;
                        orderAuditConfig.drugId = drug.packageID;
                        orderAuditConfig.drugSource = DrugSource.GsddFederal;
                        orderAuditConfig.sanitize();

                        orderAuditConfigs.push(orderAuditConfig);
                    }
                    orderAuditConfigs.push(this.orderAuditConfiguration);

                    axios.post(`/api/Program/orderAuditConfigurations`, orderAuditConfigs)
                        .then(resp => {
                            console.log("resp: ", resp);
                        })
                        .catch(err => {
                            this.$notification(NotificationOptions.error(err));
                        })
                        .finally(() => {
                            this.onProgramUpdate(this.program);
                        });
                })
                .catch(error => {
                    // probably do some smart error handling here at some point.
                    errorMessage = "Program was not able to be saved" + ` - ${error.response?.data}`;
                    typeMessage = NotificationOptions.NotificationTypes.danger;
                    console.error("Error on saving program", error);
                })
                .finally(() => {
                    this.$notification(NotificationOptions.notificationOptionsPreset(errorMessage, typeMessage));
                });
        } else {
            console.warn("We have no program to save, get a new one?");
        }
    }

    @Watch('$route.params.id')
    onParamChanged(value: string, oldValue: string) {
        if (value != oldValue) {
            this.fetchProgram(Number(value));
        }
    }

    @Watch('program')
    onProgramChanged(value: Program | null, _oldValue: Program | null) {
        if (!value) return;

        if (value.id && value.id != 0 && value.id != this.routeProgramId) {
            this.$router.push({ name: 'ProgramDetails', params: { id: value.id.toString() } });
        }

        if (this.routeProgramId && value.id == 0) {
            this.$router.push({ name: 'Program' });
        }
    }

    fetchProgram(programId: number) {
        if (!programId) {
            this.program = new Program();
            return;
        }
        // Get Contacts
        axios.get<Array<Contact>>(`/Program/${programId}/contacts`)
            .then(response => {
                const contacts = response.data;
                this.programContact = contacts.filter(con => con.type == ContactType.Program)[0] || ({ type: ContactType.Program } as Contact);
                this.businessContact = contacts.filter(con => con.type == ContactType.Business)[0] || ({ type: ContactType.Business } as Contact);
                this.customTemplateFile = new File([], "");
                this.selectedDoctor = new Prescriber();
                this.selectedDrug = new Drug();
            })
            .catch(error => {
                console.warn(error);
            });

        axios.get<Program>(`api/Program/${programId}`, {
            params: {
                includeDrugs: true,
                includePrescribers: true,
                includeFlags: true,
                includeAuditConfigurations: true,
            }
        })
            .then(response => {
                if (response.data?.id) {
                    const program: Program = new Program(response.data);
                    this.program = program;
                    this.drugs = new Array<DrugProgram>();
                    if (program?.drugs?.length) {
                        this.drugs = program.drugs?.map(dp => new DrugProgram(dp));
                    }

                    this.prescribers = program.prescribers?.map(doc => Object.assign(new Prescriber(), doc)) || ([] as Array<Prescriber>);
                    this.flags = program.flags;
                    this.selectedPriceSchedule = this.priceSchedules.filter(sm => sm.id == this.program?.priceSchedule?.id)[0];
                    if (this.program.orderAuditConfigurations?.length) {
                        const auditConfigurations = this.program.orderAuditConfigurations;
                        this.orderAuditConfiguration = new OrderAuditConfiguration(auditConfigurations.find(v => !v.drugId));
                        for (let drug of this.drugs) {
                            let drugAuditConfig = auditConfigurations.find(v => v.drugId == drug.packageID);
                            if (drugAuditConfig) {
                                drug.orderAuditId = drugAuditConfig.id;
                                drug.minimumMargin = drugAuditConfig.minimumMargin;
                                drug.maximumMargin = drugAuditConfig.maximumMargin;
                                drug.maximumCopay = drugAuditConfig.maximumCopay;
                            } else {
                                console.warn("no config found for drug: ", drug);
                            }
                        }
                    }

                    if (this.program.transferToPharmacyId) {
                        axios.get<PharmacyRecord>(`/api/StoredPharmacy/${this.program.transferToPharmacyId}`)
                            .then(resp => {
                                this.pharmacy = new PharmacyRecord(resp.data);
                            });
                    }
                }
            })
            .catch(error => {
                console.error('Error while getting program data.', { error, response: error?.response });
            });
    }

    onProgramUpdate(value: Program) {
        return this.fetchProgram(value.id);
    }

    addDoctor(val: Prescriber) {
        if (val && val.id) {
            const wasAdded = this.prescribers.find(doc => doc.id == val.id);
            if (!wasAdded)
                this.prescribers.push(val);
        }
    }

    removePrescriber(prescriber: Prescriber) {
        this.prescribers = this.prescribers.filter(doc => doc.id != prescriber.id);
    }

    addDrug(val: DrugProduct) {
        if (val && val.drugId) {
            const wasAdded = this.drugs.filter(doc => doc.packageID == Number(val.drugId))[0];
            if (!wasAdded) {
                const dp = new DrugProgram();
                dp.drug = val;

                let url = `api/drug/${val.drugId}/${val.source}/Program`;

                //Find if selected drug has already been set for autoselect for another program
                this.$http.get<Array<DrugProgram>>(url)
                    .then(res => {
                        if (res.data?.length) {
                            //Get programs for drug and find the one with autoselect
                            const dps = res.data.map(dp => new DrugProgram(dp));
                            const autoselectProgram = dps.find(dp => dp.autoselect)?.program || null;
                            dp.autoselect = false;
                            dp.autoselectProgram = autoselectProgram;
                        }
                    })
                    .catch(err => {
                        console.error('Error getting Program information for selected drug', {
                            err,
                            response: err?.response
                        });
                    })
                    .finally(() => {
                        this.drugs.push(dp);
                    });
            }
        }
    }

    disableAutoselect(dp: DrugProgram): boolean {
        return !!(dp.autoselectProgram?.id && dp.autoselectProgram?.id != this.program.id);
    }

    removeDrug(drug: DrugProgram) {
        let idx = this.drugs.findIndex(dg => dg.packageID == drug.packageID && dg.drugSource == drug.drugSource);
        if (idx != -1) {
            this.drugs.splice(idx, 1);
        }
        // this.drugs = this.drugs.filter(dg => dg.packageID != drug.packageID && dg.drugSource != drug.drugSource);
    }

    getDrugId(drug: DrugProgram) {
        return `${drug.drugSource}-${drug.packageID}`;
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await axios.get<boolean>("/inventory/has-inventory-locations")).data;
        } catch {
            //ignore
        }
    }

} // close export of class

</script>


<style scoped>
/*noinspection CssUnusedSymbol*/
.form-check {
    margin-top: 0;
    padding-left: 0;
}
</style>
