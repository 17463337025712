
/*noinspection CssUnusedSymbol*/
::v-deep .modal-dialog {
    right: 20px;
    position: absolute;
    min-width: 600px;
}

#prescription-image {
    min-height: 880px;
}

.image-wrapper {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch !important;

}

.script-image {
    height: 100vh;
    border: 1px solid black;
    flex-grow: 1;
    max-height: 100vh !important;
}
