<template>
    <b-container fluid="xs">
        <b-row v-if="!hideTitle">
            <b-col>
                <hr>
                <h5>Quantity Pricing</h5>
            </b-col>
        </b-row>
        <b-row>

        </b-row>
        <b-row v-if="onAdd" align-v="end">
            <b-col v-if="!defaultDrug" cols="5">
                <search-component v-model="drug" label="Drug" :params="{ onlyActive: true }" search-u-r-l="/api/Drug"
                    :disabled="disabled" />
            </b-col>
            <b-col>
                <b-form-group label="Quantity" :disabled="disabled">
                    <b-form-input type="number" v-model.number="newQuantity.quantity" />
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Unit Price" :disabled="disabled">
                    <b-form-input type="number" v-model.number="newQuantity.unitPrice" />
                </b-form-group>
            </b-col>
            <b-col class="text-center">
                <b-form-group label="Include Equivalent" :disabled="disabled">
                    <b-form-checkbox class="my-2" v-model="newQuantity.includeEquivalent" />
                </b-form-group>
            </b-col>
            <b-col class="text-center">
                <b-button variant="success" @click="addQuantityPricing" :disabled="disabled">Add</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>

                <b-table id="qty-price-table" ref="qty-price-table" api-url="/api/PriceSchedule/quantity-pricing"
                    :filter="tableFilter" :fields="fields" :items="provider" :per-page="perPage"
                    :current-page="currentPage" :tbody-tr-class="getQuantityRowClass"
                    :sort-by="defaultDrug ? 'quantity' : undefined" sort-icon-left no-provider-sorting
                    no-provider-paging>

                    <template #cell(quantity)="{ item, index }">
                        <div v-if="editingRowIndex === index">
                            <b-form-input v-model="editingRowData.quantity" class="qty" type="number"
                                min="0"></b-form-input>
                        </div>
                        <div v-else>
                            {{ item?.quantity }}
                        </div>
                    </template>

                    <template #cell(unitPrice)="{ item, index }">
                        <div v-if="editingRowIndex === index">
                            <b-form-input v-model="editingRowData.unitPrice" class="unit-price" type="number"
                                min="0"></b-form-input>
                        </div>
                        <div v-else>
                            {{ item?.unitPrice }}
                        </div>
                    </template>

                    <template #cell(includeEquivalent)="{ item, index }">
                        <div v-if="editingRowIndex === index">
                            <b-form-checkbox v-model="editingRowData.includeEquivalent" />
                        </div>
                        <div v-else>
                            {{ item.includeEquivalent ? '✅' : '❌' }}
                        </div>
                    </template>

                    <template #cell(actions)="{ item, index }">
                        <div class="text-right">
                            <template v-if="editingRowIndex !== index">
                                <b-button variant="link" @click.prevent="startEdit(item, index)" title="Edit">
                                    <b-icon icon="pencil" variant="primary"></b-icon>
                                </b-button>
                                <b-button variant="link" @click.prevent="removeItem(item)" title="Remove">
                                    <b-icon icon="x-circle-fill" variant="danger"></b-icon>
                                </b-button>
                            </template>
                            <template v-else>
                                <b-button variant="link" title="Save" @click.prevent="saveEdit">
                                    <b-icon icon="save" variant="primary"></b-icon>
                                </b-button>
                                <b-button variant="link" title="Cancel Edit" @click.prevent="editingRowIndex = -1">
                                    <b-icon icon="x-lg" variant=""></b-icon>
                                </b-button>
                            </template>
                        </div>
                    </template>
                </b-table>

            </b-col>
        </b-row>
        <BottomPagerBar v-model="currentPage" :total-rows="rows" :per-page="perPage" no-stick show-total
            show-page-size-selector @change-page-size="perPage = $event" />
    </b-container>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { DrugProduct } from "@/models/Drug/DrugProduct";
import { BTable, BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
import axios, { AxiosRequestConfig } from "axios";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { DrugQuantityPricing } from "@/models/Drug/DrugQuantityPricing";
import { PriceSchedule } from "@/models/PriceSchedule";
import SearchComponent from "@/components/SearchComponent.vue";
import { Drug } from "@/models/Drug/Drug";
import BottomPagerBar from "../BottomPagerBar.vue";

@Component({
    name: 'QuantityPricingComponent',
    components: { SearchComponent, BottomPagerBar },
})
export default class QuantityPricingComponent extends Vue {
    @Prop({ required: true }) priceSchedule!: PriceSchedule;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ required: false }) onAdd?: Function;
    @Prop({ required: false }) defaultDrug!: Drug;
    @Prop({ required: false }) hideTitle!: boolean;
    @Prop({ required: false }) highlightQuantity!: number;
    @Ref('qty-price-table') table!: BTable;
    drug?: DrugProduct = this.defaultDrug ?? new Drug();
    newQuantity: DrugQuantityPricing = this.emptyQuantity;

    editingRowIndex: number = -1;
    editingRowData: any = {};

    perPage: number = 25;
    currentPage: number = 1;

    get rows(): number {
        return this.items.length ?? 0;
    }

    get fields(): BvTableFieldArray {

        const fields: BvTableFieldArray = [
            { key: "quantity", sortable: true },
            {
                key: "unitPrice",
                formatter: (value: number) => `$${value.toLocaleString("en-US", { minimumFractionDigits: 2 })}`,
            },
            {
                key: "includeEquivalent",
                formatter: (value: boolean) => value ? '✅' : '❌',
                tdClass: "text-center",
                thClass: "text-center",
            },
        ];

        if (this.onAdd) {
            fields.push({ key: "actions", label: "" });
        }

        if (!this.defaultDrug) {
            fields.unshift({ key: "drugName", label: "Drug", sortable: true }, {
                key: "drugNDC11",
                label: "NDC",
                sortable: true,
            });
        }

        return fields;
    }

    get emptyQuantity(): DrugQuantityPricing {
        return {
            priceScheduleId: this.priceSchedule.id,
            drugId: this.drug?.drugId,
            drugSource: this.drug?.source,
            quantity: 0,
            unitPrice: 0,
            includeEquivalent: false,
        } as DrugQuantityPricing;
    }

    @Watch('drug')
    clear() {
        this.newQuantity = this.emptyQuantity;
        this.newQuantity.quantity = 0;
        this.newQuantity.unitPrice = 0;
        this.newQuantity.includeEquivalent = false;
    }

    public clearDrug() {
        this.drug = new Drug();
    }

    mounted() {
        this.clear();
    }

    get tableFilter(): AxiosRequestConfig {
        let filter: AxiosRequestConfig;
        filter = {
            params: {
                priceScheduleId: this.priceSchedule.id,
                drugId: this.drug?.drugId,
                drugSource: this.drug?.source,
            },
        };
        return filter;
    }

    async addQuantityPricing() {
        if (!this.onAdd) return;

        try {
            if (!this.priceSchedule.id) {
                let ps = await this.onAdd();
                this.newQuantity.priceScheduleId = ps.id;
            }
            if (!this.newQuantity.drugId) return;
            await axios.post("/api/PriceSchedule/quantity-pricing", this.newQuantity);
            this.clear();
            this.$emit("added", this.newQuantity);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }

        this.table.refresh();
    }

    private items: any = [];

    async provider(ctx: BvTableCtxObject) {
        const uri = ctx.apiUrl + "";
        const config = ctx.filter as AxiosRequestConfig;
        if (!this.priceSchedule?.id) return [];
        try {
            const resp = await axios.get(uri, config);
            this.items = resp.data;
            return resp.data;
        } catch (err) {
            return [];
        }
    }

    get sortedItems() {
        return this.items?.toSorted((a: any, b: any) => a.quantity - b.quantity);
    }

    get highlightMinLine() {
        if (!this.highlightQuantity) return null;
        return this.sortedItems.filter((ql: any) => ql.quantity <= this.highlightQuantity)?.at(-1) ?? null;
    }

    get highlightMaxLine() {
        if (!this.highlightQuantity ||
            !this.priceSchedule.useLinearInterpolation ||
            this.highlightMinLine?.quantity === this.highlightQuantity
        ) return null;
        return this.sortedItems.filter((ql: any) => ql.quantity > this.highlightQuantity)[0] ?? null;
    }

    getQuantityRowClass(item: any) {
        return (item.id === this.highlightMinLine?.id || item.id === this.highlightMaxLine?.id) ? "table-success" : "";
    }

    async removeItem(item: DrugQuantityPricing) {
        try {
            await axios.delete("/api/PriceSchedule/quantity-pricing", {
                params: {
                    id: item.id,
                },
            });
            this.$emit("removed", item);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
        this.table.refresh();
    }

    startEdit(item: object, index: number) {
        this.editingRowIndex = index;
        this.editingRowData = { ...item };
    }

    async saveEdit() {
        try {
            await axios.put("/api/PriceSchedule/quantity-pricing", this.editingRowData);
            this.editingRowIndex = -1;
            this.$emit("edited", this.editingRowData);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
        this.table.refresh();
    }
}
</script>

<style scoped>
input.qty,
input.unit-price {
    width: 150px;
}
</style>
