<template>
    <card title="Reports">
        <b-row align-v="end">
            <b-col cols="2">
                <b-form-group>
                    <label for="typeChooser">Type</label>
                    <v-select id="typeChooser"
                              v-model="searchType"
                              class="style-chooser"
                              :clearable="false"
                              :options="searchTypes">
                    </v-select>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                
                <b-input v-model="days" type="number"></b-input>
                <search-component v-model="store" label="Store" prepopulate search-u-r-l="/Store"></search-component>
                <!-- <fg-input>
                    <el-date-picker v-model="fromDate" type="date"></el-date-picker>
                </fg-input>
                <fg-input>
                    <el-date-picker v-model="toDate" type="date"></el-date-picker>
                </fg-input> -->
            </b-col>
            <b-col cols="2">
                <b-button variant="success"
                          :block="true"
                          @click="savePDF">
                    Save as PDF
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-button variant="info"
                          :block="true"
                          @click="allClicked">
                    All Time
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-button variant="danger"
                          :block="true"
                          @click="clearClicked">
                    Clear
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table id="report-table"
                :items="reportItems"
                :fields="reportFields">

                </b-table>
            </b-col>
        </b-row>
        <b-row v-if="false" id="reports-row">
            <b-col>
                <b-row v-show="reportItems.length" class="py-3">
                    <b-col>
                        <label for="report-table">{{reportTitle}}</label>
                        <b-table id="report-table"
                                 striped
                                 outlined
                                 foot-clone
                                 selectable
                                 responsive="true"
                                 :items="reportItems"
                                 :fields="reportFields"
                                 :sort-by="sortBy"
                                 selected-variant="warning">

                            <template slot="foot(totalPrice)"
                                      slot-scope="data">
                                {{totalCostFootCall(data)}}
                            </template>

                            <template slot="foot()"
                                      slot-scope="data">
                                {{""}}
                            </template>

                            <template slot="cell(totalPrice)"
                                      slot-scope="data">
                                {{totalPriceCellCall(data)}}
                            </template>

                        </b-table>
                    </b-col>
                </b-row>
                <b-row v-show="subReportItems.length" class="py-3">
                    <b-col>
                        <label for="sub-report-table">{{subReportTitle}}</label>
                        <b-table id="sub-report-table"
                                 striped
                                 outlined
                                 responsive="true"
                                 :items="subReportItems"
                                 :fields="subReportFields"
                                 :sort-by="subSortBy"
                                 selected-variant="warning">
                        </b-table>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

    </card>
</template>

<script lang="ts">
    import { Component, Watch, Mixins } from 'vue-property-decorator';
    import { DatePicker } from 'element-ui';
    import { Store } from '@/models/Store';
    import { ReportExporter } from '@/mixins/ReportExporter';
    import axios from 'axios';
    import moment from 'moment';
    import SearchComponent from '@/components/SearchComponent.vue';

    @Component({
        name: "ReportsComponent",
        components: {
            [DatePicker.name]: DatePicker,
            SearchComponent,
        }
    })
    export default class ReportsComponent extends Mixins(ReportExporter) {
        created() {
            console.log(`%c Created ${this.$options.name}`, "color: green");
            // hotkeys on
        }
        beforeDestroy() {
            console.log(`%c Destroying ${this.$options.name}`, "color: red");
            //hotkeys off
        }

        private fromDate: Date = moment().subtract(14, 'd').toDate();
        private toDate: Date = new Date;

        private days: number = 7;
        private store: Store = new Store();

        private searchTypes: string[] = ["", "Ordering", "Ordering with shortages"];
        private searchType = "";

        private sortBy: any = null;
        private reportTitle = "";
        private reportItems: any[] = [];
        private reportFields: any[] = [];

        private subSortBy: any = null;
        private subReportTitle = "";
        private subReportItems: any[] = [];
        private subReportFields: any[] = [];

        private ndcFormatter = (value: string, _key: string, _item:any) => {
            return `${value.substr(0, 5)}-${value.substr(5, 4)}-${value.substr(9)}`;
        };

        totalPriceCellCall(data: any) {
            if (data.value == "$0.00") {
                data.selectRow();
                return "MISSING VENDOR INFORMATION";
            } else {
                data.unselectRow();
                return data.value;
            }
        }

        @Watch('searchType')
        @Watch('fromDate')
        @Watch('toDate')
        @Watch('days')
        @Watch('store.id')
        getReport() {
            if (this.searchType == "") return;
            const config = {
                params: {

                }
            }

            if (this.searchType == "Ordering") {
                config.params = {
                    fromDate: moment(this.fromDate).startOf('day').utc().toDate(),
                    toDate: moment(this.toDate).startOf('day').utc().toDate(),
                };
                return this.getOrderingReport(config);
            }
            if (this.searchType == "Ordering with shortages"){
                config.params = {
                    days: this.days,
                    storeId: this.store?.id
                }
                return this.getOrderingReportWithShortages(config);
            }

            const reportPath = `/reports/${this.searchType}`;
            axios.get(reportPath, config)
                .then(response => console.log('response.data from %s:%o', reportPath, response.data))
                .catch(error => console.warn(error));
        }

        totalCostFootCall(data: any) {
            const total = this.reportItems.reduce((total, item) => total + this.calculateCost(item), 0);
            return `Grand Total: $${total}`;
        }

        calculateCost(item: any) {
            return Math.round(item.unitPrice * item.quantity * 100) / 100;
        }

        async getOrderingReport(config: any) {
            return axios.get('reports/ordering', config)
                .then(response => {

                    const results: any[] = response.data;
                    results.map(p => p.result);

                    this.reportTitle = "Ordering";
                    this.reportItems = response.data;
                    this.reportFields = [
                        { label: "Vendor Name", key: "vendorName", },
                        { label: "Drug NDC"   , key: "drugNDC", formatter: this.ndcFormatter, sortable: true, },
                        { label: "Drug Name"  , key: "drugName", },
                        { label: "Total Qty"  , key: "quantity", },
                        { label: "Unit Price" , key: "unitPrice", formatter: (p: any) => `$${p.toFixed(2)}`, },
                        { label: "Total Price", key: "totalPrice", formatter: (p: any) => `$${p.toFixed(2)}`, },
                    ];
                    this.sortBy = this.reportFields[0].key;//"vendorName";

                    this.subReportItems = this.reportItems.reduce(function (storage: any[], item: any) {
                            const group = item["vendorName"];
                            if (group == null) return storage;
                            const idx = storage.findIndex(p => p.vendorName == group);
                            if (idx >= 0) {
                                storage[idx].totalPrice = storage[idx].totalPrice + item.totalPrice;
                            } else {
                                storage.push({vendorName: item.vendorName, totalPrice: item.totalPrice});
                            }
                            return storage;
                        }, []);
                    this.subReportTitle = "Totals By Vendor";
                    this.subReportFields = [
                        { key: "vendorName", sortable: true },
                        { key:"totalPrice", formatter: (p: any)=>`$${p.toFixed(2)}`, sortable: true},
                    ];
                    return response.data;
                })
                .catch(error => {
                    throw error;
                });
        }
        async getOrderingReportWithShortages(config: any){
            return axios.get('reports/OrderingReportWithShortages', config)
                .then(response => {

                    const results: any[] = response.data;
                    results.map(p => p.result);

                    this.reportTitle = "Ordering with shortages";
                    this.reportItems = response.data;
                    this.reportFields = [
                        { label: "Store Name", key: "StoreName", },
                        { label: "Program Name", key: "ProgramName", },
                        { label: "Drug Name"  , key: "ProductNameShort", },
                        { label: "Drug NDC"   , key: "NDC11", formatter: this.ndcFormatter, sortable: true,  },
                        { label: "Vendor Name", key: "VendorName", },
                        { label: "7 day usage", key: "DispensedQty", },
                        { label: "Packages Dispensed", key: "PackagesDispensed", },
                        { label: "On Hand", key: "InventoryQty", },
                        { label: "Allocated", key: "AllocatedQty", },
                        { label: "Reported Shortage", key: "ShortageReported", },
                        { label: "Amount to Order", key: "AmtToOrder", formatter:(amountToOrder:number,key:string,item:any) => Math.max(amountToOrder, item.ShortageReported)},
                        { label: "Packages to Order", key: "PackagesToOrder", formatter:(v:any,k:string,item:any) => Math.max(item.AmtToOrder, item.ShortageReported) / (item?.PackageSize ?? 1) },
                        // { label: "Unit Price" , key: "unitPrice", formatter: (p: any) => `$${p.toFixed(2)}`, },
                        // { label: "Total Price", key: "totalPrice", formatter: (p: any) => `$${p.toFixed(2)}`, },
                    ];
                    this.sortBy = this.reportFields[0].key;//"vendorName";

                    this.subReportItems = this.reportItems.reduce(function (storage: any[], item: any) {
                            const group = item["VendorName"];
                            if (group == null) return storage;
                            const idx = storage.findIndex(p => p.vendorName == group);
                            if (idx >= 0) {
                                storage[idx].totalPrice = storage[idx].totalPrice + item.totalPrice;
                            } else {
                                storage.push({vendorName: item.vendorName, totalPrice: item.totalPrice});
                            }
                            return storage;
                        }, []);
                    this.subReportTitle = "Totals By Vendor";
                    this.subReportFields = [
                        { key: "vendorName", sortable: true },
                        { key:"totalPrice", formatter: (p: any)=>`$${p.toFixed(2)}`, sortable: true},
                    ];
                    return response.data;
                })
                .catch(error => {
                    throw error;
                });
        }
        savePDF() {
            const name = `${moment().format('L')}-ordering`;
            this.ExportPDF(name, "#report-table")
        }

        allClicked() {
            if (this.searchType == "Ordering") {
                return this.getOrderingReport({});
                    //.then(response => {
                    //})
                    //.catch(error => {
                    //});
            }
            if (this.searchType == "Ordering with shortages") {
                return this.getOrderingReportWithShortages({});
            }
        }

        clearClicked() {
            this.reportTitle = "";
            this.reportFields = [];
            
        }
    }

</script>

<style scoped>
    .vdp-datepicker {
        color: #000000;
    }
</style>
