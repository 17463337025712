<template>
    <b-modal :id="id" size="lg" title="Prior Authorization" @show="onShow" @hide="$emit('hide')" @hidden="resetModal">
        <b-container fluid v-if="value.prescription">

            <b-row v-if="lastDocumentDate != null">
                <b-col>
                    <b-alert show variant="info">
                        Last document uploaded for this patient was at {{ lastDocumentDate | formatDate('lll') }}.
                        <b>
                            <a style="color:white" @click.prevent v-b-modal.patient_documents_modal>
                                See Documents...
                            </a>
                        </b>
                    </b-alert>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b>Patient: </b> <span>{{ patientName }}</span>
                </b-col>
                <b-col style="text-align:right">
                    <router-link target="_blank" :to="{ name: 'PatientDashboard', params: { id: value.patient.id } }">
                        <span>Go To Profile</span> <i class="fas fa-user"></i>
                    </router-link>
                    <span> | </span>
                    <router-link target="_blank" :to="prescriptionDetailsRoute">
                        <span>Go To Rx</span> <i class="fas fa-file-prescription"></i>
                    </router-link>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Status: </strong>{{ status }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Drug: </strong>{{ value.prescription.productNameShort }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Prescriber: </strong>{{ value.prescriber.lastName }}, {{ value.prescriber.firstName }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Initiated: </strong>{{ createDate }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Last Updated: </strong>{{ updateDate }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>Rx Id: </strong>{{ value.prescription.storeID }}-{{ value.prescription.rxNumber }}-{{
        value.prescription.rfNumber }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>CMM Id: </strong>{{ value.coverMyMedsId }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>CMM Event: </strong>{{ value.latestCallback.event_key }} - {{ value.latestCallback.message
                    }}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <strong>CMM: </strong>
                    <button type="button" class="btn-link" @click="coverMyMedsClick">Click Here to View Cover My Meds
                        Profile</button>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table-lite id="notes-table" :fields="noteFields" :items="notes">
                        <template #cell(created)="data">
                            <b-row>
                                <b-col class="note-body">
                                    {{ data.item.body }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="text-secondary">{{ data.item.createdBy }}</b-col>
                                <b-col class="text-secondary">{{ data.value }}</b-col>
                            </b-row>
                        </template>
                    </b-table-lite>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-textarea id="textarea" v-model="newNoteBody" rows="3" max-rows="3"></b-form-textarea>
                </b-col>
            </b-row>
            <hr>
            <CoverMyMedsResponseComponent v-model="cmmResponse" :displayValue="displayValue" />
        </b-container>

        <template #modal-footer>
            <b-row class="w-100">
                <b-col class="text-right">
                    <b-dropdown id="template-dropdown" variant="primary" text="Templates" size="sm">
                        <b-dropdown-item v-for="template in templates" :key="template.value" :value="template.value"
                            @click="templateClick(template.value)">{{ template.text }}</b-dropdown-item>
                    </b-dropdown>
                    <b-button variant="success" size="sm" class="mx-1" v-b-modal.cmm-id-add>Add CMM Id</b-button>
                    <b-button variant="outline-info" size="sm" class="mx-1" @click="saveNoteClick">Save Note</b-button>
                    <b-dropdown id="status-dropdown" variant="primary" text="Update Status" size="sm">
                        <b-dropdown-item @click="sentToPrescriberClick">Sent to Prescriber</b-dropdown-item>
                        <b-dropdown-item @click="submittedClick">Submitted</b-dropdown-item>
                        <b-dropdown-item @click="abandonedClick" variant="danger">Abandoned</b-dropdown-item>
                        <b-dropdown-item @click="approvedClick" variant="success">Approved</b-dropdown-item>
                        <b-dropdown-item @click="deniedClick" variant="danger">Denied</b-dropdown-item>
                        <b-dropdown-item @click="cancelledClick" variant="danger">Cancelled</b-dropdown-item>
                    </b-dropdown>
                    <b-button variant="success" @click="createPA" v-if="!value.coverMyMedsId" size="sm">Start
                        PA</b-button>
                </b-col>
            </b-row>
        </template>
        <b-modal id="cmm-id-add" title="Add CMM Id" @ok="cmmOk">
            <b-container>
                <b-row>
                    <b-col>
                        <b-input v-model="value.coverMyMedsId"></b-input>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <b-modal id="complete-reason-modal" title="Complete Reason" @hide="completeHide">
            <b-container>
                <b-row>
                    <b-col>
                        <b-input v-model="value.completeReason"></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-select @input="completeChosen" :options="completeReasons"></b-form-select>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>

        <PatientDocumentsModal ref="patient-documents-modal" v-if="patientName" :patient="value.patient">
        </PatientDocumentsModal>

        <button v-b-modal.patient_documents_modal>Docs</button>
    </b-modal>
</template>

<script lang="ts">

import Card from "@/components/Cards/Card.vue";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import { PriorAuthorization, PriorAuthorizationStatusCode, CoverMyMedsResponse } from "@/models/PriorAuthorization";
import CoverMyMedsResponseComponent from "@/components/PriorAuthorization/CoverMyMedsResponseComponent.vue";
import moment from "moment";
import Axios from 'axios';
import { NotificationOptions } from '../../util/NotificationOptionsPresets';
import { Prescription } from "@/models/Prescription";
import { Patient } from "@/models/Patient";
import { PatientDocument } from "@/models/PatientDocument";
import PatientDocumentsModal from '@/components/Patient/PatientDocumentsModal.vue';

@Component({
    name: "PriorAuthorizationDetails",
    components: {
        Card, CoverMyMedsResponseComponent, PatientDocumentsModal
    }
})
export default class PriorAuthorizationDetails extends Vue {
    @Ref('patient-documents-modal') patientDocumentsModal!: PatientDocumentsModal;
    @Prop() protected value!: PriorAuthorization;
    @Prop() private id!: string;
    private notes: any[] = [];
    private newNoteBody: string = "";
    private cmmResponse: CoverMyMedsResponse = {} as CoverMyMedsResponse;

    private templates: any[] = [
        { value: "1", text: "NO PA'S PER MDO" },
        { value: "2", text: "NO RESPONSE FROM MDO" },
        { value: "3", text: "LVM @ MDO TO FOLLOW UP" },
        { value: "4", text: "FAXED MDO TO FOLLOW UP" },
        { value: "6", text: "RECEIVED ADDITIONAL INFORMATION, SENT TO PLAN" },
        { value: "8", text: "NO RESPONSE FROM PLAN, TEST CLAIM PAYS, MARKING AS APPROVED" },
        { value: "9", text: "NO RESPONSE FROM PLAN, TEST CLAIM REJECTS, MARKING AS DENIED" },
        { value: "10", text: "PATIENT DECLINED MEDICATION" },
        { value: "11", text: "NOT A PA REJECTION" },
        { value: "12", text: "NO PATIENT CONTACT" },
        { value: "13", text: "NOT CONTRACTED WITH PLAN" },
    ];

    private completeReasons: string[] = [
        "No response from MDO",
        "Not approved for patient dx",
        "Unknown/other reason",
        "Patient doesn't meet criteria",
        "Other/IT Issue",
        "No patient contact",
        "Patient refused medication",
        "Not PA rejection 75",
        "MDO doesn't do prior auths",
        "Not in network",
        "Transferred out",
        "Plan exclusion",
        "Paid claim",
        "Wrong Rx#/Duplicate Request",
        "Cancelled by MDO",
        "Business Rule Cancelled"
    ];

    completeChosen(item: any) {
        this.value.completeReason = item;
    }

    onShow() {
        this.resetModal();

        this.$nextTick(() => {
            this.fetchPaNotes();
            this.loadPatientDocuments();
        });
    }

    get status(): string {
        if (!this.value) return "";
        return PriorAuthorizationStatusCode[this.value.status];
    }
    get createDate(): string {
        if (!this.value) return "";
        return moment.utc(this.value.created).format("L");
    }
    get updateDate(): string {
        if (!this.value) return "";
        return moment.utc(this.value.updated).format("L");
    }

    get noteFields(): any[] {
        return [
            { key: "created", label: "", formatter: (val: any) => moment.utc(val).local().format("L LT") },
        ];
    }

    get patientName(): string {
        if (!this.value?.patient) return '';

        return this.value.patient.displayNamesForPerson();
    }

    resetModal() {
        this.cmmResponse = {} as CoverMyMedsResponse;
        this.notes = [];
        this.newNoteBody = "";
    }

    fetchPaNotes() {
        if (!this.value.id) return;
        Axios.get(`PriorAuthorization/${this.value.id}/Note`)
            .then(response => {
                this.notes = response.data;
            })
            .catch(err => {
                console.warn(err);
            });
    }

    savePriorAuthorization(closeModal: boolean = true) {
        const data = this.value;
        Axios.post(`PriorAuthorization/${this.value.id}/Save`, data)
            .then(response => {
                this.$notification(NotificationOptions.successSaveNotificationPreset("Prior Authorization"));
                if (closeModal) this.close();
            })
            .catch(err => {
                console.error('Error while saving PA', { err, response: err?.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Prior Authorization"));
            });
    }

    createPA() {
        const prescription = this.value.prescription;
        if (!prescription) return;
        //?forcePA=true
        Axios.post<PriorAuthorization>(`/PriorAuthorization/Create?paId=${this.value.id}`, prescription)
            .then(response => {
                console.log("createPa response.data: ", response.data);
                if (response.data.responses.length > 0) {
                    const resp = response.data.responses[0];
                    this.cmmResponse = resp;
                } else {
                    this.$notification(NotificationOptions.notificationOptionsPreset("No responses returned.", NotificationOptions.NotificationTypes.warning));
                }
            })
            .catch(err => {
                this.$notification(NotificationOptions.notificationOptionsPreset("Error attempting to create PA.", NotificationOptions.NotificationTypes.danger));
                console.error('Error while creating PA', { err, response: err?.response });
            });
    }

    get displayValue(): string {
        const prescription = Object.assign(new Prescription(), this.value.prescription);
        if (!prescription) return '';

        return `${prescription.rxID} ${prescription.productNameShort} - ${this.formatDate(prescription.writtenDate)}`
    }

    formatDate(d: Date): string {
        if (!d) return "";
        return moment.utc(d).local().format("L");
    }

    askCompleteReason() {
        this.$bvModal.show("complete-reason-modal");
    }

    completeHide() {
        if (this.value.completeReason?.length > 0) {
            this.savePriorAuthorization();
        } else {
            console.warn("No complete reason given, not saving.");
            this.$notification(NotificationOptions.notificationOptionsPreset("No complete reason given, not saving.", NotificationOptions.NotificationTypes.warning));
        }
    };

    // button calls
    coverMyMedsClick() {
        const url = `https://covermymeds.com/request/view/${this.value.coverMyMedsId}`;
        window.open(url, "_blank");
    }

    templateClick(val: string) {
        const selectedTemplate = this.templates.find(t => t.value == val);
        this.newNoteBody = selectedTemplate.text;
    }

    cmmOk() {
        this.savePriorAuthorization(false);
        this.$bvModal.hide('cmm-id-add');
    }

    saveNoteClick() {
        let data = { Body: this.newNoteBody };
        Axios.post(`PriorAuthorization/${this.value.id}/Note`, data)
            .then(response => {
                console.log("postNote: ", response.data);
                this.notes.push(response.data)
                this.newNoteBody = "";
            })
            .catch(err => {
                console.warn(err);
            });
    }

    submittedClick() {
        this.value.status = PriorAuthorizationStatusCode.SubmittedToInsurance;
        this.savePriorAuthorization();
    }
    sentToPrescriberClick() {
        this.value.status = PriorAuthorizationStatusCode.SentToPrescriber;
        this.savePriorAuthorization();
    }

    approvedClick() {
        this.value.status = PriorAuthorizationStatusCode.Approved;
        this.savePriorAuthorization();
    }

    deniedClick() {
        this.value.status = PriorAuthorizationStatusCode.Denied;
        this.askCompleteReason();
    }
    abandonedClick() {
        this.value.status = PriorAuthorizationStatusCode.Abandoned;
        this.askCompleteReason();
    }
    cancelledClick() {
        this.value.status = PriorAuthorizationStatusCode.Cancelled;
        this.askCompleteReason();
    }

    close() {
        this.$bvModal.hide(this.id);
    }

    loadPatientDocuments() {
        if (!this.value?.patient?.id) return;

        this.$http.get<Array<PatientDocument>>(`/patient/${this.value?.patient?.id}/get-documents/`)
            .then(res => {
                if (res.data?.length) this.calculateLastDocumentDate(res.data);
            })
            .catch(err => {
                console.error("Error while loading patient documents.", { err, response: err.response });
            })
    }

    private lastDocumentDate: Date | null = null;

    calculateLastDocumentDate(docs: Array<PatientDocument>) {
        if (!docs?.length) return null;

        const res = docs.map(d => d.created).sort((d1: Date, d2: Date) => {
            return new Date(d2).getTime() - new Date(d1).getTime();
        })[0];

        this.lastDocumentDate = res;
    }

    get prescriptionDetailsRoute() {
        return {
            name: 'PrescriptionDetails'
            , params: {
                storeID: this.value.prescription.storeID,
                rxNumber: this.value.prescription.rxNumber,
                rfNumber: (this.value.prescription.rfNumber || 0)
            }
        };
    }
}
</script>

<style scoped>
.note-body {
    word-break: break-word;
}
</style>
