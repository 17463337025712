<template>
    <div>
        <card :title="hideTitle ? '' : 'Pharmacy Record Page'">
            <SearchComponent
                v-if="!newMode"
                :disabled="!!(pharmacyDetail.id)"
                v-model="pharmacyDetail"
                :search-u-r-l="`/StoredPharmacy`"
                :params="{ showOnlyActive: false }"
                :id="pharmacyDetail && pharmacyDetail.id"
                @input="onPharmacyChange"></SearchComponent>
            <b-row>
                <b-col>
                    <checkbox class="form-check"
                        v-model="pharmacyDetail.isActive" :disabled="disableFields" required>
                        Is Pharmacy active?
                    </checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <fg-input v-model="pharmacyDetail.name"
                              label="Pharmacy Name" :disabled="disableFields" required>
                    </fg-input>
                </b-col>
                <b-col>
                    <fg-input v-model="pharmacyDetail.address"
                              label="Address" :disabled="disableFields">
                    </fg-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <fg-input v-model="pharmacyDetail.addressCity"
                              label="City" :disabled="disableFields">
                    </fg-input>
                </b-col>
                <b-col>
                    <fg-input v-model="pharmacyDetail.addressState"
                              label="State" :disabled="disableFields">
                    </fg-input>
                </b-col>
                <b-col>
                    <fg-input v-model="pharmacyDetail.addressZip"
                              label="ZIP" type="number" :disabled="disableFields">
                    </fg-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <fg-input v-model="pharmacyDetail.phoneNumber"
                              label="Phone" :disabled="disableFields" required>
                    </fg-input>
                </b-col>
                <b-col>
                    <fg-input v-model="pharmacyDetail.faxNumber"
                              label="Fax" :disabled="disableFields" required>
                    </fg-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <fg-input
                        v-model="pharmacyDetail.dea"
                        label="DEA"
                        :disabled="disableFields"
                        required>
                    </fg-input>
                </b-col>
            </b-row>

            <NewClearSaveButtons
                :titles="buttonTitles"
                :disable="disableFields"
                :disableClear="disableFields"
                :disableSave="!isFormValid"
                @clearClicked="clearForm"
                @newClicked="createStoredPharma"
                @saveClicked="saveStoredPharmacy"
                @cancel-clicked="$emit('close')">
            </NewClearSaveButtons>


            <!--
                    TABLE OF PHARMACISTS
                    FROM StoredPharmacist
                    WHERE ID = CURRENT PHARMACY
            -->
            <b-row v-if="pharmacyDetail && pharmacyDetail.id">
                <b-col>
                    <h3> Stored Pharmacist: </h3>
                    <b-table id="stored-pharmacist-table"
                             :items="pharmacists"
                             :fields="pharmacistsFields"
                             empty-text="No Pharmacists"
                             show-empty
                             striped
                             bordered>
                        <template #cell(isActive)="row">
                            <b-checkbox v-model="row.item.isActive" @change="updateActive(row.item)"></b-checkbox>
                        </template>
                    </b-table>

                    <b-button variant="success"
                              @click="newStoredPharmacist"
                              :disabled="!pharmacyDetail || !pharmacyDetail.id">Create New Pharmacist
                    </b-button>
                </b-col>
            </b-row>
        </card>

        <b-modal
            id="addNewPharmacist"
            hide-footer
            size="xl"
            no-close-on-backdrop
            title="Create New Pharmacist"
        >
            <PharmacistDetailPage
                :disabled="!pharmacyDetail || !pharmacyDetail.id"
                :pharmacy-id="pharmacyDetail && +pharmacyDetail.id"
                new-mode
                @saveClicked="saveStoredPharmacist"
                @close="$bvModal.hide('addNewPharmacist')"
                @created="onPharmacistCreate"
            ></PharmacistDetailPage>
        </b-modal>
    </div>
</template>


<script lang="ts">
import axios from 'axios';
import {Component, Prop, Vue} from 'vue-property-decorator';
import SearchComponent from '@/components/SearchComponent.vue';
import NewClearSaveButtons, {NewClearSaveTitles} from '@/components/NewClearSaveButtons.vue';
import {PharmacyRecord} from "@/models/Pharmacy"
import {PharmacistRecord} from "@/models/Pharmacist";
import {NotificationOptions} from "@/util/NotificationOptionsPresets";
import Checkbox from "@/components/Inputs/Checkbox.vue";
import PharmacistDetailPage from "@/pages/Pharmacist/PharmacistDetailPage.vue";
import NotificationTypes = NotificationOptions.NotificationTypes;

@Component({
    name: 'PharmacyDetailPage',
    components: {
        PharmacistDetailPage,
        SearchComponent,
        NewClearSaveButtons,
        Checkbox
    }
})
export default class PharmacyDetailPage extends Vue {

    protected pharmacists: PharmacistRecord[] = [];
    private pharmacyDetail: PharmacyRecord = new PharmacyRecord();
    private pharmacistDetail: PharmacistRecord = new PharmacistRecord();

    private pharmacistsFields = [
        "id",
        "firstName",
        "lastName",
        "isActive",
    ];

    @Prop({type: Number, default: 0}) private pharmacyId!: number;
    @Prop({type: Boolean, default: false}) private newMode!: boolean;
    @Prop({ default: false}) private hideTitle!: boolean;
    @Prop({ default: false}) private defaultToActive!: boolean;

    created() {
        this.pharmacyDetail.isActive = this.defaultToActive;
        if (this.pharmacyId) {
            this.pharmacyDetail.id = this.pharmacyId;
            this.onPharmacyChange(this.pharmacyDetail);
        }
    }

    onPharmacyChange(_value: PharmacyRecord) {

        if (this.pharmacyDetail.id) {

            axios.get<PharmacyRecord>(`/StoredPharmacy/${this.pharmacyDetail.id}`)
                .then(pbmResponse => {
                    this.pharmacyDetail = new PharmacyRecord(pbmResponse.data);

                });

            //  LOAD ALL THE PHARMACISTS FOR A GIVEN PHARMACY AFTER IT HAS BEEN SELECTED
            this.loadPharmacists();
        }
        this.loadPharmacists();
    }

    //  THIS GETS ALL OF THE STORED PHARMACISTS ASSOCIATED WITH A SPECIFIC PHARMACY
    async loadPharmacists() {
        axios.get<PharmacistRecord[]>(`/StoredPharmacist/GetAllPharmacistsByStore/${this.pharmacyDetail.id}`).then((response) => {
            this.pharmacists = response.data;
        }).catch((error) => {
            console.error("Problem loading Pharmacists by Pharmacy", {error, response: error?.response});
        });
    }

    get disableFields(): boolean {
        return (this.pharmacyDetail?.id || 0) == 0 && !this.newMode;
    }

    get isFormValid() {
        return this.pharmacyDetail.name && this.pharmacyDetail.faxNumber && this.pharmacyDetail.phoneNumber && this.pharmacyDetail.dea
    }

    @Prop({
        default: () => {
            return {
                new: "New Pharmacy",
                clear: "Clear",
                save: "Save Changes",
                cancel: "Cancel",
            };
        }
    }) protected buttonTitles!: NewClearSaveTitles;

    createStoredPharma() {
        this.newMode = true;
        this.pharmacyDetail = new PharmacyRecord();
    }

    clearForm() {
        this.newMode = false;
        this.pharmacyDetail = new PharmacyRecord();
    }

    saveStoredPharmacy() {
        if (this.pharmacyDetail?.id as number) this.pharmacyDetail.id = this.pharmacyDetail.id as number;
        else this.pharmacyDetail.id = null as unknown as number;
        axios.post<{pharmacy:PharmacyRecord, warning: string}>('/StoredPharmacy', this.pharmacyDetail)
            .then(response => {
                let pharm = response.data.pharmacy;
                let warning = response.data.warning;
                if (pharm.id) {
                    this.pharmacyDetail = new PharmacyRecord(pharm);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Pharmacy"));
                    this.$emit('created', this.pharmacyDetail);
                }
                if (warning) {
                    this.$notification(NotificationOptions.notificationOptionsPreset(warning, NotificationTypes.warning));
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Pharmacy", error));
            });
    }

    saveStoredPharmacist() {
        this.pharmacistDetail.pharmacyId = this.pharmacyId;
        if (this.pharmacistDetail?.id as number) this.pharmacistDetail.id = this.pharmacistDetail.id as number;
        else this.pharmacistDetail.id = null as unknown as number;

        axios.post<PharmacistRecord>('/SavePharmacist', this.pharmacistDetail)
            .then(response => {

                if (response.data.id) {
                    this.loadPharmacists();
                    this.pharmacistDetail = Object.assign(new PharmacistRecord(), response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Pharmacist"));
                    this.$emit('created', this.pharmacistDetail);
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Pharmacist", error));
            });
    }

    onPharmacistCreate(newPharmacist: PharmacistRecord) {
        this.pharmacistDetail = newPharmacist;
        this.$bvModal.hide("addNewPharmacist");
    }

    updateActive(pharmacist: PharmacistRecord) {
        console.log("pharmacist: ", pharmacist);
        axios.post<PharmacistRecord>('/StoredPharmacist', pharmacist)
            .then(response => {

                if (response.data.id) {
                    this.loadPharmacists();
                    this.pharmacistDetail = Object.assign(new PharmacistRecord(), response.data);
                    this.$notification(NotificationOptions.successSaveNotificationPreset("Pharmacist"));
                    this.$emit('created', this.pharmacistDetail);
                }
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Pharmacist", error));
            });
    }

    newStoredPharmacist() {
        this.pharmacistDetail = new PharmacistRecord();
        this.pharmacistDetail.isActive = true;
        this.$bvModal.show("addNewPharmacist");
    }


}
</script>

<style scoped>
.form-check {
    margin-top: 0;
    padding-left: 0;
}
</style>
