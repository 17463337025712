
.card {
    box-shadow: none !important;
    margin-bottom: 8px;
}

::v-deep(.card-body) {
    padding: 10px !important;
}

::v-deep(.card-body .d-flex) {
    gap: 8px;
}

.handle {
    cursor: grab;
}

.plan-name {
    width: 50%;
}

.plan-details {
    width: 50%;
}

.move-top {
    cursor: pointer;
    transition: all .3s;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
    position: absolute;
}

::v-deep(.relationship-code legend) {
    padding-bottom: 0;
}
