<template>
    <div :class="{ small: small }">
        <div class="filter-grid mb-3">
            <b-form-group v-if="!small" label="Type:">
                <b-form-select :options="problems" v-model="problemFilter" />
            </b-form-group>
            <DateRangeFilter v-model="createdDateRangeFilter" label="Created Date:" />
            <b-form-group v-if="!small" label="Filter:">
                <b-form-input placeholder="Patient Name / DOB" v-model="query" />
            </b-form-group>
        </div>
        <b-row>
            <b-col>
                <b-table id="case-table" ref="caseTable" show-empty sort-icon-left no-provider-sorting
                    empty-text="No Cases Found" empty-filtered-text="No Cases Found" :fields="tableFields"
                    :items="filteredItems" :per-page="pageSize" :current-page="pageNumber" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :tbody-tr-class="colorCodeTable" selectable select-mode="single"
                    @row-selected="selectedRow">
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle" />
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(details)="row">
                        <b-button variant="success" @click="selectCase(row.item)" class="text-right"
                            :size="small ? 'sm' : undefined">
                            <i class="fas fa-eye mr-2"></i>
                            <template v-if="!small">Details</template>
                        </b-button>
                    </template>
                    <template #cell(lastName)="data">
                        <router-link :to="{ name: 'PatientDashboard', params: { id: data.item.patientId } }">
                            {{ data.value }}
                        </router-link>
                    </template>
                </b-table>

                <BottomPagerBar v-if="totalRows > 0" v-model="pageNumber" :total-rows="totalRows" :per-page="pageSize" show-total />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <CaseDetailComponent :caseSelected="caseSelected" :show="show" :editable="!readOnly"
                    @updateCase="updateCaseList" @hidden="modalClosed" />
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
import { CaseDetail, types as CaseTypes } from '@/models/Case';
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { Table, TableColumn } from 'element-ui';
import axios from 'axios';
import CaseDetailComponent from '@/components/Cases/CaseDetailComponent.vue';
import { BvTableFieldArray, BTable } from "bootstrap-vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { DatePicker } from "element-ui";
import moment from 'moment';
import DateRangeFilter from "@/components/DateRangeFilter.vue";
import BottomPagerBar from '../BottomPagerBar.vue';

@Component({
    name: "CasesListComponent",
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        CaseDetailComponent,
        [DatePicker.name]: DatePicker,
        DateRangeFilter,
        BottomPagerBar
    }
})
export default class CasesListComponent extends Vue {

    @Prop() private show!: string;
    @Prop({ type: Boolean, default: false }) private small!: boolean;
    @Prop({ type: Boolean, default: false }) private hidePatient!: boolean;
    @Prop({ type: Boolean, default: false }) private showStatus!: boolean;
    @Prop({ type: Boolean, default: false }) private readOnly!: boolean;
    @Prop({ default: 50 }) private pageSize!: number;
    @Prop() private defaultItems?: Array<CaseDetail>;

    @Ref("caseTable") private caseTable!: BTable;

    private caseSelected: CaseDetail | any = {};

    protected createdDateRangeFilter: { startDate: any, endDate: any } = { startDate: null, endDate: null };

    private pageNumber = 1;

    private items: Array<CaseDetail> = [];

    private sortBy: string = 'created';
    private sortDesc: boolean = true;

    private problemFilter: string | null = null;
    private query: string | null = null;

    get problems() {
        return [
            { text: "", value: null },
            ...CaseTypes
        ]
    }

    get startRowIndex() {
        return this.pageNumber * this.pageSize - this.pageSize;
    }

    get endRowIndex() {
        const calcIndex = this.startRowIndex + this.pageSize;
        if (calcIndex > this.filteredItems.length) {
            return this.filteredItems.length;
        }
        return calcIndex;
    }

    get totalRows() {
        return this.items.length;
    }

    created() {
        this.getCases();
    }

    get tableFields(): BvTableFieldArray {
        return [
            {
                key: "id", label: this.small ? "Id" : "Case Id"
            },
            {
                key: "lastName",
                label: "Patient Name",
                formatter: (v: any, k: any, i: any) => i.lastName?.length < 1 ? `` : `${i.lastName}, ${i.firstName}`,
                sortable: true,
                visible: !this.hidePatient
            },
            {
                key: "dateOfBirth",
                formatter: (v: any) => new Date(v).toLocaleDateString(),
                sortable: true,
                visible: !this.hidePatient
            },
            { key: "problem", label: "Type", sortable: true },
            {
                key: "status",
                label: "Status",
                sortable: true,
                formatter: (v: any, k: any, i: any) => i?.accepted != null ? "Closed" : i?.resolved != null ? "Resolved" : "Opened",
                visible: this.showStatus,
            },
            { key: "created", formatter: (v: any) => v ? moment(v).format("l LT") : "", sortable: true, visible: !this.small },
            { key: "resolved", formatter: (v: any) => v ? moment(v).format("l LT") : "", sortable: true, visible: !this.small },
            {
                key: "accepted",
                label: "Closed",
                formatter: (v: any) => v ? moment(v).format("l LT") : "",
                sortable: true, visible: !this.small
            },
            {
                key: "latest",
                label: "Status Date",
                formatter: (_: any, __: any, i: any) => {
                    if (i.accepted) return moment(i.accepted).format("l LT");
                    if (i.resolved) return moment(i.resolved).format("l LT");
                    return moment(i.created).format("l LT");
                },
                visible: !!this.small
            },
            { key: "details", label: "", visible: !this.small, class: "details" },
        ].filter(i => i.visible !== false);
    }

    get filteredItems() {
        let items = this.items;

        if (this.problemFilter) {
            items = items.filter(i => i.problem === this.problemFilter);
        }

        if (this.createdDateRangeFilter?.startDate) {
            items = items.filter(i => new Date(i.created) >= moment(this.createdDateRangeFilter!.startDate).toDate() &&
                new Date(i.created) < moment(this.createdDateRangeFilter!.endDate).add(1, "day").toDate());
        }

        if (this.query) {
            const queryTerms = this.query.toLowerCase().split(" ");
            items = items.filter(i => queryTerms.every(qt => i.lastName.toLowerCase().includes(qt) ||
                i.firstName.toLowerCase().includes(qt) ||
                new Date(i.dateOfBirth).toLocaleDateString().includes(qt)));
        }

        return items;
    }

    async updateCaseList() {
        this.$emit("update");
        await this.getCases();
    }

    @Watch("defaultItems")
    async getCases() {
        this.caseSelected = {};

        this.items = this.defaultItems ?? [];

        if (!this.show) return;

        const uri = `/api/Case/${this.show}`;

        try {
            const resp = await axios.get<CaseDetail[]>(uri);
            this.items = resp.data;
            return resp.data.slice(this.startRowIndex, this.endRowIndex);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
            return [];
        }
    }

    selectCase(item: CaseDetail) {
        this.$bvModal.show('case-detail');
        this.caseSelected = item;
    }

    colorCodeTable(c: CaseDetail) {
        if (!this.showStatus) return "";
        if (c?.accepted == null) return "table-warning";
        return "";
    }

    selectedRow(items: Array<any>) {
        if (!items.length) return;
        this.selectCase(items[0]);
    }

    modalClosed() {
        this.caseTable.clearSelected();
        this.caseSelected = null;
    }
}
</script>

<style scoped>
.filter-grid {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 430px), 1fr));
}

.small ::v-deep(table) {
    font-size: .98em;
}

.small .filter-grid {
    grid-template-columns: 1fr;
}

::v-deep(.details) {
    width: 135px;
}

@media (min-width: 1600px) {
    .filter-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
