<template>
    <div>
        <!-- TOPICAL DRUG ALERT -->
        <b-alert v-model="invalidROA" variant="danger">
            <b>RX is not valid for autorefill. </b>
            <span>The route of administration for {{ drug.productNameShort }} is topical.</span>
        </b-alert>
        <b-row v-if="isLoading">
            <b-spinner label="Spinning" class="mr-2"></b-spinner>
            <strong>Loading...</strong>
        </b-row>
        <b-row v-else>
            <b-col>
                <b>Store: </b><span>{{ storeName }}</span><br />
                <b>Rx Number: </b><span>{{ rxNumber }}</span><br />
                <b>Drug:</b> {{ drug.ndc }} - {{ drug.productNameShort }}<br />
                <span v-if="mode != 'create'">
                    <b>Subscribed on: </b><span>{{ sub.created | formatDate('MM/DD/yyyy h:mm a') }}</span><br />
                    <b>Subscribed by: </b><span>{{ createdBy }}</span>
                </span>
            </b-col>
            <b-col>
                <div v-if="showSignature">
                    <small v-html="createPromptText"></small>
                    <img v-if="showSignature && !sub.signatureData.startsWith('<')" :src="sub.signatureData" />
                    <div v-if="showSignature && sub.signatureData.startsWith('<')" v-html="sub.signatureData"
                        class="signature-border" />
                </div>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col>
                <fg-input label="Next Process" required>
                    <el-date-picker v-model="sub.nextProcessDate" type="date" :disabled="isDisabled" format="MM/dd/yyyy"
                        :picker-options="pickerOptions"></el-date-picker>
                </fg-input>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col>
                <span>Select the card that will be charged for this refill subscription:</span>
                <StripeVueCard :patient="patient" :disabled="isDisabled" @cardAdded="cardAdded"></StripeVueCard>
                <CardsTable ref="cardsTable" v-model="patient.stripeCustomerId" :selectedCardID="sub.cardId"
                    :disabled="!!sub.cancellationDate || isDisabled" @cardSelected="setCardID"></CardsTable>
            </b-col>
        </b-row>
        <b-row v-if="!loadingPatient && mode == 'create'">
            <b-col v-if="patientHasNoPhone">
                <b-alert :show="true" variant="warning" class="text-center">
                    <strong>This patient has no phone numbers and cannot be enrolled in auto refills.</strong>
                </b-alert>
            </b-col>
            <b-col v-else-if="patientIsTextOptOut">
                <b-alert :show="true" variant="warning" class="text-center">
                    <strong>This patient is opted out of text messages and cannot be enrolled in auto refills.</strong>
                </b-alert>
            </b-col>
            <template v-else>
                <b-col cols="6" class="offset-3">
                    <strong>Read the following statement to the patient:</strong>
                    <div v-html="createPromptText"></div>
                </b-col>
                <b-col cols="12">
                    <b-row>
                        <b-col cols="2" class="offset-4 text-center">
                            <input type="radio" value="true" v-model="patientConsent">
                            <label for="one">Yes</label>
                        </b-col>
                        <b-col cols="2" class=text-center>
                            <input type="radio" value="false" v-model="patientConsent">
                            <label for="one">No</label>
                        </b-col>
                    </b-row>
                </b-col>
            </template>
        </b-row>
        <b-row v-if="!isSaving">
            <b-col cols="6" v-if="mode == 'edit' && !sub.cancellationDate">
                <b-button variant="danger" @click="confirmDialog('cancel')">Cancel Sub</b-button>
            </b-col>
            <b-col cols="6" v-if="!(mode == 'edit' && !sub.cancellationDate)">
            </b-col>
            <b-col cols="6" class="text-right" v-if="!patientIsTextOptOut && !patientHasNoPhone">
                <b-button variant="info" :disabled="isDisabled || !isFormValid" @click="save">Save</b-button>
            </b-col>
        </b-row>
        <div v-else class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Saving...</strong>
        </div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
import moment from 'moment';
import { Component, Prop } from 'vue-property-decorator';
import { DatePicker } from 'element-ui';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';

import { AutoRefillSubscription } from '@/models/AutoRefillSubscription';
import { Prescription } from '@/models/Prescription';
import { Patient } from '@/models/Patient';
import { StripeCard } from '@/models/Payment';
import { Drug } from '@/models/Drug/Drug';

import SearchComponent from '@/components/SearchComponent.vue';
import CardsTable from '@/components/Stripe/CardsTable.vue'
import StripeVueCard from '@/components/Payments/StripeVueCardcomponent.vue';
import { PhoneOptStatus } from '@/models/OptStatus';

@Component({
    name: "RXSubscriptionForm",
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
        CardsTable, StripeVueCard
    },
})
export default class RXSubscriptionForm extends Vue {
    @Prop({ default: () => new Prescription() }) private rx!: Prescription;
    @Prop({ default: () => new AutoRefillSubscription() }) sub!: AutoRefillSubscription;

    private isSaving = false;
    private patient = new Patient();
    private drug = new Drug();
    private _rx: Prescription | null = null;
    private isLoading = false;
    private patientConsent = false;
    private loadingPatient = false;

    protected createPromptText = "";

    created() {
        this.isLoading = true;
        if (!this.rx.rxID) {
            const rxID = `${this.sub.StoreRx}-0`;
            this.$http.get<Prescription>(`/Prescription/${rxID}`)
                .then(res => {
                    this._rx = new Prescription(res.data);
                    if (this._rx.rxID) {
                        this.fetchDrug();
                    }
                })
                .catch(err => console.error('Error getting Rx Details.', { err, response: err?.response }))
        } else {
            this.fetchDrug();
        }
        this.getAutoRefillPromptStatementFromConfig();
    }

    fetchDrug() {
        if (!this.prescription?.packageID) return;

        this.$http.get<Drug>(`drug/${this.prescription.packageID}`)
            .then(res => {
                if (res.data?.packageID) this.drug = new Drug(undefined, res.data);
            })
            .catch(err => console.error('Error getting drug details.', { err, response: err?.response }))
            .finally(() => this.isLoading = false)
    }

    get invalidROA(): boolean {
        return this.drug?.routeOfAdministration == 'Topical' && this.mode == 'create';
    }

    get isDisabled(): boolean {
        //return this.isSaving || !!this.sub?.cancellationDate || !this.sub?.cardId;
        return this.isSaving || !!this.sub?.cancellationDate || this.invalidROA || this.isLoading;
    }

    get isFormValid(): boolean {
        return !!(this.sub.nextProcessDate && (this.sub.cardId || this.mode == 'edit' || (this.prescription?.patientPay ?? -1) == 0) && (this.mode != 'create' || this.patientConsent));
    }

    get mode(): string {
        if (this.sub?.id) return 'edit'
        else return 'create';
    }

    get storeName(): string {
        return this.rx?.storeName || this.sub?.storeName;
    }

    get rxNumber(): number {
        return this.rx?.rxNumber || this.sub?.rxNumber;
    }

    get patientID(): number {
        return this.rx?.patientID || this.sub?.patientId;
    }

    get prescription(): Prescription | null {
        return this.rx.hasFullId ? this.rx : this._rx;
    }

    get showSignature(): boolean {
        return this.sub.createdBy == 'PatientWeb' || this.sub.createdBy?.split('-').length == 5;
    }

    get createdBy(): String {
        return this.sub.createdBy?.split('-').length == 5 ? 'Patient App' : this.sub.createdBy;
    }

    processDateValidator(date: Date) {
        return AutoRefillSubscription.isProcessDateInvalid(date, this.rx.expirationDate);
    }
    private pickerOptions = {
        disabledDate: this.processDateValidator
    }

    setCardID(cardID: string) {
        this.sub.cardId = cardID;
    }

    mounted() {
        if (this.mode == 'create') {
            this.sub.setPrescription(this.rx);
        }

        this.loadPatient();
    }

    getAutoRefillPromptStatementFromConfig() {
        let url = '/Configuration/AutoRefillPromptStatement';
        if (this.patient.languageName != 'English') url = `${url}${this.patient.languageName}`;
        this.$http.get<string>(url)
            .then(fetchResponse => {
                this.createPromptText = fetchResponse.data;
            });
    }

    save() {
        this.isSaving = true;
        this.sub.nextProcessDate = moment(this.sub.nextProcessDate).startOf('day').toDate();
        this.$http.post<AutoRefillSubscription>(`/autorefill/subscribe-autorefill`, this.sub)
            .then(res => {
                if (((res.data?.id ?? 0) as number) >= 0) {
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset("Prescription successfully subscribed to auto-refill."
                            , NotificationOptions.NotificationTypes.success
                        ))
                    this.$emit('saved');
                }
            })
            .catch(err => {
                let errorMsg = "Error while subscribing prescription to auto-refill.";
                this.$notification(NotificationOptions.errorSaveNotificationPreset(errorMsg))
                console.error(errorMsg, { err, response: err?.response })
            })
            .finally(() => {
                this.isSaving = false;
            })
    }

    confirmDialog(type: string) {
        this.$bvModal.msgBoxConfirm(`Are you sure you want to cancel this subscription to auto-refill?`, {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
        })
            .then(value => {
                if (!value) return;
                this.cancel();
            })
    }

    cancel() {
        if (!this.sub?.id) return;

        this.isSaving = true;
        this.$http.delete<AutoRefillSubscription>(`/autorefill/cancel-autorefill/${this.sub.id}`)
            .then(res => {
                if (((res.data?.id ?? 0) as number) >= 0) {
                    this.$notification(
                        NotificationOptions.notificationOptionsPreset("Subscription successfully canceled."
                            , NotificationOptions.NotificationTypes.success
                        ))
                    this.$emit('saved');
                }
            })
            .catch(err => {
                let errorMsg = "Error while cancelling subscribition.";
                this.$notification(NotificationOptions.errorSaveNotificationPreset(errorMsg))
                console.error(errorMsg, { err, response: err?.response })
            })
            .finally(() => {
                this.isSaving = false;
            })
    }

    async loadPatient() {
        try {
            this.loadingPatient = true;
            const res = await this.$http.get<Patient>(`/Patient/${this.patientID}`);
            if (!res.data?.id) return;
            this.patient = new Patient(res.data);
        } catch {
            // ignore
        } finally {
            this.loadingPatient = false;
            this.getAutoRefillPromptStatementFromConfig();
        }
    }

    reloadCards() {
        const cardsTable = this.$refs.cardsTable as CardsTable;
        if (cardsTable) cardsTable.loadPatientPaymentMethods();
    }

    cardAdded(card: StripeCard) {
        this.patient.stripeCustomerId = card.customerId;
        this.reloadCards();
    }

    get patientIsTextOptOut() {
        const phone = this.patient?.phone;
        const cell = this.patient?.cell;

        if (!phone && !cell) return false;
        if (phone && this.patient?.optInOptOutPhoneStatus !== PhoneOptStatus.optOut) return false;
        if (cell && this.patient?.optInOptOutCellPhoneStatus !== PhoneOptStatus.optOut) return false;

        return true;
    }

    get patientHasNoPhone() {
        return !this.patient?.phone && !this.patient?.cell;
    }
}
</script>
<style scoped>
.signature-border {
    border: thin solid black;
}
</style>
