<template>
    <card>

        <template #header>
          <div class="d-flex align-items-center justify-content-between">
            <h1 class="card-title">Doctor Follow-Ups</h1>

            <div>
              <b-form-group>
                <b-form-radio-group v-model="selectedMode" :options="['Queue','All']" button-variant="primary"
                  buttons />
              </b-form-group>
            </div>
          </div>
        </template>

        <div class="filter-grid mb-3">
            <div>
                <SearchComponent :key="selectedDoctor?.id ?? 0" v-model="selectedDoctor" :label="`Doctor`"
                    :search-u-r-l="`/Prescriber`" :clearable="true" />
            </div>

            <EnumSelector v-model="selectedFaxType" label="Request Type" :enum-codes="faxTypeEnum"
                :elementsToDiscard="[faxTypeEnum.RxExternalTransfer]" :clearable="true" />

            <div>
                <label>Created Date:</label>
                <div class="d-flex" style="gap: 15px;align-items: center;margin-top: -7px;">
                    <el-date-picker size="sm" v-model="startDate" today-button reset-button :disabled="isLoading" />
                    <el-date-picker size="sm" v-model="endDate" today-button reset-button :disabled="isLoading" />

                    <b-dropdown size="sm" right>
                        <b-dropdown-item @click="createdToday">Today</b-dropdown-item>
                        <b-dropdown-item @click="createdYesterday">Yesterday</b-dropdown-item>
                        <b-dropdown-item @click="createdThisWeek">This Week</b-dropdown-item>
                        <b-dropdown-item @click="createdLastWeek">Last Week</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="startDate = ''; endDate = '';">Clear</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <div v-if="isLoading">
            <b-spinner label="Spinning" class="mr-2"></b-spinner>
            <strong>Loading...</strong>
        </div>
        <template v-else>

            <b-table striped :items="filteredItems" :fields="fields" :busy="isLoading" :sort-by.sync="sortBy" show-empty
                empty-text="No results." :per-page="pageSize" :current-page="pageNumber" @sort-changed="pageNumber = 1" bordered>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template #cell(created)="data">
                    <span v-b-tooltip.hover :title='`Created by ${data.item.createdBy}`'>
                        {{ data.item.created | formatDate('lll') }}
                    </span>
                </template>
                <template #cell(requestName)="data">
                    <div class="d-flex justify-content-between align-items-center">
                        <span v-b-tooltip.hover :title="!!data.item.rxID ? `RxID: ${data.item.rxID}` : ''">
                            {{ data.item.requestName }}
                        </span>

                        <b-link @click="filterByType(data.item.type)" class="ml-2">
                            <b-icon icon="search" />
                        </b-link>
                    </div>
                </template>
                <template #cell(updated)="data">
                    <span v-b-tooltip.hover :title='`Updated by ${data.item.updatedBy}`'>
                        {{ data.item.updated | formatDate('lll') }}
                    </span>
                </template>
                <template #cell(actions)="data">
                    <a href="#" @click.prevent="openDetails(data.item)">
                        <b-icon icon="pencil-square" v-b-tooltip.hover title="Details"></b-icon>
                    </a>
                </template>
                <template #cell(prescriberName)="data">
                    <div class="d-flex justify-content-between align-items-center">
                        {{ data.item.prescriberName }}
                        <b-link @click="filterByPrescriber(data.item.prescriberId)" class="ml-2">
                            <b-icon icon="search" />
                        </b-link>
                    </div>
                </template>
            </b-table>

            <BottomPagerBar v-model="pageNumber" :total-rows="totalRows" :per-page="pageSize" show-total />

        </template>

        <!-- Follow-up case details -->
        <b-modal :id="caseModalId" title="Doctor Follow-Up Case" size="xl" hide-footer>

            <!-- FOLLOW UP COMPONENT -->
            <DoctorFollowUpCaseForm v-model="selectedCase" @submitted="closeCaseModalAndReload" />

        </b-modal>
    </card>
</template>
<script lang="ts">
import Vue from 'vue'
import { GroupOfCasesByDoctor, PrescriberFollowUpCase, FaxType } from '@/models/PrescriberFollowUpCase'
import { Prescriber } from '@/models/Prescriber';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { NotificationOptions } from '@/util/NotificationOptionsPresets';
import DoctorFollowUpCaseForm from '@/components/Doctor/DoctorFollowUpCaseForm.vue'
import SearchComponent from '@/components/SearchComponent.vue';
import EnumSelector from '@/components/EnumSelector.vue';
import { DatePicker } from "element-ui";
import moment from 'moment';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import BottomPagerBar from '@/components/BottomPagerBar.vue';

@Component({
    name: "DoctorFollowUpQueue",
    components: {
        DoctorFollowUpCaseForm, SearchComponent,
        EnumSelector, [DatePicker.name]: DatePicker, Checkbox,
        BottomPagerBar
    },
})
export default class DoctorFollowUpQueue extends Vue {
    private caseModalId = 'followup-case-form';
    private isLoading = false;
    private items: Array<PrescriberFollowUpCase> = new Array<PrescriberFollowUpCase>();
    private selectedCase: PrescriberFollowUpCase = new PrescriberFollowUpCase();
    private selectedDoctor: Prescriber = new Prescriber();
    private faxTypeEnum = FaxType;
    private selectedFaxType: FaxType | null = null;
    protected startDate?: string = "";
    protected endDate?: string = "";
    protected selectedMode: string = "Queue";

    private fields: any[] = [
        { key: "patientName", label: "Patient", sortable: true },
        { key: "requestName", label: "Request", sortable: true },
        { key: "statusName", label: "Status", sortable: true },
        { key: "prescriberName", label: "Prescriber Name", sortable: true },
        { key: "created", label: "Created", sortable: true },
        { key: "updated", label: "Updated", sortable: true },
        { key: "actions", label: '' },
    ];
    private sortBy: string = 'created';

    private pageSize = 50;
    private pageNumber = 1;

    get startRowIndex() {
        return this.pageNumber * this.pageSize - this.pageSize;
    }

    get endRowIndex() {
        const calcIndex = this.startRowIndex + this.pageSize;
        if (calcIndex > this.filteredItems.length) {
            return this.filteredItems.length;
        }
        return calcIndex;
    }

    get totalRows() {
        return this.filteredItems.length;
    }

    @Watch("filteredItems")
    resetPaging() {
        this.pageNumber = 1;
    }

    created() {
        this.loadItems();
    }

    get filteredItems() {
        if (!this.startDate) return this.items;
        return this.items.filter(i => new Date(i.created) >= moment(this.startDate).toDate() &&
            new Date(i.created) < moment(this.endDate).add(1, "day").toDate());
    }

    @Watch("selectedDoctor")
    @Watch("selectedFaxType")
    @Watch("selectedMode")
    loadItems() {
        this.isLoading = true;
        this.$http.get<Array<PrescriberFollowUpCase>>(`/Prescriber/follow-up-queue/${this.selectedDoctor?.id || 0}/${this.selectedFaxType || ''}`,
            {
                params: {
                    allStatuses: this.selectedMode === "All"
                }
            }
        )
            .then(res => {
                this.items = res.data.map(i => new PrescriberFollowUpCase(i));
            })
            .catch(err => {
                this.$notification(NotificationOptions.error(err));
                const errorMsg = 'Error while loading prescribers follow-up cases.';
                console.error(errorMsg, { err, response: err?.response })
            })
            .finally(() => {
                this.isLoading = false;
            })
    }

    closeCaseModalAndReload() {
        this.$bvModal.hide(this.caseModalId);
        this.loadItems();
    }

    openDetails(selectedItem: PrescriberFollowUpCase) {
        this.selectedCase = selectedItem;
        this.$bvModal.show(this.caseModalId);
    }

    filterByPrescriber(prescriberId: number) {
        const prescriber = new Prescriber();
        prescriber.id = prescriberId;
        this.selectedDoctor = prescriber;
    }

    filterByType(type: number) {
        this.selectedFaxType = type;
    }

    @Watch("startDate")
    handleEndDate() {
        if (this.startDate && !this.endDate)
            this.endDate = moment().toISOString();
        if (!this.startDate)
            this.endDate = "";
    }

    @Watch("endDate")
    handleStartDate() {
        if (this.endDate && !this.startDate)
            this.startDate = this.endDate;
        if (!this.endDate)
            this.startDate = "";
    }

    createdToday() {
        this.startDate = new Date().toDateString();
        this.endDate = new Date().toDateString(); 
    }

    createdYesterday() {
        this.startDate = moment().subtract(1, 'day').toString();
        this.endDate = moment().subtract(1, 'day').toString();
    }

    createdThisWeek() {
        this.startDate = moment().startOf('week').toString();
        this.endDate = moment().startOf('week').add(1, 'week').toString();
    }

    createdLastWeek() {
        this.startDate = moment().startOf('week').subtract(1, 'week').add(1, 'day').toString();
        this.endDate = moment().startOf('week').subtract(1, 'week').add(1, 'week').toString();
    }

}
</script>

<style scoped>
.filter-grid {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 430px), 1fr));


}

@media (min-width: 1600px) {
    .filter-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>