<template>
    <div id="prescription-verification-container">
        <card v-if="!isLoading">
            <WarningsHeaderComponent
                :title="pageMode == 'pv2' ? 'Prescription Verification' : pageMode == 'audit' ? 'Audit' : 'PV1'"
                :warnings="warnings"
            />

            <b-form-row v-show="!((prescription && prescription.rxNumber) || pageMode == 'pv2')"
                id="prescription-search-row">
                <b-col>

                    <SearchComponent v-model="prescription" label="Prescription" search-u-r-l="/Prescription"
                        @input="setIsLoading" />

                </b-col>
            </b-form-row>

            <b-form-row id="alert-row">
                <b-col>
                    <DispensingErrorAlertComponent v-for="(err, index) in dispenseErrors" :key="err.id"
                                                   :confirmationModalId="'errorConfirmationModal_' + index"
                                                   :buttonTitles="dispenseErrorAlertButtonTitles"
                                                   v-model="dispenseErrors[index]"
                                                   @onStatusChange="fetchDispenseErrors"
                                                   ref="DispensingErrorAlertComponents" />
                </b-col>
            </b-form-row>
            <b-form-row v-show="pageMode != 'audit'">
                <b-col>
                    <span v-show="prescription && prescription.rxNumber && prescription.pV2ID">
                        <b-button variant="danger" @click="cancelPV2">
                            Cancel PV2
                        </b-button>
                    </span>
                    <a href="#" style="float:right" class="ml-2" @click="printByRxRf()">Print by Rx/Rf <i
                            class="fas fa-print"></i></a>
                    <router-link style="float:right" target="_blank" class="ml-2"
                        :to="{ name: 'PatientDashboard', params: { id: patient.id } }">
                        <span>Go To Profile</span> <i class="fas fa-user"></i>
                    </router-link>
                </b-col>
            </b-form-row>
            <b-form-row v-show="!!(prescription && prescription.rxNumber)" id="prescription-verification-row"
                name="prescription-verification-row">

                <b-col id="image-column" cols="5" v-show="pageMode != 'audit'">
                    <!-- IMAGE -->
                    <div class="image-wrapper">
                        <b-button v-if="prescription.escriptID" variant="warning" v-b-modal.all-escripts>
                            All Escript Images
                            <escript-list-modal v-model="prescription.rxID" id="all-escripts" />
                        </b-button>
                        <ImageComponent :imageID="prescription.imageID" :escriptID="prescription.escriptID"
                            :rxNumber="prescription.rxNumber" ref="prescriptionImage" class="script-image" />
                        <FilledImagesComponent :rx-id="prescription.rxID" :modal-presentation="false" :hide-title="true"
                            class="filled-image" />
                    </div>
                </b-col>

                <!-- DETAILS COLUMNS-->
                <b-col id="details-column">
                    <b-form-row id="prescription-identifier-row">
                        <b-col>
                            <strong>Rx #{{ prescriptionIdentifier }}</strong>
                        </b-col>
                    </b-form-row>

                    <div class="highlight p-3 mb-2" v-if="valueChangesSincePV1 && valueChangesSincePV1.length">
                        <h6 class="mb-0">
                            <i :class="{ 'fa': true, 'fa-chevron-right': true, 'fa-rotate-90': showAllChanges }"
                                style="transition: all .2s;" />
                            Has Changes Since PV1
                        </h6>

                        <div v-if="showAllChanges" class="changes-grid mt-3">
                            <div v-for="i in valueChangesSincePV1">
                                <strong>{{ camelCaseToProperCase(i.key) + ":" }}</strong><br>

                                <template v-if="i.source && !i.target">
                                    Removed {{ i.source }}
                                </template>
                                <template v-else-if="i.target && !i.source">
                                    Added {{ i.target }}
                                </template>
                                <template v-else>
                                    {{ i.source }}
                                    <i class="fa fa-arrow-right" style="font-size: 1em;"></i>
                                    {{ i.target }}
                                </template>
                            </div>
                        </div>
                    </div>

                    <b-form-row id="patient-row" class="bordered">
                        <b-col>
                            <b-form-row>
                                <b-col v-if="showExplicitVerification" cols="1">
                                    <n-switch id="switch_0" v-model="patientStatus" color="green"
                                        :class="{ 'selected': (verificationIndex === 0) }">
                                        <template #on>
                                            <i class="fa fa-check" />
                                        </template>
                                        <template #off>
                                            <i class="fa fa-times" style="color:red;" />
                                        </template>
                                    </n-switch>
                                </b-col>
                                <b-col>
                                    <b-form-row>
                                        <b-col>
                                            <strong :class="patientMatchClass">Patient Name:</strong>
                                            <p>{{ patient.displayNamesForPerson() }}</p>
                                            <p>{{ patient && patientAddressString }}</p>
                                            <p><b>Pn:</b> {{ patient && patient.phoneNumber }}</p>
                                            <p><b>Cn:</b> {{ patient && patient.cellNumber }}</p>
                                        </b-col>
                                        <b-col>
                                            <strong :class="patientMatchClass">DOB:</strong>
                                            <p v-if="patient && patient.dateOfBirth && patientIsUnder12"
                                                :style="{ 'font-weight': 'bold', color: 'red' }">
                                                {{ patientDobString }}
                                            </p>
                                            <p v-else>
                                                {{ patientDobString }}
                                            </p>
                                            <p>{{ patient && (patient.licenseNumber || patient.ssn) }}</p>
                                            <p><b>Sex:</b> {{ patient && patient.sex }}</p>
                                        </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                        <b-col>
                                            <strong :class="patientAllergyMatch">Allergies:</strong>
                                            <p style="font-weight:bold">
                                                {{ patient && patientAllergyString }}</p>
                                        </b-col>
                                        <b-col>
                                            <b-button @click="historyModal.openRxHistory()" variant="outline-primary"
                                                size="sm" :disabled="!(patient && patient.id)">Show This History
                                            </b-button>
                                            <b-button @click="rxHistoryModal.openRxHistory()" variant="outline-primary"
                                                size="sm" :disabled="!(patient && patient.id)">Show RX History
                                            </b-button>
                                            <b-button v-b-modal.other-med-modal variant="outline-primary" size="sm"
                                                :disabled="!(patient && patient.id)">
                                                Show Other Meds
                                            </b-button>
                                            <b-button v-b-modal.patient-notes-modal variant="outline-primary" size="sm"
                                                :disabled="!(patient && patient.id)">
                                                Show Patient Notes
                                            </b-button>
                                            <b-button @click="openRxEdit" variant="outline-primary" size="sm"
                                                :disabled="!(patient && patient.id)">
                                                Edit Rx
                                            </b-button>
                                            <b-button v-b-modal.label-reprint-modal variant="outline-primary" size="sm">
                                                Print Label
                                            </b-button>
                                        </b-col>
                                    </b-form-row>
                                </b-col>
                            </b-form-row>
                        </b-col>
                    </b-form-row>
                    <div>
                        <b-modal id="patient-notes-modal" title="Patient Notes" size="xl">
                            <notes-list :entity-id="patient.id" :entity-type="EntityType.Patient" page-size="10" />
                            <template #modal-footer>
                                <b-button variant="dark" @click="$bvModal.hide('patient-notes-modal')">
                                    Close
                                </b-button>
                            </template>
                        </b-modal>
                        <b-modal id="other-med-modal" title="Other Medications" size="xl">
                            <b-card>
                                <other-medications-component :patient="patient" disabled onlyActive />
                            </b-card>
                            <template #modal-footer>
                                <b-button variant="dark" @click="$bvModal.hide('other-med-modal')">
                                    Close
                                </b-button>
                            </template>
                        </b-modal>
                    </div>
                    <!-- Reprint-Label-Modal-->
                    <div>
                        <b-modal id="label-reprint-modal" title="Reprint Label">
                            <b-row>
                                <b-col>
                                    <fg-input type="text" placeholder="Enter IP Address Here"
                                        v-model="printerIpAddress"></fg-input>
                                </b-col>
                            </b-row>
                            <template #modal-footer>
                                <b-button @click="getPrinterByIPAddress" variant="success">Print</b-button>
                            </template>
                        </b-modal>
                    </div>

                    <b-form-row id="doctor-row" class="bordered">
                        <b-col v-if="showExplicitVerification" cols="1">
                            <n-switch id="switch_1" v-model="doctorStatus" color="green"
                                :class="{ 'selected': (verificationIndex === 1) }">
                                <template #on>
                                    <i class="fa fa-check" />
                                </template>
                                <template #off>
                                    <i class="fa fa-times" style="color:red;" />
                                </template>
                            </n-switch>
                        </b-col>
                        <b-col>
                            <strong :class="doctorNameMatch">Doctor Name:</strong>
                            <p>{{ doctorNameLabel }}</p>
                            <p>{{ doctor && doctorAddressString }}</p>
                        </b-col>
                        <b-col>
                            <strong :class="doctorNpiMatch">NPI:</strong>
                            <p>{{ doctor && doctor.npiNumber }}</p>
                        </b-col>
                        <b-col>
                            <strong :class="doctorDeaMatch">DEA:</strong>
                            <p>{{ doctor && doctor.deaNumber }}</p>
                        </b-col>
                        <b-col>
                            <strong>Phone:</strong>
                            <p>{{ doctor && doctor.phoneNumber }}</p>
                        </b-col>
                    </b-form-row>

                    <b-form-row id="drug-row" class="bordered">
                        <b-col v-if="showExplicitVerification" cols="1">
                            <n-switch id="switch_2" v-model="drugStatus" color="green"
                                :class="{ 'selected': (verificationIndex === 2) }">
                                <template #on>
                                    <i class="fa fa-check" />
                                </template>
                                <template #off>
                                    <i class="fa fa-times" style="color:red;" />
                                </template>
                            </n-switch>
                        </b-col>
                        <b-col>
                            <b-form-row class="pb-2">
                                <b-col>
                                    <strong>Drug:</strong><br />
                                    <h5>{{ drug.productNameShort }}</h5>
                                </b-col>
                                <b-col align-self="end" cols="3">
                                    <b-button v-b-modal.drug-change-modal size="sm" variant="success">Edit</b-button>
                                    <div v-if="drugDetails != null">
                                        <DrugChangeModal v-model="drugModalVisible" :prescription="prescription"
                                            :drug-details="drugDetails" @change-drug="drugChangeComplete" />
                                    </div>
                                </b-col>
                            </b-form-row>
                            <b-form-row
                                :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'PackageID') }">
                                <b-col cols="4">
                                    <b-img v-for="drugImage of drugImageURLs" v-show="drugImageURLs.length"
                                        :key="drugImage" :src="drugImage" onerror="this.style.display='none'" fluid />
                                </b-col>
                                <b-col>
                                    <b-form-row>
                                        <b-col>
                                            <strong :class="drugMatch">NDC:</strong>
                                            {{ drugDetails && drugDetails.ndc }} {{ drugMatch != 'text-success' &&
                                                dawDrugMismatch ? '(DAW NDC Mismatch) ' : ''}}
                                        </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                        <b-col>
                                            <strong>UPC:</strong>
                                            {{ upc }}
                                        </b-col>
                                    </b-form-row>
                                    <b-form-row v-if="drugDetails && drugDetails.packageDescription">
                                        <b-col>
                                            <strong>Package Description:</strong><br>
                                            {{ drugDetails.packageDescription }}
                                        </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                        <b-col>
                                            <strong>Package Size:</strong>
                                            {{
                                                drugDetails && `${drugDetails.packageSize} ${drugDetails.billingUnit}`
                                            }}
                                        </b-col>
                                    </b-form-row>
                                </b-col>
                            </b-form-row>
                        </b-col>
                    </b-form-row>

                    <b-form-row id="quantity-row" class="bordered">
                        <b-col v-if="showExplicitVerification" cols="1">
                            <n-switch id="switch_3" v-model="quantityStatus" color="green"
                                :class="{ 'selected': (verificationIndex === 3) }">
                                <template #on>
                                    <i class="fa fa-check" />
                                </template>
                                <template #off>
                                    <i class="fa fa-times" style="color:red;" />
                                </template>
                            </n-switch>
                        </b-col>
                        <b-col>
                            <b-form-row>
                                <b-col>
                                    <b-form-row>
                                        <b-col
                                            :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'Quantity') }">
                                            <strong :class="quantityMatch">Quantity:</strong>
                                            <p>{{ prescription && prescription.quantity }}</p>
                                        </b-col>
                                        <b-col
                                            :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'Dispensed') }">
                                            <strong>Dispensed:</strong>
                                            <p>{{ prescription && prescription.dispensed }}</p>
                                        </b-col>
                                        <b-col></b-col>
                                        <b-col></b-col>
                                    </b-form-row>
                                    <b-form-row>
                                        <b-col
                                            :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'Refills') }">
                                            <strong :class="refillsMatch">Refills:</strong>
                                            <p>{{ prescription.refills }}</p>
                                        </b-col>
                                        <b-col
                                            :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'TotalDispensed') }">
                                            <strong>Qty Left:</strong>
                                            <p>
                                                {{
                                                    prescription.totalAllowedToDispense - prescription.totalDispensed
                                                }}
                                            </p>
                                        </b-col>
                                        <b-col
                                            :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'DaySupply') }">
                                            <strong>Days' Supply:</strong>
                                            <p>{{ prescription && prescription.daySupply }}</p>
                                        </b-col>
                                        <b-col
                                            :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'DeliveryCode') }">
                                            <strong>Delivery:</strong>
                                            <p>{{ deliveryCodeText }}</p>
                                        </b-col>
                                    </b-form-row>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col>
                                    <strong class="warning">{{ safety }}</strong>
                                </b-col>
                            </b-form-row>
                        </b-col>
                    </b-form-row>

                    <b-form-row id="directions-row" class="bordered">

                        <b-col v-if="showExplicitVerification" cols="1">
                            <n-switch id="switch_4" v-model="directionStatus" color="green"
                                :class="{ 'selected': (verificationIndex === 4) }">
                                <template #on>
                                    <i class="fa fa-check" />
                                </template>
                                <template #off>
                                    <i class="fa fa-times" style="color:red;" />
                                </template>
                            </n-switch>
                        </b-col>
                        <b-col class="pb-2"
                            :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'Directions') }">
                            <strong>Directions:</strong>
                            <p id="directions">{{ prescription.directions }}</p>
                        </b-col>

                    </b-form-row>

                    <!-- Financial info-->
                    <b-form-row id="codes-row" class="bordered">
                        <b-col :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'OriginCode') }">
                            <strong :class="originOk">Origin:</strong>
                            <p>{{ originCodeText }}</p>
                        </b-col>
                        <b-col :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'DawCode') }">
                            <strong :class="dawMatch">DAW:</strong>
                            <strong v-if="prescription.dawCode != 0"><br /><a>{{ dawCodeText }}</a></strong>
                            <p v-else>{{ dawCodeText }}</p>
                        </b-col>
                    </b-form-row>

                    <b-form-row id="date-rows" class="bordered">
                        <b-col>
                            <b-form-row>
                                <b-col :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'FillDate') }">
                                    <strong :class="fillDateOk">Fill Date:</strong>
                                    <p>{{ fillDate }}</p>
                                </b-col>
                                <b-col
                                    :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'WrittenDate') }">
                                    <strong :class="writtenMatch">Written:</strong>
                                    <p>{{ writtenDate }}</p>
                                </b-col>
                                <b-col
                                    :class="{ highlight: !!valueChangesSincePV1?.find(c => c.key === 'ExpirationDate') }">
                                    <strong>Rx Expires:</strong>
                                    <p>{{ expiration }}</p>
                                </b-col>
                            </b-form-row>
                        </b-col>
                    </b-form-row>
                    <b-form-row id="flag-rows" class="bordered">
                        <b-col>
                            <b-form-row>
                                <b-col v-show="LotNumberRequired">
                                    <strong>Lot Number:</strong>
                                    <p>{{ prescription.lotNumber }}</p>
                                </b-col>
                                <b-col>
                                    <strong>Drug Expiration Date:</strong>
                                    <p>{{ drugExpDate }}</p>
                                </b-col>
                                <b-col v-show="SerialNumberRequired">
                                    <strong>Serial Number:</strong>
                                    <p>{{ prescription.serialNumber }}</p>
                                </b-col>
                                <b-col v-show="PartnerFillRequired">
                                    <strong>Partner Fill Number:</strong>
                                    <p>{{ prescription.partnerFillNumber }}</p>
                                </b-col>
                            </b-form-row>
                        </b-col>
                    </b-form-row>
                    <b-form-row id="memo-row" class="bordered">
                        <b-col>
                            <fg-input label="Rx Memo">
                                <textarea id="rx-memo" v-model="prescription.memo" class="form-control" block-listener
                                    :disabled="disableFields"></textarea>
                            </fg-input>
                        </b-col>
                    </b-form-row>

                    <b-form-row id="questionary-answers-row" class="bordered" v-if="drugCategories.length > 0">
                        <b-col>
                            <b-row v-for="cat in drugCategories" :key="cat.id">
                                <b-col>
                                    <QuestionaryComponent :categoryID="+cat.id" :prescription="prescription"
                                        :ref="`questionary_${cat.id}`" :disable="true">
                                    </QuestionaryComponent>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-form-row>
                    <br>
                    <!-- Offline billing Plan Alert -->
                    <b-form-row id="offline-claims-alert-row">
                        <b-col>
                            <b-alert v-model="allClaimsAreOffline" variant="danger">
                                All of the claims on this prescription are billed to offline plans.
                            </b-alert>
                        </b-col>
                    </b-form-row>
                    <b-form-row id="program-row" class="bordered my-2">
                        <b-col>
                            <h6>Program:</h6>
                            <p>{{ prescription.programName }}</p>
                        </b-col>
                    </b-form-row>
                    <b-form-row id="finance-row" class="bordered pb-2">
                        <b-col>
                            <b>Financial Information</b>
                            <InsuranceBillingComponent ref="insuranceComponent" readOnly :patient="patient"
                                :prescription="prescription" :hideMargin="pageMode == 'audit'"
                                :disabled="prescription && !prescription.isActive" @onClaimsLoaded="onClaimsLoaded" />
                        </b-col>
                    </b-form-row>
                    <!-- Hard Copy Note -->
                    <b-row>
                        <b-col>
                            <h5>Hardcopy Notes</h5>
                            <fg-input label="">
                                <textarea v-model="addNote" class="memo form-control" block-listener />
                            </fg-input>
                            <b-button variant="success" @click="saveHardCopyWithCallback">Add Hardcopy Note</b-button>
                        </b-col>
                    </b-row>

                    <b-card class="mt-2"
                        v-if="drugUtilizationReviews && !drugUtilizationReviews.isEmpty && !durIsSamePV1">
                        <div>
                            <n-switch id="switch_5" v-model="durStatus" color="green"
                                :class="{ 'selected': (verificationIndex === 5) }">
                                <template #on>
                                    <i class="fa fa-check" />
                                </template>
                                <template #off>
                                    <i class="fa fa-times" style="color:red;" />
                                </template>
                            </n-switch>
                            <h5 class="d-inline-block ml-2">DUR Results</h5>
                        </div>
                        <div v-if="drugUtilizationReviews.wasAbleToCalculate">
                            <div v-if="drugUtilizationReviews.allergies && drugUtilizationReviews.allergies.length">
                                <h6>Allergies</h6>
                                <b-row v-for="allergy in drugUtilizationReviews.allergies" :key="allergy.identifier"
                                    class="text-danger">
                                    <b-col class="font-weight-bold">{{ allergy.type == 0 ? 'Class' : 'Ingredient' }}:
                                        {{ allergy.name }}
                                    </b-col>
                                    <b-col class="font-weight-bold"></b-col>
                                </b-row>
                            </div>
                            <hr v-if="drugUtilizationReviews.allergies && drugUtilizationReviews.allergies.length">
                            <div
                                v-if="drugUtilizationReviews.drugInteractions && drugUtilizationReviews.drugInteractions.length">
                                <h6>Interactions</h6>
                                <b-row v-for="drugInteraction in drugUtilizationReviews.drugInteractions"
                                    :key="drugInteraction.interactingPackageId" class="mb-3">
                                    <b-col cols="12" class="font-weight-bold">{{ drugInteraction.drugName }} last
                                        dispensed
                                        {{ formatDate(drugInteraction.lastFilled) }} ({{
                                            ago(drugInteraction.lastFilled)
                                        }})
                                    </b-col>

                                    <b-col cols="12" v-for="interaction in drugInteraction.interactions"
                                        :key="interaction.observation">
                                        <div :class="'interaction-unit ' + severityClass(interaction.severity)">
                                            <div class="icon">
                                                <i :class="{
                                                    'fa fa-fw': true,
                                                    'fa-info': !severityClass(interaction.severity),
                                                    'fa-exclamation-circle': severityClass(interaction.severity) === 'major',
                                                    'fa-exclamation': severityClass(interaction.severity) === 'moderate'
                                                }"></i>
                                            </div>
                                            <div>
                                                <b-row>
                                                    <b-col cols="2" class="font-weight-bold severity-label">
                                                        <span>{{ interaction.severity }}</span>

                                                    </b-col>
                                                    <b-col class="font-weight-bold">{{ interaction.observation
                                                        }}</b-col>

                                                    <b-col cols="2" class="font-weight-bold">
                                                        {{ interaction.documentationLevel }}
                                                    </b-col>

                                                    <b-col cols="12" class="mt-2">{{ interaction.professionalNotes
                                                        }}</b-col>
                                                </b-row>
                                            </div>
                                        </div>

                                    </b-col>
                                </b-row>
                            </div>
                            <hr
                                v-if="drugUtilizationReviews.drugInteractions && drugUtilizationReviews.drugInteractions.length">
                            <div
                                v-if="drugUtilizationReviews.duplicateTherapies && drugUtilizationReviews.duplicateTherapies.length">
                                <h6>Duplicate Therapy</h6>
                                <b-row class="text-danger font-weight-bold">

                                    <b-col cols="12" v-for="(dup, index) in drugUtilizationReviews.duplicateTherapies"
                                        :key="index">
                                        {{ dup.drugName }} last dispensed {{ formatDate(dup.lastFilled) }}
                                        ({{ ago(dup.lastFilled) }})
                                    </b-col>
                                </b-row>
                            </div>
                            <hr
                                v-if="drugUtilizationReviews.duplicateTherapies && drugUtilizationReviews.duplicateTherapies.length">
                            <div
                                v-if="drugUtilizationReviews.pregnancyAndLactation && drugUtilizationReviews.pregnancyAndLactation.ratings && drugUtilizationReviews.pregnancyAndLactation.ratings.length">
                                <h6>Pregnancy &amp; Lactation</h6>
                                <b-row class="my-2"
                                    v-for="(rating, index) in drugUtilizationReviews.pregnancyAndLactation.ratings"
                                    :key="index">
                                    <b-col cols="4">{{ rating.ratingType }}{{ rating.trimester.length > 0 ? ':' : '' }}
                                        {{ rating.trimester }} - {{ rating.code }}
                                    </b-col>
                                    <b-col cols="12" v-if="rating.description">{{ rating.description }}</b-col>
                                </b-row>
                            </div>
                        </div>
                        <div v-else>
                            <b-row class="text-danger font-weight-bold text-center">
                                <b-col>
                                    Unable to calculate DUR on non-GSDD product.
                                    <br>
                                    You must manually verify DURs.
                                </b-col>
                            </b-row>
                        </div>
                    </b-card>

                </b-col> <!--id="details-column"-->
            </b-form-row>

            <b-form-row>
                <b-col>
                    <!-- If an error was reported, an alert is shown-->
                    <b-alert v-if="errorIsPending" show variant="warning">
                        <p>
                            A dispense error was reported for this prescription, it needs to be accepted before
                            continuing with this verification.
                            See dispense error alert above for details.
                        </p>

                    </b-alert>

                    <!-- If there is no error, buttons are shown-->
                    <NewClearSaveButtons :titles="buttonTitles" :disable="disableFields" :disableCancel="false"
                        :disableSave="disableVerify" :disableClear="errorIsPending" @cancelClicked="cancelClicked"
                        @saveClicked="verifyClicked" @clearClicked="openErrorDispenseModal" />
                </b-col>
            </b-form-row>

            <DispensingErrorModalComponent v-model="dispenseError" :reviewMode="!!dispenseError.id"
                @onClickSave="saveDispenseError" @errorModalClosed="addListeners" @errorModalOpened="removeListeners"
                @savedNote='reloadNotesForErrorAlert' />


            <PatientRxHistoryModalComponent id="patient_this_history_modal" ref="patient_this_history_modal"
                :patient="patient" :productName="prescription.productNameShort" />

            <PatientRxHistoryModalComponent id="patient_rx_history_modal" ref="patient_rx_history_modal"
                :patient="patient" />
        </card>
        <DebugViewComponent ref="disable-reason-debug-view" header="">
            disableVerifyReason: {{ disableVerifyReason }}
        </DebugViewComponent>
    </div>
</template>

<script lang="ts">
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { Lock, LockHandler } from '@/mixins/LockHandler';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import NewClearSaveButtons, { NewClearSaveTitles } from '@/components/NewClearSaveButtons.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import DispensingErrorModalComponent from '@/components/DispensingError/DispensingErrorModalComponent.vue';
import DispensingErrorAlertComponent from '@/components/DispensingError/DispensingErrorAlertComponent.vue';
import Switch from "@/components/Switch.vue";
import NSwitch from "@/components/Switch.vue";
import ImageComponent from '@/components/Prescription/ImageComponent.vue';
import PatientRxHistoryModalComponent from '@/components/Patient/PatientRxHistoryModalComponent.vue';
import InsuranceBillingComponent from '@/components/InsurancePlan/InsuranceBillingComponent.vue';
import QuestionaryComponent from '@/components/Questionary/QuestionaryComponent.vue';

import { DAWCode, OriginCode, Prescription } from '@/models/Prescription';
import { DeliveryCode } from '@/models/DeliveryCode';
import { Prescriber } from '@/models/Prescriber';
import { Verification, VerificationResponse } from '@/models/Verification';
import { Patient } from '@/models/Patient';
import { Store } from '@/models/Store';
import { Drug } from '@/models/Drug/Drug';
import { Note } from '@/models/Note';
import { DispenseError } from '@/models/DispenseError';
import { GSDDPatientAllergy } from '@/models/GSDD';
import { Order } from '@/models/Order';
import { DrugCategory } from '@/models/DrugCategory';
import { Claim } from '@/models/Claim';
import Button from '@/components/Button.vue';
import { Printer } from '@/models/Printer';
import DrugChangeModal from "@/components/Prescription/DrugChangeModal.vue";
import { NonGSDDDrug } from "@/models/Drug/NonGSDDDrug";
import { DrugProduct, DrugSource } from "@/models/Drug/DrugProduct";
import { CompoundDrug } from "@/models/Drug/CompoundDrug";
import { BvMsgBoxOptions } from "bootstrap-vue";
import { FunctionalityFlag } from "@/models/FunctionalityFlag";
import EscriptListModal from "@/components/Prescription/EscriptListModal.vue";
import { EscriptData } from '@/models/EscriptData';
import FilledImagesComponent from "@/components/Prescription/FilledImagesComponent.vue";
import OtherMedicationsComponent from "@/components/Patient/OtherMedicationsComponent.vue";
import NotesList from "@/components/Note/NotesList.vue";
import { EntityType } from "@/models/EntityType";
import DebugViewComponent from "@/components/DebugViewComponent.vue";
import DispenseErrorTypeEnum = DispenseError.DispenseErrorTypeEnum;
import WarningsHeaderComponent from "@/components/WarningsHeaderComponent.vue";
import { EscriptResponse } from "@/models/Escript";

@Component({
    name: "PrescriptionVerificationDetailsPage",
    components: {
        DebugViewComponent,
        NotesList,
        OtherMedicationsComponent,
        EscriptListModal,
        NSwitch,
        DrugChangeModal,
        SearchComponent,
        NewClearSaveButtons,
        DispensingErrorModalComponent,
        DispensingErrorAlertComponent,
        [Switch.name]: Switch,
        ImageComponent,
        PatientRxHistoryModalComponent,
        InsuranceBillingComponent,
        QuestionaryComponent,
        Button,
        FilledImagesComponent,
        WarningsHeaderComponent
    },
})
export default class PrescriptionVerificationDetailsPage extends Mixins(LockHandler) {
    @Ref('patient_this_history_modal') protected historyModal!: PatientRxHistoryModalComponent;
    @Ref('patient_rx_history_modal') private rxHistoryModal!: PatientRxHistoryModalComponent;
    // Flags for checkboxes
    private prescription: Prescription = new Prescription();
    private prescriptionLock: Lock | null = null;
    private patientStatus = false;
    private doctorStatus = false;
    private quantityStatus = false;
    private drugStatus = false;
    private directionStatus = false;
    private durStatus = false;
    protected drugCategories: Array<DrugCategory> = [];

    private store: Store | null = new Store();
    private patient: Patient = new Patient();
    private doctor: Prescriber = new Prescriber();
    private drug: DrugProduct | null = null;
    private order: Order | null = null;
    private dispenseError: DispenseError = new DispenseError();
    private dispenseErrors: Array<DispenseError> = [];
    protected dispenseErrorAlertButtonTitles: any = { accept: 'Approve', reject: 'Reject' };
    private patientAllergies: GSDDPatientAllergy[] = [];
    private drugUtilizationReviews: any = {};
    protected ipAddress: Array<Printer> = [];
    private printerIpAddress = '';
    private drugModalVisible: boolean = false;
    private pv1ModalVisible: boolean = false;
    private programFlags: FunctionalityFlag[] = [];
    private PartnerFillRequired = false;
    private SerialNumberRequired = false;
    private LotNumberRequired = false;
    protected drugAlert: string = "";
    private patientAddressErrors: string | null = null;

    protected mode: string = '';
    protected isLoading: boolean = true;
    protected patientAddressString = "";
    protected patientAllergyString = "";
    private isPrintingRxRf: boolean = false;

    protected requireExplicitPV1 = false;
    protected requireExplicitPV2 = false;
    protected patientPendingAllergy = false;

    protected durIsSamePV1: boolean = false;
    protected valueChangesSincePV1: Array<any> = [];
    protected showAllChanges: boolean = false;

    //Notes
    private addNote = '';
    protected hardcopyAnnotations: Note[] = [];

    protected verificationIndex = 0;
    protected allClaimsAreOffline = false;
    private escriptData: EscriptData | null = null;

    protected disableVerifyReason: string = "";

    get EntityType() {
        return EntityType;
    }

    blockListeners(): boolean {
        if (this.drugModalVisible) return true;
        if (this.pv1ModalVisible) return true;
        //Add HTML attribute 'block-listener' to any input where we don't want to listen key events.
        const blockersElements = document.querySelectorAll("*[block-listener]");
        let block = false;
        if (blockersElements?.length)
            blockersElements.forEach(el => {
                if (document.activeElement == el && !block)
                    block = true;
            });

        return block;
    }

    keypressListener(e: any) {
        if (this.blockListeners()) return;

        if (e.code === 'KeyV' && this.prescription && this.prescription.rxNumber) { //Check/Uncheck with 'V' key
            this.scrollToFocusedSwith();
            switch (this.verificationIndex) {
                //case 0: this.storeStatus = !this.storeStatus; break;
                case 0:
                    this.patientStatus = !this.patientStatus;
                    break;
                case 1:
                    this.doctorStatus = !this.doctorStatus;
                    break;
                case 2:
                    this.drugStatus = !this.drugStatus;
                    break;
                case 3:
                    this.quantityStatus = !this.quantityStatus;
                    break;
                case 4:
                    this.directionStatus = !this.directionStatus;
                    break;
                case 5:
                    this.durStatus = !this.durStatus;
                    break;
            }
            const indexLimit = this.drugUtilizationReviews.isEmpty ? 4 : 5;
            this.verificationIndex = this.verificationIndex > indexLimit - 1 ? 0 : this.verificationIndex + 1;
            e.preventDefault();
            this.scrollToFocusedSwith();
            return;
        }
    }

    keydownListener(e: any) {
        if (this.blockListeners()) return;
        if (e.code === "F5") e.preventDefault();
    }

    keyupListener(e: any) {
        if (this.blockListeners()) return;

        switch (e.code) {
            case 'NumpadEnter':
            case 'Enter':
                // if (this.hardcopyAnnotations) break;
                if ((this.durChecked || this.drugUtilizationReviews?.isEmpty))
                    if (!this.errorIsPending)
                        this.verifyClicked();
                    else
                        this.$notification(NotificationOptions.notificationOptionsPreset("Dispense Error is pending", NotificationOptions.NotificationTypes.warning));
                else this.openErrorDispenseModal();
                break;
            case 'F5':
                this.rxHistoryModal.openRxHistory();
                break;
        }
    }

    beforeDestroy() {
        this.removeListeners();
    }

    mounted() {
        this.addListeners();
    }

    addListeners() {
        document.addEventListener("keypress", this.keypressListener);
        document.addEventListener("keydown", this.keydownListener);
        document.addEventListener("keyup", this.keyupListener);
    }

    removeListeners() {
        document.removeEventListener("keypress", this.keypressListener);
        document.removeEventListener("keydown", this.keydownListener);
        document.removeEventListener("keyup", this.keyupListener);
    }

    scrollToFocusedSwith() {
        const swithID = 'switch_' + this.verificationIndex;
        const switch_dom = document.getElementById(swithID);
        if (switch_dom)
            switch_dom.scrollIntoView({ block: "center", behavior: "smooth" });
    }

    get buttonTitles(): NewClearSaveTitles {
        let cancel = this.routeDispenseErrorId ? "Back to Dispense Error List"
            : this.routeOrderId ? "Back to Order Verification"
                : "";

        return {
            new: "",
            clear: "Reject",
            save: "Accept",
            cancel: cancel,
        };
    }

    created() {
        console.log(`%c Created ${this.$options.name}`, "color: green");
        this.getPrescription(this.routeRxId);
        this.isLoading = true;
        window.scroll(0, 0);
    }

    drugChangeSuccess() {
        this.drugModalVisible = false;
        this.$router.go(0);
    }

    drugChangeFail(message: string) {
        this.drugModalVisible = false;
        this.dispenseError = new DispenseError(this.prescription);
        this.dispenseError.errorType = DispenseErrorTypeEnum.WrongNDC;
        this.dispenseError.reason = message;
        this.openErrorDispenseModal();
    }

    drugChangeComplete(response: AxiosResponse, newDrug: string) {
        console.log("newDrug: ", newDrug);

        function isAxiosError(item: any): item is AxiosError {
            // noinspection JSIncompatibleTypesComparison
            return (item as AxiosError).isAxiosError !== undefined;
        }

        if (isAxiosError(response)) {
            const err: AxiosError = response as AxiosError;
            const errorMessage = err.response?.data.message + '\n' + newDrug;
            this.drugChangeFail(errorMessage);
        } else if (response?.status == 200) {
            this.drugChangeSuccess();
        } else {
            console.warn("Bad place to have gotten into!");
        }
    }

    setIsLoading(_val: Patient) {
        this.isLoading = true;
    }

    get doctorAddressString(): string {
        if (!this.doctor) return "";
        return (this.doctor.address1 + (this.doctor.address2 ? ', ' + this.doctor.address2 : '') + ', '
            + this.doctor.addressCity + ', ' + this.doctor.addressState + ' ' + this.doctor.addressZip).toString();
    }

    // used to calculate if the patient is 12 & under
    get patientYearsOld(): number {
        return moment().diff(moment(this.patient.dateOfBirth), "years");
    }

    get patientIsUnder12(): boolean {
        return this.patientYearsOld <= 12;
    }

    get patientDobString(): string {
        if (!this.patient?.dateOfBirth) return 'unknown';
        let dobString = `${this.formattedLocalDate(this.patient.dateOfBirth)} (${this.patientYearsOld} years old)`;
        if (this.patientIsUnder12) dobString = " ⚠️ " + dobString + " ⚠️ ";
        return dobString;
    }

    get upc(): string {
        if (this.drug?.source != DrugSource.GsddFederal) return '';
        const drug = this.drug as Drug;
        const idents = drug?.packageDetail?.packageIdentifier?.filter((pi: any) => pi.identifierType == 8);
        return idents?.length > 0 ? idents[0].identifier : '';
    }

    get pageMode() {
        let res = '';
        if (this.$router.currentRoute.name == 'PV2') {
            res = "pv2";
        }
        if (this.$router.currentRoute.name == 'PV1') {
            res = "pv1";
        }
        if (this.$router.currentRoute.name == 'Audit') {
            res = "audit";
            document.title = `Audit ${this.routeRxId}`;
        }
        return res;
    }

    get routeRxId(): string | any {
        let res = this.$route.params.id;
        if (!res) {
            const storeID = Number(this.$route.params.storeID);
            const rxNumber = Number(this.$route.params.rxNumber);
            const rfNumber = Number(this.$route.params.rfNumber);
            if (!isNaN(storeID) && !isNaN(rxNumber) && !isNaN(rfNumber))
                res = `${storeID}-${rxNumber}-${rfNumber}`;
        }
        return res;
    }

    get routeOrderId(): number {
        let res = 0;
        if (this.$route.params.orderID)
            res = Number(this.$route.params.orderID);

        return res;
    }

    get routeDispenseErrorId(): number | null {
        let res = null;
        if (this.$route.params.dispenseErrorId)
            res = Number(this.$route.params.dispenseErrorId);
        else if (this.dispenseError?.id)
            res = Number(this.dispenseError?.id);
        return res;
    }

    get disableFields(): boolean {
        //return !(this.prescriptionLock || (this.prescription && !this.prescription.rxNumber));
        const lockedForUser = !this.prescriptionLock;
        const noEntityLoaded = !(this.prescription?.rxNumber);

        // Disable fields if
        // ** it's locked for the user
        // ** or if there is no object loaded and
        return (lockedForUser || noEntityLoaded);
    }

    get errorIsPending(): boolean {
        return !!(this.dispenseError?.id && !this.dispenseError?.accepted);
    }

    get disableForAllergy(): boolean {
        if (this.pageMode != "pv2") return false;
        return this.patientPendingAllergy;
    }

    get disableVerify(): boolean {
        this.disableVerifyReason = "";
        if (this.errorIsPending) {
            this.disableVerifyReason = "Pending Error";
            return true;
        }
        if (this.disableForAllergy) {
            this.disableVerifyReason = "Patient Pending Allergy";
            return true;
        }

        let validChecks = this.patientStatus
            && this.doctorStatus
            && this.quantityStatus
            && this.drugStatus
            && this.directionStatus;

        if (!this.drugUtilizationReviews?.isEmpty) {
            validChecks = validChecks && this.durChecked;
        }

        return !validChecks;
    }

    get durChecked(): boolean {
        return this.drugUtilizationReviews?.isEmpty || this.durStatus || this.durIsSamePV1;
    }

    get doctorNameLabel(): string {
        return this.doctor.displayNamesForPerson();
    }

    get deliveryCodeText(): string {
        if (!this.prescription) return "";
        return `${this.prescription.deliveryCode} - ${DeliveryCode[this.prescription.deliveryCode]}`;
    }

    get originCodeText(): string {
        if (!this.prescription) return "";
        return `${this.prescription.originCode} - ${OriginCode[this.prescription.originCode]}`;
    }

    get dawCodeText(): string {
        if (!this.prescription) return "";
        return `${this.prescription.dawCode} - ${DAWCode[this.prescription.dawCode]}`;
    }

    get requiresPV1(): boolean {
        return this.prescription.isPV1Required || (this.drug instanceof Drug && this.drug.isControl) || (this.drug instanceof CompoundDrug);
    }

    printByRxRf() {
        if (!this.isPrintingRxRf) {

            this.isPrintingRxRf = true;
            type PrintedViewModel = { printedLabels: number, badLabels: { label: any, error: string }[] };
            Axios.get<PrintedViewModel>(`/Print/${this.prescription.rxID}/rx-pated`)
                .then(res => {
                    if (res.data.printedLabels > 0 && res.data.badLabels?.length == 0) {
                        const successMessage = `PatEd for Rx ${this.prescription.rxID} was successfully printed`;
                        this.$notification(NotificationOptions.notificationOptionsPreset(successMessage, NotificationOptions.NotificationTypes.success));
                    }
                })
                .catch(err => {
                    this.$notification(NotificationOptions.error(err));
                    console.error("Error while pulling printing Rx", { err, response: err?.response });
                })
                .finally(() => {
                    this.isPrintingRxRf = false;
                });
        }
    }

    @Watch('prescription')
    async prescriptionUpdate(value: Prescription | null, oldValue: Prescription | null) {
        if (!value || !value.rxNumber) return;

        if (oldValue && oldValue.storeID && oldValue.rxNumber && oldValue.rfNumber) {
            if (oldValue.storeID != value.storeID
                || oldValue.rxNumber != value.rxNumber
                || oldValue.rfNumber != value.rfNumber) {
                await this.fetchPrescription(value);
            }
        }
    }

    fetchDrugAlert(drug: DrugProduct) {
        Axios.get(`/Drug/${drug.drugId}/Alert`, {
            params: { getProgram: true, drugSource: drug.source },
        })
            .then((response) => {
                this.drugAlert = response.data;
            })
            .catch((error) => {
                console.error("Error while getting drug alert", {
                    error,
                    response: error?.response,
                });
            });
    }

    fetchPrescription(prescription: Prescription) {
        const rxID = prescription.rxID;
        return this.getPrescription(rxID);
    }

    getPrescription(rxID: string) {
        return this.addLockWithURL(`/Prescription/${rxID}/Lock`, 60000)
            .then(result => {
                this.prescriptionLock = result;
            })
            .catch(error => {
                if (error.response && error.response.status == 418) {
                    const lockData = error.response.data;
                    const lockedBy = lockData.lockedBy;
                    const expires = lockData.expires;
                    this.$bvModal.msgBoxOk(`The Prescription is locked by ${lockedBy} until ${expires}.`);
                }
            })
            .finally(() => {
                // If it's locked should we even allow verification to view anything?
                // this.patientStatus = this.doctorStatus = this.quantityStatus = this.drugStatus = false;

                Axios.get<VerificationResponse>(`/Prescription/${rxID}/verification`)
                    .then(response => {
                        const data = response.data;

                        this.prescription = new Prescription(data.prescription);
                        this.patient = new Patient(data.patient);

                        this.patientAddressString = this.buildAddressString(this.patient);
                        this.doctor = new Prescriber(data.prescriber);
                        this.store = new Store(data.store);
                        this.order = new Order(data.order);
                        this.programFlags = data.programFlags;
                        this.patientAddressErrors = data.patientAddressErrors;

                        this.PartnerFillRequired = this.programFlags.find((m: any) => m.name == "Partner Fill Number Required")?.value ?? false;

                        if (this.pageMode == 'pv2') {
                            this.SerialNumberRequired = this.programFlags.find((m: any) => m.name == "Serial Number Required")?.value ?? false;
                            this.LotNumberRequired = this.programFlags.find((m: any) => m.name == "Lot Number Required")?.value ?? false;
                        } else {
                            this.SerialNumberRequired = false;
                            this.LotNumberRequired = false;
                        }

                        Axios.get(`/Allergy/GetGSDDAllergiesForPatient/${this.patient.id}`)
                            .then(response => {
                                this.patientAllergies = response.data;
                                this.patientAllergyString = this.buildAllergyString(this.patientAllergies);
                                if (this.patient.nkda) {
                                    this.patientAllergyString += 'NKDA';
                                }
                            }).catch(error => {
                                console.error("Error while loading allergies", { error, response: error?.response });
                            });

                        Axios.post(`/Prescription/${rxID}/GenerateDURs`)
                            .then(response => {
                                this.drugUtilizationReviews = Object.assign(new Verification.DrugUtilizationReviews(), response.data.dur);
                                this.valueChangesSincePV1 = response.data.changes;
                                this.durIsSamePV1 = response.data.isDURSameAsPV1;
                            }).catch(error => {
                                console.error("Error while pulling DUR", { error, response: error?.response });
                            });

                        Axios.get<EscriptResponse>(`/api/Escript/prescription/${this.prescription.rxID}`)
                            .then(resp => {
                                if (resp && resp.data) this.prescription.escriptID = +resp.data.eScript.id;
                            })
                            .finally(() => {
                                Axios.get<EscriptData>(`/Escript/Parsed/${this.prescription.escriptID}`)
                                    .then(response => {
                                        this.escriptData = new EscriptData(response.data);
                                    })
                                    .catch(error => {
                                        console.warn("Error attempting to fetch escript",
                                            { error, response: error?.response, });
                                    });
                            });

                        //Pull all errors reported for this prescription
                        this.dispenseErrors = data.dispenseErrors.map(err => Object.assign(new DispenseError(), err));

                        //Take the current one.
                        if (this.dispenseErrors?.some(err => !err.accepted))
                            this.dispenseError = this.dispenseErrors.filter(err => !err.accepted)[0];

                        //If there is no active Dispense error, set the new one with the RxID
                        if (!this.dispenseError.id) {
                            this.dispenseError.storeID = this.prescription.storeID;
                            this.dispenseError.rxNumber = this.prescription.rxNumber;
                            this.dispenseError.rfNumber = this.prescription.rfNumber;
                        }

                        if (data.drug.source == DrugSource.GsddFederal) {
                            this.drug = new Drug(null, data.drug);
                            this.drugDetails = new Drug(null, data.drug);
                        }
                        if (data.drug.source == DrugSource.NonGsddProduct) {
                            this.drug = new NonGSDDDrug(null, data.drug);
                            this.drugDetails = new NonGSDDDrug(null, data.drug);
                        }
                        if (data.drug.source == DrugSource.CompoundProduct) {
                            this.drug = new CompoundDrug(null, data.drug);
                            this.drugDetails = new CompoundDrug(null, data.drug);
                        }

                        if (this.drug != null) this.fetchDrugAlert(this.drug);
                        this.fetchDrugCategory(this.drug?.drugId);

                        //const verification = data.verifications.find(p => this.prescription && this.prescription.pV2ID ? p.id == this.prescription.pV2ID : this.prescription && this.prescription.pV1ID ? p.id == this.prescription.pV1ID : p.id != null);
                        let verification: Verification | null = {} as Verification;
                        if (this.pageMode == 'pv2') {
                            verification = data.verifications.find(v => v.id == (this.prescription?.pV2ID || 0)) || null;
                        } else if (this.pageMode == 'pv1') {
                            verification = data.verifications.find(v => v.id == (this.prescription?.pV1ID || 0)) || null;
                        }

                        this.requireExplicitPV1 = data.requireExplicitPV1;
                        this.requireExplicitPV2 = data.requireExplicitPV2;
                        this.patientPendingAllergy = data.patientPendingAllergy;

                        if (verification) {
                            this.patientStatus = !this.showExplicitVerification || verification.patient;
                            this.doctorStatus = !this.showExplicitVerification || verification.prescriber;
                            this.quantityStatus = !this.showExplicitVerification || verification.quantity;
                            this.directionStatus = !this.showExplicitVerification || verification.direction;
                            this.drugStatus = !this.showExplicitVerification || verification.drug;
                        } else {
                            this.patientStatus = !this.showExplicitVerification;
                            this.doctorStatus = !this.showExplicitVerification;
                            this.quantityStatus = !this.showExplicitVerification;
                            this.directionStatus = !this.showExplicitVerification;
                            this.drugStatus = !this.showExplicitVerification;
                        }
                    })
                    .catch(error => {
                        console.error('Error while loading verification details.', {
                            error,
                            response: error?.response,
                        });
                    })
                    .finally(() => {
                        if (this.pageMode == "pv2") {
                            if (this.requiresPV1 && !this.prescription.pV1ID) {
                                const opt: BvMsgBoxOptions = {
                                    title: "PV1 Needed",
                                    size: "md",
                                    buttonSize: "md",
                                    okTitle: "OK",
                                    hideHeaderClose: true,
                                    centered: true,
                                };
                                const msg = "PV1 was not performed for this prescription. " +
                                    "Ensure you have done all the checks required before dispensing.";
                                this.pv1ModalVisible = true;
                                this.$bvModal.msgBoxOk(msg, opt).finally(() => {
                                    this.pv1ModalVisible = false;
                                });
                            }
                        }
                        this.isLoading = false;
                    });
            });
    }

    // fetch Annotations
    fetchAnnotations() {
        if (!this.prescription) return;

        const id = `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
        Axios.get(`/Prescription/${id}/Annotations`)
            .then(response => {
                this.hardcopyAnnotations = response.data;
            })
            .catch(err => {
                console.warn(err);
            });
    }

    addHardcopyNote() {
        if (!this.prescription) return;

        const id = `${this.prescription.storeID}-${this.prescription.rxNumber}-${this.prescription.rfNumber}`;
        Axios.post(`/Prescription/${id}/AddHardcopyAnnotation`, { Body: this.addNote })
            .then(_response => {
                this.addNote = '';
                if (this.prescription.imageID)
                    (this.$refs.prescriptionImage as any).fetchPrescriptionImageSource(`image/${this.prescription.imageID}`);
                else if (this.prescription.escriptID)
                    (this.$refs.prescriptionImage as any).fetchPrescriptionEscriptResponse(this.prescription.escriptID);
                this.$notification(NotificationOptions.successSaveNotificationPreset("Hardcopy Note"));
            })
            .catch(err => {
                console.warn(err);
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Hardcopy Note", err));
            })
            .finally(() => {
                this.fetchAnnotations();
            });
    }

    saveHardCopyWithCallback(_billInsuranceCallback: Function) {
        // this.prescription.productNameShort = this.drug.productNameLong;
        this.prescription.quantity = this.prescription.quantity || 0;
        this.prescription.dispensed = this.prescription.dispensed || 0;
        // this.assignViewValues();
        console.log(this.saveHardCopyWithCallback);

        Axios.post('/Prescription/', this.prescription)
            .then(response => {
                this.prescription = Object.assign(new Prescription(), response.data);
                this.$notification(NotificationOptions.successSaveNotificationPreset(`Prescription ${this.prescription.rxID}`));
                this.addHardcopyNote();
            });
    }

    backToPreviousPage(backToOrderPage = false) {
        let orderId = this.routeOrderId || this.order?.id;
        if (!!(backToOrderPage || this.routeOrderId) && orderId)
            this.$router.push({ name: 'OrderVerification', params: { orderId: orderId.toString() } });
        else if (this.routeDispenseErrorId)
            this.$router.push({ name: 'PendingDispenseErrors' });
        else this.$router.go(-1);
    }

    postVerification() {
        if (!this.prescription) return;

        const PVNbr = this.pageMode == 'pv1' ? 1 : (this.pageMode == 'pv2' ? 2 : null);
        const postData = {
            Prescription: this.prescription,
            Verification: {
                Patient: this.patientStatus,
                Prescriber: this.doctorStatus,
                Quantity: this.quantityStatus,
                Drug: this.drugStatus,
                Direction: this.directionStatus,
                dur: this.drugUtilizationReviews?.isEmpty ? null : this.durStatus,
            },
            DUR: this.drugUtilizationReviews?.isEmpty ? null : this.drugUtilizationReviews,
            PVNbr,
        };

        Axios.post(`/Prescription/${this.prescriptionIdentifier}/verification`, postData)
            .then(_response => {
                this.$notification(NotificationOptions.successSaveNotificationPreset("Verification"));
                this.backToPreviousPage(true);
            })
            .catch(error => {
                this.$notification(NotificationOptions.errorSaveNotificationPreset("Verification", error));
                console.log('Error while posting verification', { error, response: error?.response });
            });
    }

    fetchDrugCategory(productID: number | string = 0) {
        if (!(this.drug?.drugId || productID)) return;
        if (this.drug?.source != DrugSource.GsddFederal) return;

        productID = productID as any || this.drug?.drugId as any;
        Axios.get<Array<DrugCategory>>(`Drug/${productID}/${this.drug.source}/categories`)
            .then(response => {
                this.drugCategories = response.data.map(r => Object.assign(new DrugCategory(), r));
            })
            .catch(error => {
                console.error('Error while getting drug categories', { error, response: error?.response });
            });
    }

    getPrinterByIPAddress() {
        Axios.post(`Print/PrintByIPAddress/${this.prescription.storeID}/${this.prescription.rxNumber}/${this.prescription.rfNumber}?ipAddress=${this.printerIpAddress}`)
            .then(_response => {
                this.printerIpAddress = '';
                this.$notification(NotificationOptions.notificationOptionsPreset(`Label was successfully printed`, NotificationOptions.NotificationTypes.success));
            })
            .catch(_error => {
                this.$notification(NotificationOptions.notificationOptionsPreset(`Unable to print label`, NotificationOptions.NotificationTypes.danger));
            });
    }

    get lastIpAddress(): any {
        const objStr = localStorage.getItem('printQueue_ipAddress');
        if (objStr)
            return JSON.parse(objStr);
        else return null;

    }

    @Watch('printerIpAddress')
    lastIpAddressChanged(val1: any, _val2: any) {
        if (val1 == new Printer()) {
            return;
        }
        localStorage.setItem('printQueue_ipAddress', JSON.stringify(val1));
        console.log('printerIpAddress', this.lastIpAddress);

    }

    get prescriptionIdentifier(): string {
        return this.prescription.rxID;
    }

    formattedLocalDate(value: Date): string {
        if (!value) return '';
        const date = moment(value);
        return date.format("L");
    }

    formattedDate(value: Date): string {
        if (!value) return '';
        const date = moment.utc(value);
        return date.format("L");
    }

    get expiration(): string {
        if (!this.prescription || !this.prescription.expirationDate) return "";
        return this.formattedDate(this.prescription.expirationDate);
    }

    get fillDate(): any {
        if (!this.prescription || !this.prescription.fillDate) return "";
        return this.formattedDate(this.prescription.fillDate);
    }

    get writtenDate(): any {
        if (!this.prescription || !this.prescription.writtenDate) return "";
        return this.formattedDate(this.prescription.writtenDate);
    }

    get drugExpDate(): any {
        if (!this.prescription || !this.prescription.drugExpirationDate) return "";
        return moment(this.prescription.drugExpirationDate).format('YYYY-MM-DD');
    }

    get safety(): string {
        return this.patient && this.patient.childCaps ? "USE SAFETY CAP" : "USE NON-SAFETY CAP";
    }

    protected drugImageURLs: string[] = [];
    private drugDetails: DrugProduct | null = null;

    @Watch('drugDetails')
    drugDetailsUpdate(value: DrugProduct | null, oldValue: DrugProduct | null) {
        if (value == oldValue) return;
        this.drugImageURLs = [];
        const baseURL = Axios?.defaults?.baseURL || '';
        if (!value) return;
        const imageURI = `${baseURL}/drug/get-image?packageID=${value.drugId}&drugSource=${value.source}`;
        this.drugImageURLs.push(imageURI);
    }

    formatDate(date: any): string {
        return moment(date).format('L');
    }

    ago(date: any): string {
        return moment(date).fromNow();
    }

    get patientMatchClass(): string {
        try {
            if (this.escriptData == null) return '';
            if (this.patient?.firstName.trim().toLowerCase() != this.escriptData?.patient?.firstName.trim().toLowerCase()) return 'text-danger firstName';
            if (this.patient?.lastName.trim().toLowerCase() != this.escriptData?.patient?.lastName.trim().toLowerCase()) return 'text-danger lastName';
            if (this.patient?.dateOfBirth != this.escriptData?.patient?.dateOfBirth) return 'text-danger dob';
            return 'text-success';
        } catch {
            return '';
        }
    }

    get patientAllergyMatch(): string {
        if (this.escriptData == null) return '';
        if (this.patient.nkda && (this.escriptData.allergies?.length ?? 0) > 0) return 'text-danger';
        if (this.patient.nkda && (this.escriptData.allergies?.length ?? 0) == 0) return 'text-success';
        return 'text-warning';
    }

    get doctorNameMatch(): string {
        if (this.escriptData == null) return '';
        if (this.doctor?.firstName.trim().toLowerCase() != this.escriptData.prescriber?.firstName.trim().toLowerCase()) return 'text-danger';
        if (this.doctor?.lastName.trim().toLowerCase() != this.escriptData.prescriber?.lastName.trim().toLowerCase()) return 'text-danger';
        return 'text-success';
    }

    get doctorNpiMatch(): string {
        if (this.escriptData == null) return '';
        if (this.doctor?.npiNumber != this.escriptData.prescriber?.npiNumber) return 'text-danger';
        return 'text-success';
    }

    get doctorDeaMatch(): string {
        if (this.escriptData == null) return '';
        if (this.doctor?.deaNumber != this.escriptData.prescriber?.deaNumber) return 'text-danger';
        return 'text-success';
    }

    get quantityMatch(): string {
        if (this.escriptData == null) return '';
        if (this.prescription.quantity != this.escriptData.prescribedMed?.quantityValue) return 'text-danger';
        return 'text-success';
    }

    get refillsMatch(): string {
        if (this.escriptData == null) return '';
        if (this.prescription.refills != this.escriptData.prescribedMed?.numberOfRefills) return 'text-danger';
        return 'text-success';
    }

    get dawMatch(): string {
        if (this.escriptData == null) return '';
        if (this.prescription.dawCode != this.escriptData.prescribedMed?.substitutions) return 'text-danger';
        // if (this.prescription.dawCode != 0
        //     && this.prescription.ndc11 != this.escriptData.prescribedMed.ndc11)
        // {
        //     return 'text-danger'
        // }
        return 'text-success';
    }

    get writtenMatch(): string {
        if (!this.escriptData?.prescribedMed?.writtenDate) return '';
        const writtenDateStartUtc = moment.utc(this.prescription.writtenDate).startOf('day');
        const escriptWrittenDateStartUtc = moment.utc(this.escriptData.prescribedMed?.writtenDate).startOf('day');
        if (!writtenDateStartUtc.isSame(escriptWrittenDateStartUtc)) return 'text-danger';
        return 'text-success';
    }

    protected dawDrugMismatch: boolean = false;
    get drugMatch(): string {
        this.dawDrugMismatch = false;
        if (!this.escriptData?.prescribedMed) {
            console.log("no escript data");
            return '';
        }

        if (this.drugDetails?.ndc == null) {
            console.log("no drugDetails");
            return '';
        }

        let processedNdc = this.drugDetails.ndc.replaceAll('-', '');
        if (processedNdc.length > 9) processedNdc = processedNdc.substring(0, 9);

        if (this.escriptData.prescribedMed.allowedNdcList?.length > 0
            && this.escriptData.prescribedMed.allowedNdcList.indexOf(processedNdc) >= 0) {
            if (this.prescription.dawCode == 0) {
                return 'text-success';
            } else {
                this.dawDrugMismatch = true;
            }
        }

        if (this.escriptData.prescribedMed.ndc11 == null) return '';
        let prescribedNdc = this.escriptData.prescribedMed.ndc11.replaceAll('-', '');
        if (prescribedNdc.length > 9) prescribedNdc = prescribedNdc.substring(0, 9);

        if (prescribedNdc != processedNdc) return 'text-danger';

        return 'text-success';
    }

    get fillDateOk(): string {
        if (moment(this.prescription.fillDate).isSameOrAfter(moment().subtract(2, 'weeks'))) {
            return 'text-success';
        }
        return 'text-warning';
    }

    get originOk(): string {
        if (this.escriptData == null && this.prescription.originCode == OriginCode.Escript) {
            return 'text-danger';
        }

        if (!this.escriptData?.store) {
            return '';
        }

        if (this.escriptData.store.npiNumber != this.store?.npiNumber && this.prescription.originCode != OriginCode.Transfer) {
            return 'text-danger';
        }

        if (this.escriptData.store.npiNumber == this.store?.npiNumber && this.prescription.originCode != OriginCode.Escript) {
            return 'text-danger';
        }

        return 'text-success';
    }

    @Watch('drug') drugUpdate(value: DrugProduct | null, oldValue: any) {
        if (!value || value && oldValue && oldValue.id) {
            this.drugDetails = value;
        }
        if (!value || !value.drugId || (oldValue && oldValue.packageID && +value.drugId == +oldValue.packageID)) return;
        if (this.prescription) this.prescription.packageID = +value.drugId; // ensure packageID is a number;

        if (value.source == DrugSource.GsddFederal) {
            Axios.get(`/Drug/${value.drugId}`)
                .then(response => {
                    this.drugDetails = new Drug(value.drugId, response.data);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
        if (value.source == DrugSource.NonGsddProduct) {
            Axios.get(`/NonGSDD/${value.drugId}`)
                .then(response => {
                    this.drugDetails = new NonGSDDDrug(value.drugId, response.data);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
        if (value.source == DrugSource.CompoundProduct) {
            Axios.get(`/Compound/${value.drugId}`)
                .then(response => {
                    this.drugDetails = new CompoundDrug(value.drugId, response.data);
                })
                .catch(error => {
                    console.warn(error);
                });
        }

    }

    saveDispenseError() {
        this.dispenseError.pv = this.pageMode;
        Axios.post<DispenseError>("/DispenseError", this.dispenseError)
            .then(response => {
                if (response.data.id) {
                    this.fetchDispenseErrors();
                    this.$notification(NotificationOptions.notificationOptionsPreset("Dispense error was reported.", NotificationOptions.NotificationTypes.info));
                }
            })
            .catch(error => {
                console.error("Error saving dispense error", { error, response: error?.response });
            })
            .finally(() => this.openErrorDispenseModal());
    }

    buildAddressString(_patient: Patient) {
        return (this.patient.address1 + (this.patient.address2 ? ', ' + this.patient.address2 : '') + ', '
            + this.patient.addressCity + ', ' + this.patient.addressState + ' ' + this.patient.addressZip).toString();
    }

    buildAllergyString(allergies: GSDDPatientAllergy[]) {
        let result = "";
        allergies.forEach(allergy => result = result.concat(allergy.allergyName + ', '));
        result = result.slice(0, -2);
        return result;
    }

    fetchDispenseErrors() {
        // Refresh errors and get the new pending.
        Axios.get<Array<DispenseError>>(`DispenseError/By-RxId/${this.prescription.rxID}/`)
            .then(response => {
                const findActiveDispenseErrorCallback = (err: DispenseError) => err.statusEnum != DispenseError.DispenseErrorStatusEnum.Accepted;
                const emptyDispenseError = new DispenseError(this.prescription);
                this.dispenseErrors = response.data.map((err: DispenseError) => Object.assign(new DispenseError(), err));
                if (this.dispenseErrors?.some(findActiveDispenseErrorCallback))
                    this.dispenseError = this.dispenseErrors.find(findActiveDispenseErrorCallback) || emptyDispenseError;
                else this.dispenseError = emptyDispenseError;
            })
            .catch(err => {
                console.log("Error while refreshing dispense errors", err);
            });
    }

    verifyClicked() {
        if (!this.prescription) return;

        if (this.LotNumberRequired && (!this.prescription.lotNumber || this.prescription.lotNumber.trim().length == 0)) {
            return this.$bvModal.msgBoxOk(`Lot Number required.`);
        }
        if (this.SerialNumberRequired && (!this.prescription.serialNumber || this.prescription.serialNumber.trim().length == 0)) {
            return this.$bvModal.msgBoxOk(`Serial Number required.`);
        }
        if (this.PartnerFillRequired && (!this.prescription.partnerFillNumber || this.prescription.partnerFillNumber.trim().length == 0)) {
            return this.$bvModal.msgBoxOk(`Partner Fill Number required.`);
        }

        return this.postVerification();
    }

    openErrorDispenseModal() {
        this.$bvModal.show("dispense-error-modal");
    }

    cancelClicked() {
        this.backToPreviousPage();
    }

    openRxEdit() {
        this.$router.push({
            name: 'PrescriptionDetails',
            params: {
                storeID: this.prescription.storeID.toString(),
                rxNumber: this.prescription.rxNumber.toString(),
                rfNumber: (this.prescription.rfNumber as number).toString(),
            },
        });
    }

    reloadNotesForErrorAlert(dispenseErrorId: number) {
        const components = this.$refs.DispensingErrorAlertComponents as Array<DispensingErrorAlertComponent>;
        const component = components?.find((com: DispensingErrorAlertComponent) => com.value.id == dispenseErrorId);
        if (component)
            component.loadNotes();
        else
            console.error("Reference dont exist for the Dispense Error Alert component");
    }

    cancelPV2() {
        this.$bvModal.msgBoxConfirm("By Cancelling PV2, any previous PV2 done for this RX will cancelled and inventory will be put back to stock. Are you sure?", {
            title: 'Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        })
            .then(value => {
                if (!value) return;

                const rxId = this.prescription.rxID;
                if (!rxId) return;

                Axios.get(`/Prescription/${rxId}/cancel-pv2/`)
                    .then(response => {
                        if (response.data.cancelled) {
                            this.prescription.pV2ID = null;
                            this.$notification(NotificationOptions.notificationOptionsPreset("PV2 was cancelled.", NotificationOptions.NotificationTypes.success));
                        } else
                            this.$notification(NotificationOptions.errorSaveNotificationPreset("PV2 cancellation"));
                    })
                    .catch(error => {
                        console.error("Error while cancelling PV2", { error, response: error?.response });
                        this.$notification(NotificationOptions.errorSaveNotificationPreset("PV2 cancellation", error));
                    });
            })
            .catch(err => {
                console.error("Error caught on cancel PV2 button.", err);
            });
    }

    onClaimsLoaded(billedClaims: Claim[]) {
        let allOffline = true;
        for (const claim of billedClaims) {
            if (!claim.isOffline) {
                allOffline = false;
                break;
            }
        }
        this.allClaimsAreOffline = allOffline;
    }

    get showExplicitVerification() {
        if (this.pageMode == "pv1" && this.requireExplicitPV1) return true;
        return this.pageMode == "pv2" && this.requireExplicitPV2;

    }

    camelCaseToProperCase(str: string) {
        return str.replace(/([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, function (match, p1, p2, p3, p4, p5) {
            if (p1 && p2) {
                return p1 + p2.toLowerCase() + p3;
            } else {
                return p4 + ' ' + p5;
            }
        }).replace(/^./, function (str) {
            return str.toUpperCase();
        });
    }

    get warnings() {
        const showAll = false;

        return [
            {
                name: "pendingAllergies",
                title: "Patient Has Pending Allergies",
                icon: "fa fa-box-tissue",
                variant: "danger",
                visible: showAll || this.patientPendingAllergy
            },
            {
                name: "patientMemo",
                title: "Patient Memo",
                message: this.patient.alert,
                icon: "fa fa-user",
                dismissable: true,
                visible: showAll || !!this.patient?.alert
            },
            {
                name: "patientAddress",
                title: "Patient Address Validation Errors",
                message: this.patientAddressErrors,
                icon: "fa fa-home",
                variant: "warning",
                dismissable: true,
                visible: showAll || !!this.patientAddressErrors
            },
            {
                name: "doctorMemo",
                title: "Doctor Memo",
                message: this.doctor.alert,
                icon: "fa fa-user-md",
                variant: "danger",
                dismissable: true,
                visible: showAll || !!this.doctor?.alert
            },
            {
                name: "drugMemo",
                title: "Drug Memo",
                message: this.drugAlert,
                icon: "fa fa-capsules",
                variant: "danger",
                dismissable: true,
                visible: showAll || !!this.drugAlert
            }
        ];
    }

    severityClass(str: string) {
        if (str.includes('3')) return "moderate";
        if (str.includes('1') || str.includes('2')) return "major";
        return "";
    }

}

</script>

<style scoped>
h4 {
    margin-top: 0;
}

p {
    margin-bottom: 0;
}

.bordered {
    border-style: solid;
    border-width: thin;
    border-color: currentColor;
}

.selected {
    border-style: solid;
    border-width: 2px;
    border-color: red;
}

.changes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 200px), 1fr));
    gap: 10px;
}

.highlight {
    background: rgba(255, 255, 0, .2);
}

.image-wrapper {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch !important;
}

.script-image {
    height: 100vh;
    border: 1px solid black;
    flex-grow: 1;
    max-height: 100vh !important;
}

.interaction-unit {
    border: 1px solid gray;
    margin-bottom: 7px;
    gap: 5px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.02);
}

.interaction-unit>* {
    padding: 5px;
}

.interaction-unit .icon {
    background: gray;
    color: white;
    align-self: stretch;
    padding: 3px;
    font-size: 1.2em;
}

.interaction-unit.moderate {
    border-color: orangered;
}

.interaction-unit.moderate {
    background: rgba(255, 68, 0, 0.07);
}

.interaction-unit.moderate .icon {
    background: orangered;
}

.interaction-unit.major {
    border-color: red;
}

.interaction-unit.major {
    background: rgba(255, 0, 0, 0.07);
}

.interaction-unit.major .icon {
    background: red;
}

.interaction-unit.major .severity-label span {
    background: red;
    color: white;
    padding: 0 5px;
    border-radius: 6px;
}
</style>
