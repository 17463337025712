
.pinned {
    background: rgba(gold, .1);
    transition: all .2s;

    .byline-bar {
        background: rgba(white, .1);
    }
}

.note {
    border-radius: 0 15px 15px 15px;
    margin-bottom: 10px;
}

.child {
    border-radius: 15px 15px 0 15px;
    margin-top: 7px !important;
    margin-bottom: 5px !important;
}

.note-body {
    white-space: pre-wrap;
    line-height: 1.35;
    margin-top: 8px;
}

.byline-bar {
    font-weight: 600;
    font-size: .75em;
    border-top: 1px solid rgba(black, 0.05);
    margin-left: -15px;
    margin-right: -15px;
    padding: 3px 5px;
    margin-top: 10px;
    padding-left: 15px;
    letter-spacing: .25px;

    a {
        padding: 5px;
    }
}
