<template>
    <div class="doctor-component">
        <card title="Doctor Record">
            <form>
                <b-row v-if="newMode">
                    <b-col>
                        <search-component v-model="surescriptsDoctor"
                                          label="NPI Lookup"
                                          search-u-r-l="/api/Prescriber/Surescripts"
                                          clearable
                                          :disabled="disableFields"
                                          @input="surescriptsSelected"
                        />
                    </b-col>
                </b-row>
                <NameSearchComponent v-model="displayName" :named-person="doctor" :disable-fields="disableFields"
                    :searchMode="disableFields" @personUpdate="personUpdate" @input="nameChanged">
                    <div class="col-4">
                        <fg-input v-model="doctor.phoneNumber" label="Phone" :disabled="disableFields">
                        </fg-input>
                    </div>
                </NameSearchComponent>

                <b-form-row>
                    <b-col>
                        <AddressFormFields v-model="doctor" :disable-fields="disableFields">
                            <!--<template #row-1>
                                <b-col cols="4" offset="1">
                                    <fg-input v-model="doctor.cellNumber"
                                              label="Cell"
                                              :disabled="disableFields"></fg-input>
                                </b-col>
                            </template>-->
                            <template #row-1>
                                <b-col cols="4" offset="1">
                                    <fg-input v-model="doctor.altNumber" label="Alternate Phone"
                                        :disabled="disableFields"></fg-input>
                                </b-col>
                            </template>
                            <template #row-2>
                                <b-col cols="4" offset="1">
                                    <fg-input v-model="doctor.faxNumber" label="Fax"
                                        :disabled="disableFields"></fg-input>
                                </b-col>
                            </template>
                        </AddressFormFields>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col cols="7">
                        <fg-input v-model="doctor.emailAddress" label="Email" type="email"
                            :disabled="disableFields"></fg-input>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col>
                        <fg-input v-model="doctor.deaNumber" label="DEA Number" :disabled="disableFields"></fg-input>
                    </b-col>
                    <b-col>
                        <fg-input v-model="doctor.npiNumber" label="NPI Number" :disabled="disableFields"></fg-input>
                    </b-col>
                    <b-col>
                        <fg-input v-model="doctor.stateLicenseNumber" label="State License Number"
                            :disabled="disableFields"></fg-input>
                    </b-col>
                    <b-col>
                        <fg-input v-model="doctor.sureScriptsId" label="Sure Scripts ID"
                            :disabled="disableFields"></fg-input>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col cols="12">
                        <fg-input label="Alert">
                            <textarea v-model="doctor.alert"
                                      class="form-control"
                                      :disabled="disableFields"></textarea>
                        </fg-input>
                    </b-col>
                    <b-col>
                        <b-checkbox v-model="doctor.allowsPa"
                                    :disabled="disableFields">Allows Pa
                        </b-checkbox>
                        <b-checkbox v-model="doctor.reminderOptOut"
                                    :disabled="disableFields">Fax Reminder Opt Out
                        </b-checkbox>
                    </b-col>
                </b-form-row>
                <b-row>
                    <b-col>
                        <SearchComponent v-model="selectedProgram" label="Program" search-u-r-l="/Program"
                            :params="{ active: true }" :disabled="disableFields"
                            :id="selectedProgram && selectedProgram.id" clearable prepopulate>
                        </SearchComponent>
                    </b-col>
                </b-row>
                <hr>
                <b-row v-if="doctor && doctor.id">
                    <b-col>
                        <NotesForm :entityId="doctor.id"
                                   :entityType="doctorEntityType"
                                   @saved="refreshNotesList"/>
                        <NotesList ref="notesList"
                                   :entityId="doctor.id"
                                   :entityType="doctorEntityType"
                                   :pageSize="5"/>
                    </b-col>
                </b-row>

            </form>
            <div v-if="doctor?.contacts">
                <h4>Office Contacts</h4>

                <div class="contact-cards">
                    <b-card v-for="(contact, index) in doctor.contacts" :key="contact.id">
                        <div class="pull-right">
                            <b-button variant="link" @click.prevent="editContact(contact, index)" title="Edit"
                                class="m-0">
                                <b-icon icon="pencil" variant="primary" />
                            </b-button>
                            <b-button variant="link" @click.prevent="removeContact(contact)" title="Remove" class="m-0">
                                <b-icon icon="x-circle-fill" variant="danger" />
                            </b-button>
                        </div>
                        <strong>
                            {{ contact.firstName }}
                            {{ contact.lastName }}
                        </strong>
                        <div>
                            {{ contact.title }}
                        </div>
                        <div>
                            {{ contact.phoneNumber }}
                        </div>
                        <div>
                            <small class="text-muted">
                                {{ contact.note }}
                            </small>
                        </div>
                    </b-card>

                    <b-card body-class="text-center" @click="addContact" class="add-card text-muted">
                        <div>
                            <i class="fa fa-3x fa-plus-circle mb-3" />
                        </div>

                        Add Office Contact
                    </b-card>
                </div>
            </div>
            <NewClearSaveButtons :titles="buttonTitles" :disable="disableFields" :disableSave="!isFormValid"
                :disableClear="disableFields" @newClicked="createPrescriber" @clearClicked="undoChanges"
                @saveClicked="savePrescriber" @cancelClicked="emitClose">
            </NewClearSaveButtons>
        </card>

        <b-modal id="office-contact-modal" title="Office Contact" centered no-fade
            :ok-disabled="!selectedContactIsValid" @ok="updateContact">
            <template v-if="selectedContact">
                <b-form-group label="First Name *">
                    <b-input v-model="selectedContact.firstName" maxlength="35" autofocus />
                </b-form-group>
                <b-form-group label="Last Name *">
                    <b-input v-model="selectedContact.lastName" maxlength="45" />
                </b-form-group>
                <b-form-group label="Title">
                    <b-input v-model="selectedContact.title" maxlength="25" />
                </b-form-group>
                <b-form-group label="Phone Number">
                    <b-input v-model="selectedContact.phoneNumber" maxlength="12" />
                </b-form-group>
                <b-form-group label="Note">
                    <b-textarea v-model="selectedContact.note" maxlength="150" />
                </b-form-group>
            </template>
        </b-modal>
    </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
import axios from 'axios';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { Lock, LockHandler } from '@/mixins/LockHandler';
import { Prescriber, PrescriberContact } from '@/models/Prescriber';
import { Program } from '@/models/Program';
import NameSearchComponent from '@/components/NameSearchComponent.vue';
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import AddressFormFields from '@/components/AddressFormFields.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import NotesList from '../Note/NotesList.vue';
import NotesForm from '../Note/NotesForm.vue';
import { EntityType } from "@/models/EntityType";
import NotificationTypes = NotificationOptions.NotificationTypes;

@Component({
    name: 'DoctorFormComponent',
    components: { NameSearchComponent, NewClearSaveButtons, AddressFormFields, SearchComponent, NotesForm, NotesList },
})
export default class DoctorFormComponent extends Mixins(LockHandler, DisableAutocompleteMixin) {

    @Prop() private buttonTitles!: any;
    @Prop({ type: Boolean, default: false }) private createNewDoctor!: boolean;
    @Prop() private escriptResponsePrescriber?: Prescriber;

    created() {
        console.log(`%c Created ${this.$options.name}`, "color: green");
        if (this.createNewDoctor)
            this.createPrescriber();
    }

    beforeDestroy() {
        console.log(`%c Destroying ${this.$options.name}`, "color: red");
    }

    private surescriptsDoctor: Prescriber = new Prescriber();
    private doctor: Prescriber = new Prescriber();
    private selectedProgram: Program = new Program();

    private doctorLock: Lock | null = null;

    private newMode = false;
    private doctorEntityType = EntityType.Prescriber;

    refreshNotesList() {
        if (this.$refs.notesList instanceof NotesList)
            this.$refs.notesList.loadNotes();
    }

    get disableFields(): boolean {
        const lockedForUser = !this.doctorLock;
        const noEntityLoaded = !(this.doctor && this.doctor.id);

        // Disable fields if
        // ** its locked for the user
        // ** or if there is no doctor loaded and is not in New object Mode.
        return (lockedForUser || noEntityLoaded) && !this.newMode;
    }

    private nameIntroduced = "";
    private displayName = "";

    @Watch('doctor') onDoctorChanged(value: Prescriber, oldValue: Prescriber) {
        if (!value) {
            this.displayName = "";
            return;
        }
        const doctor = value;
        if (!value.id) {
            this.doctor.updateNamesForPersonFromDisplayName(this.displayName);
        } else {
            if (!oldValue || oldValue.id != doctor.id) {
                this.fetchPrescriber(doctor);
            }
        }
        this.displayName = value.displayNamesForPerson();
    }

    fetchPrescriber(prescriber: Prescriber) {
        this.addLockWithURL(`/Prescriber/${prescriber.id}/lock`, 60000)
            .then(result => {
                console.log(result);
                this.doctorLock = result;
            })
            .catch(error => {
                if (error.response && error.response.status == 418) {
                    // doctor locked
                    console.log('Doctor is locked');
                    console.log(error.response);
                    const lockData = error.response.data;
                    const lockedBy = lockData.lockedBy;
                    const expires = lockData.expires;
                    this.$bvModal.msgBoxOk(`The Doctor is locked by ${lockedBy} until ${expires}.`);
                }
            })
            .finally(() => {
                axios.get(`/Prescriber/${prescriber.id}`)
                    .then(response => {
                        this.doctor = Object.assign(new Prescriber(), response.data);
                        this.selectedProgram.id = this.doctor?.programId || 0;
                        this.displayName = this.doctor.displayNamesForPerson();
                        this.nameIntroduced = this.doctor.displayNamesForPerson();
                        this.newMode = false;
                    })
                    .catch(error => {
                        console.warn(error);
                    });
            });
    }

    surescriptsSelected(value: any) {
        this.doctor = new Prescriber(value);
        (this.doctor.id as any) = undefined;
        this.displayName = this.doctor.displayNamesForPerson();
        this.nameIntroduced = this.doctor.displayNamesForPerson();
    }

    personUpdate(value: any) {
        this.doctor = value;
    }

    nameChanged(val: string) {
        this.nameIntroduced = val;
    }

    undoChanges() {
        this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
            title: 'Confirm',
            okVariant: 'danger',
            centered: true,
        })
            .then(value => {
                if (!value) return;
                if (this.doctorLock && this.doctorLock.refreshURL) {
                    this.releaseLockAtURL(this.doctorLock.refreshURL);
                    this.doctorLock = null;
                }
                this.doctor = new Prescriber();
                this.newMode = false;
                this.selectedProgram = new Program();
                this.displayName = '';
            })
            .catch(err => {
                console.log("Error caught in undoChanges()");
                console.error(err);
            });
    }

    createPrescriber() {
        this.newMode = true;
        this.displayName = '';
        this.doctor = new Prescriber();
        if (this.escriptResponsePrescriber) {
            Object.assign(this.doctor, this.escriptResponsePrescriber);
            this.displayName = this.doctor.displayNamesForPerson();
            this.nameIntroduced = this.doctor.displayNamesForPerson();
        }
    }

    savePrescriber() {
        if (!this.doctor) {
            console.warn("We have no doctor/prescriber to save, get a new one?");
            this.createPrescriber();
        }
        const doctor: Prescriber = this.doctor as Prescriber;
        doctor.updateNamesForPersonFromDisplayName(this.displayName);

        const data = doctor;
        data.programId = (this?.selectedProgram?.id as number);
        axios.post<{ prescriber: Prescriber, warnings: Array<string> }>('/Prescriber', data)
            .then(response => {
                this.doctor = Object.assign(new Prescriber(), response.data.prescriber);
                if (response.data.warnings.length > 0) {
                    for (const warning of response.data.warnings) {
                        this.$notification(NotificationOptions.notificationOptionsPreset(warning, NotificationTypes.warning));
                    }
                }
                this.$notification(NotificationOptions.successSaveNotificationPreset('Doctor'));

                if (this.createNewDoctor) {
                    this.$emit("created", this.doctor);
                }
            })
            .catch(error => {
                console.warn(error);
                this.$notification(NotificationOptions.errorSaveNotificationPreset('Doctor'));
                // probably do some smart error handling here at some point.
            });
    }

    get selectedProgramId() {
        return this.selectedProgram?.id || 0;
    }

    optionLabel(option: Prescriber) {
        return "ID: " + option.id + " " + option.lastName + ", " + option.firstName;
    }

    get isFormValid() {
        return !!(this.nameIntroduced);
    }

    programLabel(object: Program) {
        return object.name ? `Program - Abbr: ${object.name} - ${object.abbreviation}` : '';
    }

    emitClose() {
        if (this.createNewDoctor) {
            this.$emit("close");
        }
    }

    removeContact(contact: PrescriberContact) {
        this.doctor.contacts = this.doctor.contacts.filter(c => c !== contact);
    }

    private selectedContact: PrescriberContact | null = null;
    private selectedContactIndex: number = -1;

    editContact(contact: PrescriberContact, index: number) {
        // @ts-ignore
        this.selectedContact = structuredClone(contact);
        this.selectedContactIndex = index;
        this.$bvModal.show("office-contact-modal");
    }

    updateContact() {
        this.doctor.contacts.splice(this.selectedContactIndex, 1, this.selectedContact!);
    }

    addContact() {
        this.selectedContact = new PrescriberContact();
        this.selectedContactIndex = this.doctor.contacts.length;
        this.$bvModal.show("office-contact-modal");
    }

    get selectedContactIsValid() {
        if (!this.selectedContact) return false;
        if (!this.selectedContact.lastName?.trim()) return false;
        if (!this.selectedContact.firstName?.trim()) return false;
        return true;
    }

} // close export of class
</script>

<style scoped>
.contact-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 280px), 1fr));
    gap: 15px;
    margin-top: 10px;
}

.add-card {
    cursor: pointer;
    transition: all .2s;
}

.add-card:hover {
    color: black !important;
}
</style>
