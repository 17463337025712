<template>
    <div class="electronic-prior-authorization-list-view">
        <Card>
            <b-row>
                <b-col>
                    <h4 class="card-subtitle">Electronic Prior Authorizations</h4>
                </b-col>
            </b-row>
            <div v-show="!patient">
                <b-row>
                    <b-col cols="2">
                        <b>Total:</b> {{epaTotal}}
                    </b-col>
                    <b-col v-for="statEnum in epaStatusList" :key="statEnum" cols="2">
                        <b>{{epaCodes[statEnum]}}:</b> {{countByStatus(statEnum)}}
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col>
                        Manufacturers
                        <b-form-select v-model="selectedManufacturer" :options="manufacturers"></b-form-select>
                    </b-col>
                    <b-col>
                        <EnumSelector v-model="selectedStatus"
                                      :enum-codes="epaCodes"
                                      clearable
                                      label="Status"></EnumSelector>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col>
                    <b-table id="epaTable"
                             ref="epaTable"
                             v-model="tableItems"
                             :fields="fields"
                             :filter="tableFilter"
                             :items="tableProvider"
                             api-url="PriorAuthorization/Electronic"
                             empty-filtered-text="No Electronic Prior Authorizations for this set."
                             empty-text="No Electronic Prior Authorizations"
                             no-provider-sorting
                             outlined
                             primary-key="id"
                             show-empty
                             sort-icon-left
                             @row-clicked="selectEpa">
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>
                        <template #cell(patient) v-if="patient">
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </Card>

        <ElectronicPriorAuthorizationModal ref="epa-modal-component"
                                           v-model="selectedEpa"
                                           @hidden="detailsHide"
                                           @show="modalIsShown=true"></ElectronicPriorAuthorizationModal>
    </div>
</template>

<script lang="ts">
    import {Component, Ref, VModel, Vue} from "vue-property-decorator";
    import Card from "@/components/Cards/Card.vue";
    import Axios, {AxiosRequestConfig} from 'axios';
    import {BTable, BvTableCtxObject} from "bootstrap-vue";
    import moment from "moment";
    import ElectronicPriorAuthorizationModal
        from "@/components/PriorAuthorization/ElectronicPriorAuthorizationModal.vue";
    import {ElectronicPriorAuthorization, ElectronicPriorAuthStatus} from "@/models/ElectronicPriorAuthorization";
    import {Patient} from "@/models/Patient";
    import EnumSelector from "@/components/EnumSelector.vue";

    @Component({
        components: {
            EnumSelector,
            Card,
            ElectronicPriorAuthorizationModal
        }
    })
    export default class ElectronicPriorAuthorizationListPage extends Vue {
        @Ref() private epaTable!: BTable;
        @VModel({default: null}) private patient!: Patient | null;
        // noinspection JSMismatchedCollectionQueryUpdate
        private tableItems: ElectronicPriorAuthorization[] = [];
        get fields(){
            var fields = [
            {key: "id"},
            {
                key: "patient",
                formatter: (p: Patient) => `${p?.lastName}, ${p?.firstName}\n${moment(p?.dateOfBirth).format("L")}`,
                tdClass: "text-center mw-10 pre-white",
                thClass: "text-center",
            },
            {key: "prescription.programName", label: "Program"},
            {key: "drugManufacturer", label: "Manufacturer"},
            {
                key: "rxId",
                formatter: (value: any, key: any, item: ElectronicPriorAuthorization) => `${item.storeId}-${item.rxNumber}-${item.rfNumber}`,
                tdClass: "text-center rx-id-col",
                thClass: "text-center",
            },
            {key: "status", formatter: this.statusFormatter, sortable: true},
            {key: "expectedResponse", formatter: this.expectedResponseFormatter, sortable: true},
            {key: "expiration", formatter: this.dateTimeFormatter, sortable: true},
            {key: "deadline", formatter: this.dateTimeFormatter, sortable: true},
        ];
        if(this.patient){
            fields.splice(fields.findIndex(f => f.key == "patient"), 1);
        }
        return fields;
        };
        private selectedEpa: ElectronicPriorAuthorization | null = null;
        private selectedStatus: string | null = null;
        private epaCodes = ElectronicPriorAuthStatus;
        private selectedManufacturer: string = "All";
        private interval!: NodeJS.Timeout;
        private modalIsShown = false;
        private statusToIgnore = [ElectronicPriorAuthStatus.CancelApproved, ElectronicPriorAuthStatus.Canceled, ElectronicPriorAuthStatus.Closed];

        get manufacturers() {
            let list = ["All"];
            this.tableItems.forEach(epa => list.push(epa.drugManufacturer));
            list = list.filter(this.onlyUnique);
            return list;
        }

        get tableFilter() {
            return {
                patientId: this.patient?.id,
                status: this.selectedStatus,
                manufacturer: this.selectedManufacturer,
            }
        }

        get epaStatusList(): Array<ElectronicPriorAuthStatus> {
            let res = new Array<ElectronicPriorAuthStatus>();
            for (const st in ElectronicPriorAuthStatus) {
                if (Number(st) >= 0 && !this.statusToIgnore.some(st2 => st2 == Number(st))) {
                    res.push(Number(st) as ElectronicPriorAuthStatus);
                }
            }
            return res;
        }

        get epaTotal() {
            return this.tableItems?.length ?? 0;
        }

        countByStatus(status: ElectronicPriorAuthStatus) {
            return this.tableItems?.filter(i => i.status == status).length ?? 0;
        }

        onlyUnique(value: any, index: any, self: any) {
            return self.indexOf(value) === index;
        }

        mounted() {
            this.refreshTable();
        }

        beforeDestroy() {
            console.log("Before unmount. Clear the interval", this.interval);
            clearInterval(this.interval);
        }

        statusFormatter(status: ElectronicPriorAuthStatus) {
            switch (status) {
                case ElectronicPriorAuthStatus.Initiated:
                    return "Initiated";
                case ElectronicPriorAuthStatus.QuestionsNeedAnswers:
                    return "Questions Need Answers";
                case ElectronicPriorAuthStatus.AnswersSubmitted:
                    return "Answers Submitted";
                case ElectronicPriorAuthStatus.Approved:
                    return "Approved";
                case ElectronicPriorAuthStatus.Denied:
                    return "Denied";
                case ElectronicPriorAuthStatus.Canceled:
                    return "Canceled";
                case ElectronicPriorAuthStatus.RejectedCanAppeal:
                    return "Rejected Can Appeal";
                case ElectronicPriorAuthStatus.InProcess:
                    return "In Process";
                case ElectronicPriorAuthStatus.CancelApproved:
                    return "Cancel Approved";
                case ElectronicPriorAuthStatus.CancelRejected:
                    return "Cancel Rejected";
                case ElectronicPriorAuthStatus.Closed:
                    return "Closed";
                case ElectronicPriorAuthStatus.PartiallyDenied:
                    return "Partially Denied";
                default:
                    return "Unknown";
            }
        }

        expectedResponseFormatter(d: any) {
            if (!d) return "";
            return moment(d).format("L");
        }

        dateTimeFormatter(d: any) {
            return moment(d).format("L LT");
        }

        tableProvider(ctx: BvTableCtxObject) {
            if (ctx.apiUrl == null) return null;
            const config: AxiosRequestConfig = {
                params: ctx.filter
            }
            return Axios.get<ElectronicPriorAuthorization[]>(ctx.apiUrl, config)
                .then(resp => {
                    let auths = resp.data;
                    auths.forEach(a => {
                        a.patient = new Patient(a.patient);
                    });
                    return auths;
                })
                .catch(err => {
                    console.error("Error while loading items for ingestion.", {err, response: err.response});
                    return [];
                });

        }

        selectEpa(item: any) {
            console.log("selectEpa");
            this.selectedEpa = item;
            if (this.selectedEpa) {
                this.$bvModal.show('electronic-prior-authorization-details-modal');
            }
        }

        refreshTable() {
            if (this.modalIsShown) return;

            if (this.interval) clearInterval(this.interval);

            const milliseconds = (h: number, m: number, s: number) => ((h * 60 + m) * 60 + s) * 1000;
            this.interval = setInterval(this.refreshTable, milliseconds(0, 10, 0));
            this.epaTable.refresh();
        }

        detailsHide() {
            this.selectedEpa = null;
            this.modalIsShown = false;
            this.refreshTable();
        }
    }
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    ::v-deep .mw-10 {
        max-width: 15em;
    }

    ::v-deep .pre-white {
        white-space: pre-line;
    }

    ::v-deep .rx-id-col {
        min-width: 10em;
    }
</style>
