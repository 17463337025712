
.btn.swap {
    margin-top: 28px;
    font-size: 1.4em;
    border-radius: 30px;
}

.patient-selector {
    gap: 12px;

    >div {
        width: 50%;
    }
}

.preview {
    gap: 15px;
    align-items: center;

    >i {
        font-size: 3em;
        margin: 0 -25px;
        z-index: 100;
    }

    >div {
        width: 50%;
    }
}
