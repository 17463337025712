
.action-btn {
    cursor: pointer;
}

.highlight-count {
    color: yellow !important;
}

.productivity-spinner {
    width: 1rem;
    height: 1rem;
}

.action-count-badge {
    position: absolute;
    top: 19px;
    left: 23px;
    border-radius: 20px;
    padding-top: 3px;
    font-size: .75em;
    width: 2em;
    height: 2em;
    text-align: center;
    font-weight: 600;
    background-color: #081c35;
    color: white;
    font-variant-numeric: tabular-nums;
    box-shadow: 0 0 2px rgba(white, .45);
    letter-spacing: .2px;

    &.danger {
        background-color: rgb(233, 0, 0);
    }
}

#user-stats {
    height: 6em;
}

::v-deep(.container-fluid) {
    display: grid !important;
    flex-wrap: nowrap !important;
    gap: 10px;
    grid-template-columns: 1fr 200px;
    align-items: center;
}

.actions {
    display: flex;
    gap: 10px;

    > * {
        flex-grow: 1;
    }

    .fas {
        font-size: 2em;
    }

    .dispense-errors,
    .notifications {
        position: relative;
    }
}

#notification-bell {
    background: transparent;
    border: none;
    color: white;
}

.stats {
    display: flex;
    flex-wrap: wrap;
    gap: 0 2px;

    div {
        border-radius: 7px;
        padding: 4px 8px;
        transition: all .2s;
        border: 1px solid transparent;
    }

    div:hover {
        background: rgba(white, .15);
        border-color: rgba(white, .25);
    }

    i,
    svg {
        margin-right: 2px;
    }
}

.btn-link {
    margin-top: 4px;
    margin-bottom: 4px;
}

@keyframes prominent-shake {
    0% {
        transform: rotate(0deg) scale(1);
    }

    10% {
        transform: rotate(20deg) scale(1.1);
        text-shadow: 0 0 5px white;
    }

    20% {
        transform: rotate(-20deg) scale(1.1);
        text-shadow: 0 0 5px white;
    }

    30% {
        transform: rotate(20deg) scale(1.1);
        text-shadow: 0 0 5px white;
    }

    40% {
        transform: rotate(-20deg) scale(1.1);
        text-shadow: 0 0 5px white;
    }

    50% {
        transform: rotate(10deg) scale(1.05);
        text-shadow: 0 0 2px white;
    }

    60% {
        transform: rotate(-10deg) scale(1.05);
        text-shadow: 0 0 2px white;
    }

    70% {
        transform: rotate(10deg) scale(1.05);
        text-shadow: 0 0 2px white;
    }

    80% {
        transform: rotate(-10deg) scale(1.05);
        text-shadow: 0 0 2px white;
    }

    90% {
        transform: rotate(5deg) scale(1);
    }

    100% {
        transform: rotate(0deg) scale(1);
    }
}

.shake {
    animation: prominent-shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
