<template>
    <b-card
        :border-variant="value && value.statusStyle"
        :header-bg-variant="value && value.statusStyle"
        :class="`bg-${value.statusStyle}`"
        header-text-variant="white"
    >
        <b-card-text>
            <b-row>
                <b-col><b>{{value.fullPatientName}}</b></b-col>
            </b-row>
            <b-row>
                <b-col>Created: <i>{{value.created | formatDate('L LT')}}</i></b-col>
            </b-row>
            <b-row>
                <b-col>Updated: <i>{{value.updated | formatDate('L LT')}}</i></b-col>
            </b-row>
            <b-row>
                <b-col>{{value.storeID}}-{{value.rxNumber}}-{{value.rfNumber}}</b-col>
            </b-row>
            <b-row>
                <b-col>{{value.errorTypeDesc}}</b-col>
            </b-row>
        </b-card-text>
        <!-- Buttons -->
        <div>
            <b-button @click="clickedDetails" :variant="value.statusStyle">
                Details
            </b-button>
        </div>

    </b-card>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DispenseError } from '@/models/DispenseError';
    import { Note } from '@/models/Note';
    import { EntityType } from "@/models/EntityType";

    @Component({
        name: "DispensingErrorCardComponent"
    })
    export default class DispensingErrorCardComponent extends Vue {
        @Prop() private value!: DispenseError;
        @Prop({default: 'dispenseAlertConfirmation'}) private detailsModalId!: string;

        private action: string = '';
        private typeOfErrors = DispenseError.DispenseErrorTypeEnum;
        private statusEnum = DispenseError.DispenseErrorStatusEnum;

        clickedDetails(){
            this.$emit("onClickDetails", this.value.id)
        }
    }

</script>
<style scoped>
    .bg-danger{
        background-color: rgba(255, 54, 54, 0.5) !important;
    }
    .bg-warning{
        background-color: #ffda9f !important;
    }
    .bg-success{
        background-color: #b7e8b5 !important;
    }
    .bg-info{
        background-color: #c3e6ff !important;
    }
</style>
