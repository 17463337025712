
button {
    border: 0;
    background: transparent;
    
    &.hasLabel {
        background: rgb(248, 248, 248);
        border: 1px solid rgb(211, 211, 211);
        border-radius: 4px;

        &:hover {
            transition: all .2s;
            background: rgb(240, 240, 240);
            color: black !important;
        }
    }
}

.search-help {
    line-height: 1.25;

    ::v-deep(.popover-body > *) {
        margin-bottom: 0;
    }

    ::v-deep(ul) {
        margin-bottom: 5px;
        padding-left: 20px;
    }
}
