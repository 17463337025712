<template>
    <card title="Store Information">
        <b-form-row class="mb-2">
            <b-col>
                <SearchComponent v-model="store" :id="store && +store.id" :disabled="!!(store && store.id) || newMode"
                    search-u-r-l="/Store" :prepopulate="true" @input="onStoreUpdate" />
            </b-col>
        </b-form-row>
        <b-form-group :disabled="disableFields">

            <b-form-row>
                <b-col>
                    <fg-input v-model="store.name" label="Store Name" required />
                </b-col>
                <b-col cols="5">
                    <fg-input v-model="store.phoneNumber" label="Phone" />
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col>
                    <AddressFormFields v-model="store">
                        <template #row-1>
                            <b-col cols="5">
                                <fg-input v-model="store.faxNumber" label="Fax" />
                            </b-col>
                        </template>
                        <template #row-2>
                            <b-col>
                                <fg-input v-model="store.emailAddress" label="Email" />
                            </b-col>
                        </template>
                        <template #row-3>
                            <b-col>
                                <fg-input v-model="store.webAddress" label="Web" />
                            </b-col>
                        </template>
                    </AddressFormFields>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col>
                    <fg-input v-model="store.deaNumber" label="DEA Number" />
                </b-col>
                <b-col>
                    <fg-input v-model="store.npiNumber" label="NPI" />
                </b-col>
                <b-col>
                    <fg-input v-model="store.mailNPINumber" label="Mail NPI" />
                </b-col>
                <b-col>
                    <fg-input v-model="store.pidNumber" label="PID" />
                </b-col>
                <b-col>
                    <fg-input v-model="store.ncpdp" label="NCPDP" />
                </b-col>
            </b-form-row>

            <hr>

            <b-form-row :class="{ 'alt-mode': store.useAlternatePrinterEndpoint }">
                <b-col class="printer">
                    <fg-input v-model="store.defaultPrinter" label="Printer" :disabled="disablePrinter" />
                    <fg-input v-model="store.zplPrinterQueue" label="ZPL Queue (optional)" :disabled="disablePrinter" />
                </b-col>
                <b-col class="alt-printer">
                    <fg-input v-model="store.alternatePrinterEndpoint" label="Alternate Printer Endpoint"
                        :disabled="disablePrinter" />
                    <b-form-checkbox v-model="store.useAlternatePrinterEndpoint">Use alternate printer
                        endpoint</b-form-checkbox>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col>
                    <fg-input v-model="store.parataQueue" label="Parata Queue" />
                </b-col>
            </b-form-row>
            <b-form-row v-if="hasInventoryLocations">
                <b-col>
                    <SearchComponent :id="store.defaultFillingLocation?.id" v-model="store.defaultFillingLocation"
                        :disabled="disableFields" search-u-r-l="/inventory/inventory-locations" :prepopulate="true"
                        label="Default Inventory Filling Location" clearable />
                </b-col>
            </b-form-row>

            <hr>
            <b-form-row>
                <b-col>
                    <fg-input v-model="store.fedIDNumber" label="Fed ID" />
                </b-col>
                <b-col>
                    <fg-input v-model="store.storeNumber" label="Store Number" />
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col>
                    <fg-input v-model="store.storeID" label="Store ID" />
                </b-col>
                <b-col>
                    <label>Allow Shipping To:</label>
                    <multiselect v-model="store.allowedStates" :options="stateOptions" :multiple="true"
                        :close-on-select="false" :clear-on-select="false" group-label="label" group-values="states"
                        :group-select="true">
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                                {{ values.length }} states selected
                            </span>
                        </template>
                    </multiselect>
                </b-col>
            </b-form-row>

            <hr>

            <b-form-row>
                <b-col>
                    <fg-input v-model="store.brandingName" label="Branding Name" />
                </b-col>
                <b-col>
                    <fg-input v-model="store.brandingPhone" label="Branding Phone" />
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col>
                    <label>Branding Image</label><br>
                    <img v-if="brandingImageSrc" :src="brandingImageSrc" class="branding-image" />
                    <b-button variant="info" v-b-modal:branding-modal>Upload Branding Image...</b-button>
                    <branding-modal id="branding-modal" v-model="store" />
                </b-col>
            </b-form-row>

            <hr>

            <b-row>
                <b-col>
                    <strong>Text Message Templates</strong>
                    <TextMessageTemplatesField v-model="store.textMessageTemplates" :disabled="disableFields"
                        :hide-templates="['passwordReset', 'awaitingInsurance', 'welcome']" :preview-data="{
                            PharmacyName: this.store.brandingName,
                            PharmacyPhone: this.store.brandingPhone,
                            PharmacyFax: this.store.faxNumber,
                            PharmacyNPI: this.store.npiNumber,
                            PharmacyNCPDP: this.store.ncpdp
                        }" />
                </b-col>
            </b-row>

            <hr>

            <b-row>
                <b-col>
                    <label for="inventory-par-level-recipients">Inventory PAR Level Alert Recipients</label>
                    <multiselect :disabled="disableFields" id="inventory-par-level-recipients"
                        v-model="store.parLevelAlertRecipients" :options="users.map(u => u.userName)" :multiple="true"
                        :close-on-select="false" :clear-on-select="false" />
                </b-col>
                <b-col>

                </b-col>
            </b-row>

            <hr>

            <b-row>
                <b-col>
                    <b-form-row>
                        <b-col class="d-flex my-3" style="gap: 10px;">
                            <b-form-checkbox v-model="store.active">Active</b-form-checkbox>
                            <b-form-checkbox v-model="store.allowOutOfState">Allow Out Of State
                                Shipments?</b-form-checkbox>
                            <b-form-checkbox v-model="store.disableReadyForPickupTexts">
                                Disable Ready for Pickup Texts
                            </b-form-checkbox>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col>
                            <b-form-group label="Billing Flags">
                                <b-col>
                                    <b-form-checkbox-group id="checkbox-group-1" v-model="store.selectedCapabilities"
                                        :options="capabilitiesOptions" />
                                </b-col>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </b-col>
                <b-col v-if="!!store.id">
                    <fg-input
                        :value="store.pharmacistOfRecord ? `${store.pharmacistOfRecord?.userName} (${store.pharmacistOfRecord?.initials})` : ''"
                        label="Pharmacist of Record" disabled />
                    <b-button @click="makeMyselfPharmacistOfRecord" :disabled="!$user.isPharmacist">
                        Make Myself Pharmacist of Record
                    </b-button>
                </b-col>
            </b-row>

        </b-form-group>

        <NewClearSaveButtons :titles="buttonTitles" :disable="disableFields" :disableSave="!validForm"
            :disableClear="disableFields" @newClicked="createStore" @clearClicked="showConfirmationModal"
            @saveClicked="submitInformation" />
    </card>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';

import AddressFormFields from '@/components/AddressFormFields.vue';
import NewClearSaveButtons, { NewClearSaveTitles } from '@/components/NewClearSaveButtons.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import { Lock, LockHandler } from '@/mixins/LockHandler';
import DisableAutocompleteMixin from '@/mixins/DisableAutocompleteMixin';
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { Store } from '@/models/Store';
import BrandingModal from "@/components/Store/BrandingModal.vue";
import TextMessageTemplatesField from "@/components/Configuration/TextMessageTemplatesField.vue";

import Multiselect from 'vue-multiselect';
import { ValidValues } from '@/data/ValidValues';
import { User } from '@/models/User';

@Component({
    name: "StoreDetailsPage",
    components: {
        BrandingModal,
        AddressFormFields,
        NewClearSaveButtons,
        SearchComponent,
        Multiselect,
        TextMessageTemplatesField
    },
})
export default class StoreDetailsPage extends Mixins(LockHandler, DisableAutocompleteMixin) {
    private store: Store = new Store();
    private storeLock: Lock | null = null;
    private newMode = false;
    protected capabilitiesOptions = Store.flagOptions;
    private stateOptions = [{ label: "All States", states: ValidValues.addressStates }];
    private brandingImageSrc: string | null = "";

    get routeId(): number {
        let res = parseInt(this.$route.params.id);
        if (isNaN(res)) return 0;
        return res;
    }

    get disableFields(): boolean {
        if (this.newMode) return false;

        const haveLock = !!this.storeLock;
        const storeGood = !!(this.store && this.store.id);
        return !haveLock || !storeGood;
    }

    get disablePrinter(): boolean {
        return !this.isAdmin;
    }

    get isAdmin(): boolean {
        return this.$user?.isAdmin;
    }

    protected buttonTitles: NewClearSaveTitles = {
        new: "New Store",
        clear: "Clear",
        save: "Save Changes",
        cancel: "",
    };

    created() {
        if (this.routeId) {
            this.newMode = false;
            this.fetchStoreById(this.routeId);
        }
        this.loadUsers();
        this.loadHasInventoryLocations();
    }

    onStoreUpdate(store: Store) {
        this.fetchStore(store);
    }

    async showConfirmationModal() {
        try {
            const value = await this.$bvModal.msgBoxConfirm('Are you sure you want to clear?', {
                title: 'Confirm',
                okVariant: 'danger',
                centered: true,
            });

            if (!value) return;
            this.clearForm();
        } catch (err) {
            console.log("Error caught in showConfirmationModal()");
            console.error(err);
        }
    }

    clearForm() {
        if (this.storeLock && this.storeLock.refreshURL) {
            this.releaseLockAtURL(this.storeLock.refreshURL);
            this.storeLock = null;
        }
        this.store = new Store();
        this.brandingImageSrc = null;
        this.newMode = false;
    }

    fetchStoreById(id: number) {
        const store = new Store();
        store.id = id;
        this.fetchStore(store);
    }

    async fetchStore(value: Store) {
        try {
            const result = await this.addLockWithURL(`/Store/${value.id}/lock`, 60000);
            this.storeLock = result;
        } catch (error) {
            const err = error as AxiosError;
            if (err.response?.status == 418) {
                // already locked
                const lockData = err.response.data;
                const lockedBy = lockData.lockedBy;
                const expires = lockData.expires;
                this.$bvModal.msgBoxOk(`The Store is locked by ${lockedBy} until ${expires}.`);
            }
        }

        try {
            const response = await axios.get(`/Store/${value.id}`, { headers: { 'Cache-Control': 'no-cache' } });
            this.store = new Store(response.data);
        } catch (error) {
            console.warn(error);
        }

        try {
            this.brandingImageSrc = await this.loadBrandingImage();
        } catch {
            this.brandingImageSrc = null;
        }
    }

    createStore() {
        this.newMode = true;
        this.store = new Store();
    }

    @Watch('$route.params.id')
    onParamChange(value: string, oldValue: string) {
        if (value != oldValue) {
            this.fetchStoreById(Number(value));
        }
    }

    @Watch('store')
    onStoreChanged(value: Store | null, _oldValue: Store | null) {
        if (!value) return;
        if (value.id && value.id != -1 && value.id != this.routeId) {
            this.$router.push({ name: 'Store', params: { id: value.id.toString() } });
        }
    }

    async submitInformation() {
        try {
            const response = await axios.post('/Store/', this.store);
            this.store = new Store(response.data);
            this.$notification(NotificationOptions.successSaveNotificationPreset("Store"));
        } catch (error) {
            console.warn(error);
            this.$notification(NotificationOptions.errorSaveNotificationPreset("Store", error as AxiosError));
        }
    }

    get validForm(): boolean {
        return !!(this.store?.name);
    }

    async makeMyselfPharmacistOfRecord() {
        try {
            const response = await axios.post(`/Store/${this.store.id}/MakeSelfPharmacistOfRecord`)
            this.store = new Store(response.data);
            this.$notification(NotificationOptions.notificationOptionsPreset("You have been made the Pharmacist of Record", NotificationOptions.NotificationTypes.success));
        } catch (error) {
            console.warn(error);
            this.$notification(NotificationOptions.notificationOptionsPreset("There was an error making you the Pharmacist of Record", NotificationOptions.NotificationTypes.danger));
        }
    }

    async loadBrandingImage(): Promise<string> {
        if (!this.store?.brandingImageId) return Promise.reject("no branding image id");

        try {
            const blob = await axios.get(`/api/Image/${this.store.brandingImageId}`, { responseType: "blob" });
            const objectUrl = URL.createObjectURL(blob.data);
            return objectUrl;
        } catch (err) {
            return Promise.reject("failed to load branding image");
        }
    }

    private users: User[] = [];

    async loadUsers() {
        try {
            const resp = await axios.get<User[]>("/user");
            this.users = resp.data;
        } catch {

        }
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await axios.get<boolean>("/inventory/has-inventory-locations")).data;
        } catch {
            //ignore
        }
    }
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.form-row {

    .alt-printer,
    .printer {
        transition: all .3s;
    }

    .alt-printer .form-group {
        opacity: .5;
    }
}

.form-row.alt-mode {
    .printer {
        opacity: .5;
    }

    .alt-printer .form-group {
        opacity: 1;
    }
}

.branding-image {
    max-width: 250px;
    display: block;
}

.card .card {
    box-shadow: none;
}
</style>
