
::v-deep(.dropdown-item.active) {
    background-color: rgba(blue, .05);
    font-weight: bold;
}

@keyframes fadeIn {
    from {
        opacity: 1;
        zoom: 1;
    }
}

.printers-loading {
    font-size: 3.5em;
    gap: 10px;
    height: 100px;
}

.printers-loading > i {
    animation: fadeIn 1.5s infinite;
    opacity: 0;
    width: 60px;
    zoom: .85;
    animation-delay: 1.5s;
}

.printers-loading > i:nth-child(1) {
    animation-delay: .5s;
}

.printers-loading > i:nth-child(2) {
    animation-delay: 1s;
}

.printers-loading > i:last-child {
    animation: none;
    opacity: 1;
    font-size: 1.3em;
}

.print-actions {
    position: sticky;
    background: rgba(255, 255, 255, .9);
    top: 0;
    z-index: 100;
    backdrop-filter: blur(5px);
}
