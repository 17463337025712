//Bootstrap Vue datepicker and timepicker layout fixes
.b-form-btn-label-control {
    &.b-form-datepicker,
    &.b-form-timepicker {
        align-items: center;
        height: 40px;

        > button {
            margin: 0;
            align-self: stretch;
            border-radius: 15px 0 0 15px;
            margin-right: 5px;
            padding: 0 10px;
        }

        label.form-control {
            height: auto !important;
            min-height: auto !important;
            //font-size: .98em;
            padding: 0;
        }

        .b-form-date-controls button,
        .b-calendar-nav button {
            color: black;
        }
    }
}

//Multiselect styles
.multiselect__tag,
.multiselect__option--highlight,
.multiselect__option--highlight:after {
    background: #5897fb;
}

.multiselect__tag-icon:after {
    color: white;
}


.table-icon-actions {
    font-size: 1.2em;
    display: flex;
    gap: 3px;
    line-height: 1.25;

    a {
        padding: 3px 5px;
    }
}

input[readonly] {
    background: rgb(248, 248, 248) !important;
    color: rgb(10, 10, 10) !important;
    border-color: rgb(230, 230, 230) !important;
}