<template>
    <div id="print-queue-container">
        <card title="Active Prescription Queue">
            <div class="filter-grid mb-3">
                <SearchComponent v-model="storeFilter" :label="`Store`" :search-u-r-l="`/Store`" :prepopulate="true"
                    :disabled="isLoading" clearable />

                <SearchComponent v-model="programFilter" label="Program" search-u-r-l="/Program"
                    :params="{ active: true }" clearable prepopulate :disabled="isLoading" />

                <div>
                    <label>Statuses:</label>
                    <multiselect v-model="statuses" :options="statusCodeOptions" multiple label="name" track-by="value"
                        :limit="2" :disabled="isLoading" />
                </div>

                <div>
                    <label>SubStatuses:</label>
                    <multiselect v-model="substatuses" :options="subStatusCodeOptions" multiple label="name"
                        track-by="value" :limit="2" :disabled="isLoading" />
                </div>

                <div>
                    <label>Fill Date:</label>
                    <div class="d-flex" style="gap: 15px;">
                        <el-date-picker size="sm" v-model="startDate" today-button reset-button :disabled="isLoading" />
                        <el-date-picker size="sm" v-model="endDate" today-button reset-button :disabled="isLoading" />
                    </div>

                </div>
            </div>

            <PrescriptionsListComponent :defaultSort="sortBy" :prescriptions="prescriptions" showPatientName
                alwaysShowVoid defaultDesc :showSelectCheckox="false" :isLoading="isLoading" :actionButtons="['edit']"
                :additional-fields="additionalFields" :manualSort="['productNameShort', 'patientName', 'fillDate', 'storeName',
                    'rxId', 'dispensed', 'programName', 'patientPay']"
                @sort-changed="handleSort" />

            <BottomPagerBar v-if="!isLoading" v-model="pageNumber" :total-rows="totalRows" :per-page="pageSize"
                show-total show-page-size-selector item-name="prescription" @change-page-size="pageSize = $event" />

        </card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { DatePicker } from "element-ui";
import SearchComponent from '@/components/SearchComponent.vue';
import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
import { Prescription, RefillStatusCode, OnHoldSubstatusCode } from '@/models/Prescription';
import { Store } from '@/models/Store';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import { Program } from "@/models/Program";
import BottomPagerBar from '@/components/BottomPagerBar.vue';

@Component({
    name: "ActiveQueue",
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
        PrescriptionsListComponent,
        Checkbox,
        Multiselect,
        BottomPagerBar
    },
})
export default class ActiveQueue extends Vue {
    protected prescriptions: Array<Prescription> = [];
    private storeFilter: Store = new Store();
    private programFilter: Program = new Program();
    protected startDate?: string = "";
    protected endDate?: string = moment().toISOString();

    protected isLoading: boolean = false;

    protected totalRows: number = 0;
    protected pageNumber: number = 1;
    protected pageSize: number = 50;
    protected statuses: Array<any> = [];
    protected substatuses: Array<any> = [];

    private sortBy: string = "fillDate";
    private sortDirection: string = "D";

    private additionalFields = [
        { index: 3, field: { key: 'patientPhone', formatter: this.phoneFormatter } },
        { index: 4, field: { key: 'patientCell', formatter: this.phoneFormatter } },
        { index: 5, field: { key: 'addressState', label: 'Patient State' } },
    ];

    phoneFormatter(value: string, key: string, item: any) {
        if (!value) return "";
        value = value.replace("+1", "");
        if (value.length >= 10) {
            return value.substr(0, 3) + '-' +
                value.substr(3, 3) + '-' +
                value.substr(6, 4);
        }
        return value;
    }
    get startRowIndex() {
        return this.pageNumber * this.pageSize - this.pageSize;
    }

    get endRowIndex() {
        return this.startRowIndex + this.prescriptions.length;
    }

    get statusCodeOptions() {
        return Object.keys(RefillStatusCode).filter(key => isNaN(Number(key)))
            .map(key => ({
                name: key,
                value: (RefillStatusCode as any)[key]
            }))
    }

    get subStatusCodeOptions() {
        return Object.keys(OnHoldSubstatusCode).filter(key => isNaN(Number(key)))
            .map(key => ({
                name: key,
                value: (OnHoldSubstatusCode as any)[key]
            }))
    }

    mounted() {
        this.load();
    }

    @Watch("endDate")
    handleStartDate() {
        if (this.endDate && !this.startDate)
            this.startDate = this.endDate;
        if (!this.endDate)
            this.startDate = "";
    }

    @Watch("statuses")
    @Watch("substatuses")
    @Watch("storeFilter")
    @Watch("startDate")
    @Watch("endDate")
    @Watch("programFilter")
    resetPageNumber() {
        this.pageNumber = 1;
        this.load();
    }

    @Watch("pageNumber")
    load() {
        this.isLoading = true;

        const params = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            storeId: this.storeFilter?.id,
            statuses: this.statuses.map(s => s.value).join(","),
            substatuses: this.substatuses.map(s => s.value).join(","),
            startDate: this.startDate,
            endDate: this.endDate,
            programId: this.programFilter?.id,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });


        this.$http.get<any>('api/Prescription/active-queue', { params: params })
            .then(response => {
                this.prescriptions = response.data.scripts;
                this.totalRows = response.data.totalRows;

            })
            .catch(error => {
                console.error("Error while loading active queue.", { error, response: error?.response });
                this.$notification(NotificationOptions.error(error));
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    handleSort(event: any) {
        this.sortBy = event.sortBy;
        this.sortDirection = event.sortDesc ? "D" : "A";
        this.pageNumber = 1;
        this.load();
    }
}

</script>

<style scoped>
.filter-grid {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 430px), 1fr));


}

@media (min-width: 1600px) {
    .filter-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
