
/* Tab content animation */
.tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .tab-pane {
        display: block;
        animation: fadeIn 0.5s;
        width: 100%;
    }
}

/**
    Extra niceties. Display error tabs and disable navigation unvisited tabs
   */
.wizard-navigation .nav-link {
    &.active,
    &.checked {
        cursor: pointer;
    }
}

.disabled-wizard-link {
    cursor: not-allowed;
}
