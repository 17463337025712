<template>
    <div class="flex-embed">

        <div v-if="errorMessage && errorMessage.length > 0" id="error-display">
            <b-card :title="errorMessage" class="text-center"/>
        </div>
        <div v-else class="accordion" role="tablist"
             v-for="(patient, idx) in patients" :key="patient.id">
            <b-card no-body>
                <b-card-header header-tag="header" class="p-2 text-center" role="tab">
                    <b-button v-b-toggle="[`accordion-${patient.id}`]" variant="info">
                        {{patient.id}} - {{patient.displayNamesForPerson()}} -
                        {{patient.dateOfBirth | formatDate("MM-DD-YYYY", false)}}
                    </b-button>
                </b-card-header>

                <b-collapse :id="`accordion-${patient.id}`" accordion="my-accordion" role="tabpanel">
                    <b-container fluid class="mb-2">
                        <b-row v-if="showAlert(patient)">
                            <b-col>
                                <b-alert show variant="danger">
                                    {{patient.alert}}
                                </b-alert>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <DoctorFollowUpCasesAlertComponent :patient-id="+patient.id"
                                                                   :case-modal-id="`followup-case-form-${patient.id}`"
                                                                   class="full-size-modal"/>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <PatientDemographicsComponent v-model="patients[idx]"/>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button variant="info"
                                          :to="{name:'PatientDashboardFlex', params:{id:+patient.id}}">
                                    To Dashboard
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-collapse>
            </b-card>
        </div>

    </div>
</template>

<script lang="ts">
    import { Component, Vue } from "vue-property-decorator";
    import { Patient } from '@/models/Patient';
    import axios from "axios";
    import PatientDemographicsComponent from "@/components/Patient/PatientDemographicsComponent.vue";
    import DoctorFollowUpCasesAlertComponent from "@/components/Doctor/DoctorFollowUpCasesAlertComponent.vue";

    @Component({
        name: "FlexEmbed",
        components: { DoctorFollowUpCasesAlertComponent, PatientDemographicsComponent }
    })
    export default class FlexEmbed extends Vue {
        protected patients: Patient[] = [];
        private errorMessage: string = "";

        get routePhoneNumber(): string {
            return this.$route.params?.phoneNumber;
        }

        showAlert(patient: Patient) {
            return patient?.alert?.length > 0 ?? false;
        }

        mounted() {
            this.fetchPatient();
        }

        async fetchPatient() {
            try {
                let patients = await axios.get<Patient[]>(`/Patient/flex/${this.routePhoneNumber}`);
                if (patients.data.length > 0) {
                    this.patients = patients.data.map(p => new Patient(p));
                    const firstId = this.patients.length > 0 ? this.patients[0].id : 0;
                    if (firstId) {
                        this.errorMessage = "";
                        this.$nextTick(() => {
                            this.$root.$emit('bv::toggle::collapse', `accordion-${firstId}`);
                        });
                    }
                }
            } catch (err) {
                this.errorMessage = err.response.data;
            }
        }
    }
</script>

<style scoped>
    :deep(.modal-dialog) {
        max-width: 95%;
    }

</style>
