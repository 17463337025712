
::v-deep(.drug) {
    width: 300px;
}

.action-bar {
    position: sticky;
    bottom: 0;
    background: rgba(white, .85);
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 10px;
    backdrop-filter: blur(5px);
}
