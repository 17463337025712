
.alert-legend {
    font-size: 1.5em;
    position: sticky;
    top: 0;
    text-align: center;

    i {
        width: 1.8em;
    }

    .cursor-pointer {
        cursor: pointer;
    }
}

.alerts i {
    background: white;
    color: black;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin: -10px;
    margin-right: 10px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .25);
    text-align: center;
    font-size: 1.2em !important;
    line-height: 2;
}

.alerts .alert-danger i {
    color: #ff5050;
}

.alerts .alert-info i {
    color: #46b3ff;
}

.alerts .alert-warning i {
    color: #ffbc50;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
