<template>
    <Card title="Doctor Dashboard">
        <b-row>
            <b-col cols="6">
                <b-form-group>
                    <label>Search By Doctor:</label>
                    <HelpPopup>
                        <p>Search using any of the following formats:</p>
                        <ul>
                            <li>NPI Number: "1234"</li>
                            <li>Last Name: "Jameson"</li>
                            <li>First Name: "John"</li>
                            <li>Phone Number: "+1555"</li>
                            <li>Fax Number: "+1855"</li>
                        </ul>
                    </HelpPopup>
                    <b-input v-model.lazy="searchTerm"
                             placeholder="NPI/Name/Phone Number/Fax Number"
                             @blur="updateSearchValue"
                             @keyup.enter="updateSearchValue"></b-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="From Date">
                    <el-date-picker v-model="fromDate" format="MM/dd/yyyy" type="date" />
                </b-form-group>
            </b-col>
            <b-col>

                <b-form-group label="To Date">
                    <el-date-picker v-model="toDate" format="MM/dd/yyyy" type="date" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table id="scripts-by-doctor" ref="scriptTable" :fields="fields" :filter="tableFilter"
                    :items="ctxProvider" :tbody-tr-class="colorCodeTable" api-url="/Prescription/ByPrescriber"
                    no-provider-sorting show-empty sort-icon-left>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(rxId)="data">
                        <span v-if="isRefillable(data.item)">
                            {{ data.item.rxId }}
                        </span>
                        <b v-else v-b-tooltip.hover :title="statusName(data.item)" style="cursor: pointer">
                            {{ data.item.rxId }}
                        </b>
                    </template>
                    <template #cell(edit)="data">
                        <router-link :to="rxDetailsRoute(data.item)">
                            <b-icon v-b-tooltip.hover icon="pencil-square" title="Edit"></b-icon>
                        </router-link>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </Card>
</template>

<script lang="ts">

    import {Component, Vue} from "vue-property-decorator";
    import Axios, {AxiosRequestConfig, CancelTokenSource} from "axios";
    import {BvTableCtxObject} from "bootstrap-vue";
    import {Prescription, RefillStatusCode} from '@/models/Prescription';
    import moment from "moment";
    import {Patient} from "@/models/Patient";
    import {Prescriber} from "@/models/Prescriber";
    import {DatePicker} from "element-ui";
    import numeral from "numeral";
    import HelpPopup from "@/components/HelpPopup.vue";

    @Component({
        name: "DoctorDashboard",
        components: {
            [DatePicker.name]: DatePicker,
            HelpPopup
        },
    })
    export default class DoctorDashboard extends Vue {
        private searchTerm = "";
        private searchValue = "";
        private toDate: any = moment().toDate();
        private fromDate: any = moment().subtract(7, "days").toDate();
        private cancelTokenSource?: CancelTokenSource;
        private fields = [
            {key: "productNameShort", label: "Drug", sortable: true},
            {key: "prescriber", formatter: this.prescriberFormatter, sortable: true,},
            {key: "patient", label: "Patient", formatter: this.patientFormatter, sortable: true, class: "patient-col"},
            {key: "storeName", label: "Store", sortable: true},
            {key: "rxId", sortable: true},
            {key: "quantity", sortable: true},
            {key: "writtenDate", formatter: (d: any) => moment(d).format("L"), sortable: true},
            {key: "fillDate", formatter: (d: any) => moment(d).format("L"), sortable: true,},
            {key: "patientPay", formatter: (d: number) => `$${d?.toFixed(2) ?? '0.00'}`, sortable: true},
            {key: "programName", label: "Program", sortable: true},
            {key: "state"},
            {key: "orderStatus", label: "Order Status",},
            {key: "edit", label: ""},
        ];

    get tableFilter() {
        this.setURIParams();
        return {
            searchTerm: this.searchValue,
            fromDate: this.fromDate,
            toDate: this.toDate,
        };
    }

    mounted() {
        this.getURIParams();
    }

    getURIParams() {
        if (this.routeDoctorSearch) {
            this.searchTerm = this.routeDoctorSearch;
            this.updateSearchValue();
        }
        if (this.routeFromDate) this.fromDate = this.routeFromDate;
        if (this.routeToDate) this.toDate = this.routeToDate;
    }

    setURIParams() {
        if (!this.searchValue) return;

        let query = this.$route.query;
        let strToDate = moment(this.toDate).format('YYYY-MM-DD');
        let strFromDate = moment(this.fromDate).format('YYYY-MM-DD');
        if (query.doctorSearch != this.searchValue || query.fromDate != strFromDate || query.toDate != strToDate)
            this.$router.replace({
                name: 'DoctorDashboard', query: {
                    doctorSearch: this.searchValue, fromDate: strFromDate, toDate: strToDate,
                }
            })
    }

    get routeDoctorSearch(): string {
        return (this.$route?.query?.doctorSearch as string) || '';
    }

    get routeFromDate(): Date | null {
        let strDate = this.$route?.query?.fromDate as string;
        if (strDate)
            return moment(strDate, 'YYYY-MM-DD').toDate();
        else return null;
    }

    get routeToDate(): Date | null {
        let strDate = this.$route?.query?.toDate as string;
        if (strDate)
            return moment(strDate, 'YYYY-MM-DD').toDate();
        else return null;
    }

    prescriberFormatter(dr: Prescriber): string {
        dr = new Prescriber(dr);
        return dr.displayNamesForPerson();
    }

    patientFormatter(pt: Patient): string {
        pt = Object.assign(new Patient(), pt);
        return pt.displayNamesForPerson();
    }

    dateFormatter(d: any): string {
        if (d == null) return "";
        return moment(d).format("L");
    }

    moneyFormatter(d: number) {
        if (d == null) return "";
        return numeral(d).format("$0,0.00");
    }

    updateSearchValue() {
        this.searchValue = this.searchTerm
    }

    ctxProvider(ctx: BvTableCtxObject, cb: Function) {
        if (ctx.apiUrl == null) return null;

        if (typeof this.cancelTokenSource != typeof undefined) {
            this.cancelTokenSource?.cancel("Operation canceled due to new request.");
        }
        this.cancelTokenSource = Axios.CancelToken.source();

        const uri = ctx.apiUrl;
        const config: AxiosRequestConfig = {
            params: ctx.filter,
            cancelToken: this.cancelTokenSource.token
        };

        Axios.get<Prescription[]>(uri, config)
            .then(resp => cb(resp.data.map(rx => new Prescription(rx))))
            .catch(() => {
                cb([])
            })
            .finally(() => {
                this.cancelTokenSource = undefined;
            });
        return null;
    }

    rxDetailsRoute(item: Prescription): any {
        return {
            name: "PrescriptionDetails",
            params: {
                storeID: item.storeID,
                rxNumber: item.rxNumber,
                rfNumber: item.rfNumber
            }
        };
    }

    colorCodeTable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        if (rx.status == RefillStatusCode.TransferOut)
            return 'table-info';
        else if (rx.status == RefillStatusCode.Void)
            return 'table-dark';
        else if (!rx.isRefillableStatus)
            return 'table-danger';
    }

    isRefillable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.isRefillableStatus;
    }

    statusName(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.statusName;
    }
}

</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
::v-deep .patient-col {
    max-width: 20rem;
}
</style>
