<template>
    <component :is="modalPresentation ? 'span' : 'div'" v-if="modalPresentation || hasImages">
        <b-button v-if="modalPresentation" @click="showModal" variant="outline-primary" size="sm"
            :disabled="!hasImages">
            Show As Filled Images
        </b-button>

        <component :is="modalPresentation ? 'b-modal' : 'div'" :id="modalId" title="As Filled Images" size="xl" ok-only
            ok-title="Close" v-if="hasImages">

            <div v-if="hasImages">
                <h5 v-if="!modalPresentation && !hideTitle">As Filled</h5>

                <div>
                    <Viewer class="viewer" :options="viewerOptions()" :images="imageUrls">
                        <template #default="scope">
                            <img v-for="src in scope.images" :src="src" :key="src" :alt="src" class="d-none">
                        </template>
                    </Viewer>
                </div>
            </div>

        </component>
    </component>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Axios from 'axios';
import 'viewerjs/dist/viewer.css';
import { component as Viewer } from 'v-viewer';

@Component({
    name: "FilledImagesComponent",
    components: {
        Viewer
    }
})
export default class FilledImagesComponent extends Vue {

    @Prop() private rxId!: string;
    @Prop({ default: false }) private modalPresentation!: boolean;
    @Prop({ default: false }) private hideTitle!: boolean;

    private imageIds: Array<number> = [];
    private imageUrls: Array<string> = [];

    viewerOptions() {
        return {
            inline: true,
            debug: true,
            button: false,
            title: false,
            transition: false,
            navbar: true,
            toolbar: {
                zoomIn: 1,
                zoomOut: 1,
                reset: 1,
                prev: 1,
                next: 1,
                rotateLeft: 1,
                rotateRight: 1,
                flipHorizontal: 1,
                flipVertical: 1,
                oneToOne: 0,
                play: 0
            }
        };
    }

    @Watch('rxId')
    handleRxIdChange() {
        this.getFilledImageIds();
    }

    created() {
        this.getFilledImageIds();
    }

    async getFilledImageIds() {
        this.imageIds = (await Axios.get<Array<number>>(`/Prescription/FilledImages/${this.rxId}`)).data;
        if (!this.modalPresentation) this.getFilledImageUrls();
    }

    async getFilledImageUrls() {
        const imageBlobPromises = this.imageIds.map(async i => {
            const blob = await Axios.get(`/api/Image/${i}`, { responseType: "blob" });
            return URL.createObjectURL(blob.data);
        })

        this.imageUrls = await Promise.all(imageBlobPromises);
    }

    get modalId(): string {
        return `filled-images-${this.rxId}-modal`;
    }

    get hasImages(): boolean {
        return !!this.imageIds.length;
    }

    showModal() {
        this.getFilledImageUrls();
        this.$bvModal.show(this.modalId);
    }
}

</script>

<style scoped>
.viewer {
    height: 350px;
}

.modal-content .viewer {
    height: 70vh;
}
</style>