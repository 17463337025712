<template>
    <card title="Change Searching">
        <form>
            <b-row align-v="end">
                <b-col cols="2">
                    <fg-input label="Type">
                        <v-select id="typeChooser" v-model="searchType" class="style-chooser" :clearable="false"
                            :options="searchTypes" />
                    </fg-input>
                </b-col>
                <b-col>
                    <fg-input :label="searchType">
                        <v-select id="personChooser" v-model="object" class="style-chooser" :options="objects"
                            :get-option-label="optionLabel" :filterable="false" :clearable="false"
                            @search="searchValueUpdated" />
                    </fg-input>
                </b-col>
                <b-col cols="2">
                    <fg-input>
                        <el-date-picker v-model="fromDate" type="date" />
                    </fg-input>
                    <fg-input>
                        <el-date-picker v-model="toDate" type="date" />
                    </fg-input>
                </b-col>
                <b-col cols="2">
                    <b-button block size="sm" class="mb-1" @click="expandAll = !expandAll">
                        {{ expandAll ? "Collapse " : "Expand " }}
                        All
                    </b-button>
                    <b-button block size="sm" variant="primary" class="my-0" @click="handleExportPdf">
                        <i class="fa fa-file-pdf" />
                        Export to PDF
                    </b-button>
                    <b-button variant="danger" :block="true" size="sm" class="mt-1" @click="clearClicked">
                        Clear
                    </b-button>
                </b-col>
            </b-row>

            <div v-if="!object" class="p-5 text-center">
                <strong>Select an object to view history.</strong>
            </div>
            <div v-else-if=loading class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
            <template v-else>
                <div v-if="allFields?.length" class="d-flex flex-wrap field-filters mt-1">
                    <b-badge :variant="filterFields.length ? 'secondary' : 'primary'" @click="filterFields = []">
                        All Fields
                    </b-badge>
                    <b-badge v-for="i in allFields" :key="i"
                        :variant="!filterFields.includes(i) ? 'secondary' : 'primary'" @click="toggleFilterField(i)">
                        {{ i }}
                    </b-badge>
                </div>

                <b-table id="history-table" :items="filteredDisplayHistory" :fields="tableFields" show-empty
                    empty-text="No history found">
                    <template #cell(toggle)="row">
                        <a v-if="row.item.diff.length && row.item.diff[0].key !== 'Initial Creation'" href="#"
                            @click.prevent="row.toggleDetails">
                            <i :class="{ 'fa fa-fw fa-chevron-right': true, 'fa-rotate-90': row.detailsShowing }"
                                aria-hidden="true" style="transition: all .2s;" />
                        </a>
                    </template>
                    <template #cell(fields)="row">
                        <div v-if="!row.detailsShowing" style="gap: 5px;" class="d-flex flex-wrap">
                            <b-badge v-for="i in row.item.diff" :key="i.key"
                                :variant="i.key == 'Initial Creation' ? 'success' : (!filterFields.length || filterFields.includes(i.key) ? 'primary' : 'secondary')"
                                :title="i.key == 'Initial Creation' ? undefined : `${i.oldValue} → ${i.newValue}`">
                                {{ i.key }}
                            </b-badge>
                        </div>
                    </template>
                    <template #row-details="row">
                        <b-card class="details-card">
                            <b-table :items="row.item.diff" small />
                        </b-card>
                    </template>
                </b-table>

                <table v-show="false" id="history-export">
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Old Value</th>
                            <th>New Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="h in filteredDisplayHistory">
                            <tr :key="h.id">
                                <th colspan="3">
                                    <strong>
                                        {{ h.updatedBy }}
                                        at
                                        {{ h.updated | formatDate('LLLL') }}
                                    </strong>
                                </th>
                            </tr>
                            <template v-for="hi in h.diff">
                                <tr :key="hi.key">
                                    <td>{{ hi.key }}</td>
                                    <td>{{ hi.oldValue }}</td>
                                    <td>{{ hi.newValue }}</td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>

            </template>
        </form>
    </card>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { Person } from '@/models/Person';
import { History, historyDiff } from '@/models/History';
import NameSearchComponent from './NameSearchComponent.vue';
import axios, { CancelTokenSource } from 'axios';
import { DatePicker } from 'element-ui';
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import { ReportExporter } from "@/mixins/ReportExporter";

@Component({
    name: "HistoryComponent",
    components: {
        NameSearchComponent,
        [DatePicker.name]: DatePicker,
    },
})
export default class HistoryComponent extends Mixins(ReportExporter) {
    created() {
        console.log("%c Created HistoryComponent", "color: green");
    }
    private object: Person | null = null; // The selected object of the search type (Patient/Prescriber/etc..)
    private objects: Person[] = []; // The list of objects returned from the search

    private fromDate: string = moment().subtract(7, 'd').format("YYYY-MM-DD");
    private toDate: string = moment().format("YYYY-MM-DD");

    private searchTypes: string[] = ["Patient", "Prescriber", "Vendor", "DrugCategory", "Store", "Prescription", "User", "Order", "AutoRefill"];
    private searchType: string = this.searchTypes[0];
    private histories: History[] = [];
    private expandAll: boolean = false;
    private filterFields: string[] = [];
    private loading = false;

    optionLabel(option: any) {
        if (this.searchType == "Order") return `Order Id: ${option.id}`;
        if (this.searchType === "AutoRefill") return `Subscription: ${option.id} Prescription: ${option.storeId}-${option.rxNumber}`;
        if (option.name != null) return option.name;
        if (option.storeID) return `Store-Rx-Rf:${option.storeID}-${option.rxNumber}-${option.rfNumber} Patient:${option.patientID}`;
        if (option.userName) return option.userName;

        const obj = new Person(option);
        return obj.toString();
    }

    formattedDateForHistory(history: History): string {
        const date = moment.utc(history.updated).local();
        return date.format("[ On: ]MM-DD-YYYY [At: ]hh:mm:ss a");
    }

    diffForHistory(history: History, forTable: boolean = false): any {
        const diff = historyDiff(history);
        if (!forTable) return diff;
        const tableHistory = Object.entries(diff).flatMap(([key, values]): any => {
            if (key === "0") return [{ key: "Initial Creation" }];
            if (values.length < 2) {
                if (!values[0]) return undefined;
                return [{ key, newValue: values[0] }];
            }
            if (values[0] === values[1]) return undefined;
            return [{ key, oldValue: values[0], newValue: values[1] }];
        }).filter(i => i);
        return tableHistory;
    }

    @Watch('searchType') onSearchTypeUpdate(value: any, oldValue: any) {
        this.objects = [];
    }

    @Watch('object') onObjectUpdate(value: Person, oldValue: Person) {
        if (!value) return;
        this.getHistoryForObject(value);
    }

    @Watch('fromDate') onFromDateUpdate(value: any, oldValue: any) {
        if (!this.object) return;
        this.getHistoryForObject(this.object);

    }
    @Watch('toDate') onToDateUpdate(value: any, oldValue: any) {
        if (!this.object) return;
        this.getHistoryForObject(this.object);
    }

    @Watch('allFields') onAllFieldsUpdate() {
        if (!this.filterFields.length) return;
        this.filterFields = this.filterFields.filter(ff => this.allFields.includes(ff));
    }

    clearClicked() {
        this.object = null;
        this.histories = [];
    }

    async getHistoryForObject(value: any) {
        const isValid = value && (value.id || value.storeID || value.userName);
        if (!isValid) return;

        let id = value.id;
        if (this.searchType == "Prescription") {
            id = `${value.storeID}-${value.rxNumber}-${value.rfNumber}`;
        } else if (this.searchType == "User") {
            id = value.userName;
        }

        const searchPath = encodeURI(`/${this.searchType}/${id}/history`);
        const config = {
            params: {
                fromDate: moment(this.fromDate).startOf('day').utc().toDate(),
                toDate: moment(this.toDate).endOf('day').utc().toDate()
            },
        };

        try {
            this.loading = true;
            const response = await axios.get(searchPath, config)
            const histories = response.data as History[];
            this.histories = histories.filter((value) => {
                return this.diffForHistory(value);
            });
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    private throttledSearch: Function = debounce(500, (searchValue) => { this.onSearch(searchValue) });

    async searchValueUpdated(value: string) {
        if (!value.length) return;
        this.throttledSearch(value);
    }

    private cancelTokenSource: CancelTokenSource | null = null;
    private searching = false;

    async onSearch(value: string) {
        if (this.searching) {
            this.cancelTokenSource?.cancel("New search started");
            this.searching = false;
        }

        if (!value.length) return;

        this.cancelTokenSource = axios.CancelToken.source();

        const searchPath = `/${this.searchType}`;
        const config = {
            params: { searchTerm: value },
            cancelToken: this.cancelTokenSource?.token
        };

        this.searching = true;

        try {
            const response = await axios.get(searchPath, config);

            let objects = response.data;
            if (!Array.isArray(objects)) objects = [objects];

            this.objects = objects;
        } catch (error) {
            console.warn(error);
        } finally {
            this.searching = false;
        }
    }

    get displayHistory() {
        return this.histories?.map(h => ({
            ...h,
            diff: this.diffForHistory(h, true),
            _showDetails: this.expandAll
        }));
    }

    get filteredDisplayHistory() {
        if (!this.filterFields.length) return this.displayHistory;

        return this.displayHistory.filter(dh => {
            return dh.diff.map((d: any) => d.key).some((i: any) => this.filterFields.includes(i))
        });
    }

    get tableFields() {
        return [
            { key: 'toggle', label: '', class: 'toggle-col' },
            { key: 'updated', formatter: this.dateTimeFormatter },
            'updatedBy',
            { key: 'fields', label: 'Updated Fields' }
        ];
    }

    dateTimeFormatter(d: any) {
        const date = moment.utc(d).local();
        return `${date.format("L LT")} (${date.fromNow()})`;
    }

    get allFields() {
        return [...new Set(this.displayHistory?.flatMap(dh => dh.diff.map((d: { key: string }) => d.key)))];
    }

    toggleFilterField(field: string) {
        if (this.filterFields.includes(field)) {
            this.filterFields = this.filterFields.filter(ff => ff !== field);
        } else {
            this.filterFields.push(field);
        }
    }

    async handleExportPdf() {
        const jspdf = await import(/* webpackChunkName: "jspdf" */ "jspdf");
        const autotable = await import(/* webpackChunkName: "jspdf" */ "jspdf-autotable");

        const doc = new jspdf.default();

        doc.setFontSize(18);
        doc.setFontStyle('bold');
        doc.text(`${this.searchType} History Report`, 14, 22);

        doc.setFontSize(14);
        doc.setFontStyle('normal');
        doc.text(this.optionLabel(this.object), 14, 30);

        doc.setFontSize(10);
        doc.text(`Generated on ${moment().format("LLLL")}`, 14, 37);

        autotable.default(doc, {
            html: "#history-export",
            includeHiddenHtml: false,
            showFoot: 'never',
            theme: "grid",
            headStyles: {
                fillColor: '#124e80'
            },
            styles: {
                cellWidth: 'wrap',
                overflow: 'linebreak'
            },
            columnStyles: {
                0: { cellWidth: 50 },
                1: { cellWidth: 65 },
                2: { cellWidth: 65 }
            },
            didParseCell(data) {
                const text = data.cell.raw;
                if (!(text as HTMLTableCellElement).querySelector('strong')) return;

                data.cell.styles.fontStyle = 'bold';
                data.cell.styles.fillColor = '#cee3f5';
            },
            startY: 42,
        });

        doc.save(`${this.searchType} History Report - ${this.object?.id}.pdf`);
    }
}

</script>

<style scoped>
#property-names {
    text-align: center;
}

#old-values {
    color: var(--color-danger);
}

#new-values {
    color: var(--color-save);
}

#title-row {
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
}

.cell {
    border: thin solid #808080;
}

.vdp-datepicker {
    background: #ffffff;
    color: #000000;
}

::v-deep(.toggle-col) {
    width: 40px;
}

::v-deep(.details-card) {
    box-shadow: none;
}

::v-deep(.details-card .card-body) {
    padding: 0;
}

::v-deep(.details-card .card-body table) {
    margin-bottom: 0;
    font-size: .9em;
}

::v-deep(table td) {
    width: 33%;
}

::v-deep(.b-table-details > td) {
    padding-left: 30px;
    padding-right: 30px;
}

::v-deep(.badge-secondary) {
    color: rgb(58, 58, 58);
}

.field-filters {
    gap: 5px;
}

.field-filters .badge {
    cursor: pointer;
}
</style>
