%card-stats{
  hr{
    margin: 5px 15px;
  }
}

.card-stats{
    @extend %card-stats;

    .statistics{
        //position: relative;
        text-align: center;
        padding: 15px 0;

        .title{
            margin-bottom: 0;
        }
        .stats-title{
            margin-bottom: 5px;
            color: $dark-gray;
            font-weight: $font-weight-normal;
        }

        &.statistics-horizontal,
        &.statistics-horizontal .info-title{
          padding: 0;
        }
    }

    .icon{
      display: inline-block;
      vertical-align: top;
      margin: 0 15px;
    }

    .stats-information{
      display: inline-block;
      margin-bottom: 15px;

      .stats-text{
        font-size: 29px;
      }

      .stats-details{
        display:block;
        color: $default-color;
      }
    }

    .dots{
      text-align: right;

      .dot{
        background-color: $black-color;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        display: inline-block;
      }
    }

    [class*="col-"]{
        .statistics::after{
            position: absolute;
            right: 0;
            top: 20px;
            width: 1px;
            height: calc(100% - 40px);
            content: "";
            background: $medium-gray;

        }
    }
    [class*="col-"]:last-child{
        .statistics::after{
            display: none;
        }
    }
}
