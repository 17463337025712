<template>
    <card title="Inventory Batch Items Queue">

        <!-- Filter -->
        <div class="filter-grid">
            <SearchComponent v-model="storeFilter" label="Store" search-u-r-l="/store" prepopulate clearable />
            <SearchComponent v-model="vendorFilter" label="Vendor" search-u-r-l="/vendor" prepopulate clearable />
            <DateRangeFilter v-model="dateRangeFilter" label="Created Date:" />
        </div>

        <div>
            <checkbox v-model="showSubmitted" inline>Show submitted</checkbox>
            <checkbox v-model="showRemoved" inline>Show removed</checkbox>
            <checkbox v-model="showReturns" inline>Returns only</checkbox>
        </div>

        <hr>

        <!-- Actions -->
        <b-row>
            <b-col>
                <b-button variant="info" size="sm" @click="addPurchase">Add Purchase Order</b-button>
                <b-button variant="warning" size="sm" @click="addReturn">Add Return</b-button>
            </b-col>
        </b-row>

        <!-- Table -->
        <b-row>
            <b-col>
                <b-table id="inventory-batch-table" ref="inventory-batch-table" :items="tableProvider"
                    :filter="tableFilter" :fields="tableFields" :sort-by.sync="sortBy" :tbody-tr-class="colorCodeTable"
                    no-provider-sorting api-url="/Inventory/inventory-batch-queue" show-empty
                    empty-text="No products in the batch." striped>
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(ndcProblemIndicator)="data">
                        <i v-if="data.item.isNDCProblem" class="fas fa-exclamation-circle" style="color:red"
                            title='There was a problem with automatic drug matching by NDC. See batch details.'
                            v-b-tooltip.hover />
                    </template>
                    <template #cell(created)="data">
                        <b v-if="data.item.canceled || data.item.submitted" v-b-tooltip="rowTitle(data.item)">
                            {{ data.item.created | formatDate('lll') }}
                        </b>
                        <span v-else>
                            {{ data.item.created | formatDate('lll') }}
                        </span>
                    </template>
                    <template #cell(actions)="data">
                        <a href="#" @click.prevent="openDetails(data.item)">
                            <b-icon icon="pencil-square" v-b-tooltip.hover title="Details" />
                        </a>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <!-- Inventory Batch Form -->
        <b-modal id="inventory-batch-new" ref="inventory-batch-new-model" title="Add Purchase Order" centered no-fade
            ok-title="Add" @ok="addPO" :hide-footer="importingPO">
            <b-form-group label="Order Number:" :disabled="importingPO">
                <b-input v-model="purchaseOrderNumber" autofocus />
            </b-form-group>

            <div v-if="importingPO">
                <b-spinner label="Spinning" class="mr-1" small />
                Looking for transaction...
            </div>
        </b-modal>

        <b-modal id="inventory-batch-form" ref="inventory-batch-modal" title="Purchase Order" size="xl" hide-footer
            no-fade>
            <InventoryBatchFormComponent v-model="selectedBatchID" @batch-saved="batchSaved"
                :batch-data="selectedBatchData" :po="purchaseOrderNumber"
                :has-inventory-locations="hasInventoryLocations" :require-lots="requireLots" />
        </b-modal>

        <b-modal id="return-modal" ref="return-modal" title="Inventory Return" size="xl" hide-footer no-fade>
            <InventoryBatchFormComponent v-model="selectedBatchID" variant="return" @batch-saved="batchSaved"
                :has-inventory-locations="hasInventoryLocations" :require-lots="requireLots" />
        </b-modal>

        <b-modal ref="import-select-modal" title="Select Imported Purchase Order" centered hide-footer no-fade>
            <p>These purchase orders were imported. Select one to open:</p>
            <b-table :items="importedBatches" :fields="['vendorName', { key: 'actions', label: '' }]">
                <template #cell(vendorName)="data">
                    {{ data.value ? data.value : data.item.senderName }}
                </template>
                <template #cell(actions)="data">
                    <b-button size="sm" @click="openPO(data.item)">
                        Open
                    </b-button>
                </template>
            </b-table>
        </b-modal>
    </card>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import SearchComponent from '@/components/SearchComponent.vue';
import InventoryBatchFormComponent from '@/components/Inventory/InventoryBatchFormComponent.vue';
import { InventoryBatch } from '@/models/InventoryBatch';
import { Store } from '@/models/Store';
import { Vendor } from '@/models/Vendor';
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { BModal, BTable, BvTableCtxObject } from "bootstrap-vue";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import DateRangeFilter from '@/components/DateRangeFilter.vue';
import moment from "moment";

@Component({
    name: "InventoryBatchQueuePage",
    components: {
        SearchComponent,
        Checkbox,
        InventoryBatchFormComponent,
        DateRangeFilter
    },
})
export default class InventoryBatchQueuePage extends Vue {
    @Ref('inventory-batch-table') private table!: BTable;
    @Ref('return-modal') private returnModal!: BModal;
    @Ref('inventory-batch-modal') private inventoryBatchModal!: BModal;
    @Ref('inventory-batch-new-model') private inventoryBatchNewModal!: BModal;
    @Ref('import-select-modal') private importSelectModal!: BModal;

    protected items: Array<InventoryBatch> = new Array<InventoryBatch>();

    private storeFilter: Store = new Store();
    private vendorFilter: Vendor = new Vendor();
    private selectedBatchID = 0;
    private selectedBatchData: InventoryBatch | null = null;
    private purchaseOrderNumber = "";
    private importingPO = false;
    private dateRangeFilter: any = { startDate: moment().subtract(30, 'days').toString(), endDate: new Date().toDateString() };
    private sortBy: string = 'created';
    private showSubmitted = false;
    private showRemoved = false;
    private showReturns = false;
    private requireLots = false;
    private importedBatches: InventoryBatch[] | null = null;

    mounted() {
        this.loadHasInventoryLocations();
        this.getRequireLotsFromConfig();
    }

    get tableFields(): any {
        return [
            { key: "ndcProblemIndicator", label: '' },
            { key: "purchaseOrderNumber", label: "Purchase Order #" },
            { key: "linesQty", label: "Lines" },
            { key: "vendorName", label: "Vendor" },
            { key: "storeName", label: "Store" },
            { key: "programName", label: "Program" },
            { key: "location.name", label: "Location", hidden: !this.hasInventoryLocations },
            { key: "created", label: "Created", sortable: true },
            { key: "createdBy", label: "Created By" },
            { key: "actions", label: '' },
        ].filter(f => f.hidden !== true);
    }

    get tableFilter(): AxiosRequestConfig {
        return {
            params: {
                storeID: this.storeFilter?.id,
                vendorID: this.vendorFilter?.id,
                showSubmitted: this.showSubmitted,
                showRemoved: this.showRemoved,
                showReturns: this.showReturns,
                startDate: moment(this.dateRangeFilter.startDate).toISOString(),
                endDate: moment(this.dateRangeFilter.endDate).toISOString()
            },
        };
    }

    async tableProvider(ctx: BvTableCtxObject) {
        const uri = ctx.apiUrl!;
        const config = ctx.filter as AxiosRequestConfig;
        try {
            const resp = await axios.get<InventoryBatch[]>(uri, config);
            return resp.data.map(i => Object.assign(new InventoryBatch.InventoryBatchItem(), i));
        } catch (err) {
            const error = err as AxiosError;
            console.error('Error while loading inventory batch items', { err, response: error?.response });
            this.$notification(NotificationOptions.error(err));
            return [];
        }
    }

    batchSaved() {
        this.inventoryBatchModal.hide();
        this.returnModal.hide();
        this.selectedBatchID = 0;
        if (this.table) this.table.refresh();
    }

    colorCodeTable(row: InventoryBatch, type: string) {
        if (!row || type !== 'row') return;

        if (row.canceled) return 'table-danger';
        if (row.submitted) return 'table-info';
        if (row.isReturn) return 'table-warning';
    }

    rowTitle(row: InventoryBatch): string {
        const dt = row.submitted || row.canceled;
        if (!dt) return "";

        const action = row.canceled ? 'Canceled' : row.submitted ? 'Submitted' : '';
        const actionBy = row.submittedBy || row.canceledBy;
        return `${action} by ${actionBy}`;
    }

    openDetails(batch: any) {
        this.selectedBatchID = batch.id;
        this.selectedBatchData = null;
        if (batch.isReturn) {
            this.returnModal.show();
        } else {
            this.inventoryBatchModal.show();
        }
    }

    addPurchase() {
        this.purchaseOrderNumber = "";
        this.inventoryBatchNewModal.show();
    }

    addReturn() {
        this.selectedBatchID = 0;
        this.returnModal.show();
    }

    async addPO(e: any) {
        this.selectedBatchID = 0;
        this.selectedBatchData = null;

        if (!this.purchaseOrderNumber?.trim()) {
            this.inventoryBatchModal.show();
            return;
        }

        e.preventDefault();

        this.importingPO = true;

        try {
            const uri = `/Inventory/inventory-batch-import/${this.purchaseOrderNumber}`;
            const resp = await axios.post<InventoryBatch[]>(uri);
            if (resp.data) {
                if (resp.data.length > 1) {
                    this.importedBatches = resp.data;
                    this.inventoryBatchNewModal.hide();
                    this.importSelectModal.show();
                    this.table?.refresh();
                    return;
                }

                if (resp.data.length === 1) {
                    this.selectedBatchID = resp.data[0].id as number;
                    this.selectedBatchData = resp.data[0];
                    this.table?.refresh();
                }
            }
            this.inventoryBatchNewModal.hide();
            this.inventoryBatchModal.show();
        } catch {
            this.$notification(NotificationOptions.errorSaveNotificationPreset("Inventory Batch"));
        } finally {
            this.importingPO = false;
        }
    }

    openPO(item: InventoryBatch) {
        this.selectedBatchID = item.id as number;
        this.selectedBatchData = item;
        this.importSelectModal.hide();
        this.inventoryBatchModal.show();
    }

    protected hasInventoryLocations = false;

    async loadHasInventoryLocations() {
        try {
            this.hasInventoryLocations = (await axios.get<boolean>("/inventory/has-inventory-locations")).data;
        } catch {
            //ignore
        }
    }

    async getRequireLotsFromConfig() {
        try {
            const response = await this.$http.get<boolean>("/Configuration/RequireLotsOnInventoryBatches");
            this.requireLots = response.data;
        } catch {
            // ignore
        }
    }

}
</script>

<style scoped>
.filter-grid {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 380px), 1fr));
}
</style>