<template>
    <card title="Transferred Prescription Report ">
        <div class="filter-grid">
            <SearchComponent v-model="pharmacyfilter" :prepopulate="true" label="Pharmacy"
                :search-u-r-l="`/StoredPharmacy`" :params="{ showOnlyActive: false }" :clearable="true"
                @input="onPharmacyChange"></SearchComponent>
            <div>
                <label>Patient:</label>
                <v-select v-model="patientFilter" class="style-chooser" :clearable="true" :options="patientOptions" />
            </div>
            <DateRangeFilter v-model="dateRangeFilter" label="Transferred Date:" />
        </div>

        <b-table striped :items="filteredItems" :fields="fields" :busy="isLoading" show-empty
            empty-text="No Transferred Prescriptions" :per-page="pageSize" :current-page="pageNumber">
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(isVerbalTransfer)="data">
                <div v-if="!data.item.isVerbalTransfer">
                    <b-button variant="primary" size="sm" @click.prevent="openPDFpreview(data.item)">
                        <i class="fa fa-file" aria-hidden="true" title="Fax"></i>
                        Fax...
                    </b-button>
                </div>
                <div v-if="data.item.isVerbalTransfer">
                    Verbal
                </div>
            </template>
            <template #cell(pharmacyName)="data">
                <div>
                    <a href="#" @click.prevent="RXdetails = data.item; $bvModal.show('update-pharmacy')">
                        {{ data.value }}
                    </a>
                </div>
                <div v-if="data.item.pharmacyFax">
                    <small class="font-weight-normal text-muted">
                        Fax: {{ data.item.pharmacyFax }}
                    </small>
                </div>
            </template>
        </b-table>

        <BottomPagerBar v-model="pageNumber" :total-rows="totalRows" :per-page="pageSize" show-total
            item-name="prescription" />

        <b-modal id="pdf-preview" hide-footer no-close-on-backdrop size="xl" title="PDF Preview">
            <b-row>
                <b-col>

                    <div id="pdfPreview" class="no-image">
                        <div v-if="isLoadingPDF" class="text-center my-2">
                            <div>
                                <b-spinner class="align-middle"></b-spinner>
                            </div>

                            <strong>Loading...</strong>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <b-button block @click="$bvModal.hide('pdf-preview')">Close</b-button>
                </b-col>
                <b-col>
                    <b-button block variant="primary" @click="$bvModal.show('update-pharmacy')">
                        Update Pharmacy...
                    </b-button>
                </b-col>
                <b-col>
                    <b-button block variant="success" @click="reSendRX()">
                        Re-send Transfer
                        <template v-if="RXdetails.pharmacyFax">to {{ RXdetails.pharmacyFax }}</template>
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal id="update-pharmacy" size="xl" title="Update Pharmacy" hide-footer>
            <PharmacyDetailPage new-mode :pharmacy-id="RXdetails.storedPharmacyId" :hide-title="true"
                @close="$bvModal.hide('update-pharmacy')" @created="handlePharmacySave" />
        </b-modal>
    </card>
</template>

<script lang="ts">
import axios, { AxiosRequestConfig } from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';
import SearchComponent from '@/components/SearchComponent.vue';
import NewClearSaveButtons from '@/components/NewClearSaveButtons.vue';
import { TransferRXRecord, PharmacyRecord } from "@/models/Pharmacy"
import Checkbox from "@/components/Inputs/Checkbox.vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import PDFObject from "pdfobject";
import DateRangeFilter from './DateRangeFilter.vue';
import moment from "moment";
import BottomPagerBar from './BottomPagerBar.vue';
import PharmacyDetailPage from "@/pages/Pharmacy/PharmacyDetailPage.vue";

@Component({
    name: 'TransferRxReportPage',
    components: {
        SearchComponent,
        NewClearSaveButtons,
        Checkbox,
        DateRangeFilter,
        BottomPagerBar,
        PharmacyDetailPage
    }
})
export default class TransferRxReportPage extends Vue {
    private items: Array<TransferRXRecord> = new Array<TransferRXRecord>();
    private isLoading = false;
    private isLoadingPDF = false
    private RXdetails: TransferRXRecord = new TransferRXRecord()
    private fields: any[] = [
        // { key: "id", label: 'ShortageId'},
        { key: "patientName", label: "Patient Name" },
        { key: "drugName", label: "Drug Name" },
        { key: "pharmacyName", label: "Pharmacy" },
        { key: "transferredDate", label: "Date Transferred" },
        { key: "transferredBy", label: "Transferred By" },
        { key: "isVerbalTransfer", label: "Transferred Method" },
    ];
    private pharmacyfilter: PharmacyRecord = new PharmacyRecord();
    private pageSize = 50;
    private pageNumber = 1;
    private dateRangeFilter: any = { startDate: null, endDate: null };
    private patientFilter = "";

    created() {
        console.log("%c Created AuditTrackingPage", "color: green");
        this.loadItems(0);
    }

    async loadItems(value: number) {
        this.isLoading = true;

        try {
            const res = await axios.get<Array<TransferRXRecord>>(`StoredPharmacy/getAllTransferredRx/${value}`);
            this.items = res.data.map(i => new TransferRXRecord(i));
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }

        this.isLoading = false;
    }

    get totalRows() {
        return this.items?.length ?? 0;
    }

    get filteredItems() {
        return this.items.filter(i => {
            if (this.dateRangeFilter.startDate) {
                const xferDate = moment(i.transferredDate).toDate();

                if (xferDate < this.dateRangeFilter.startDate ||
                    xferDate > moment(this.dateRangeFilter.endDate).add(1, "day").toDate()) return false;
            }

            if (this.patientFilter && i.patientName !== this.patientFilter) return false;

            return true;
        });
    }

    @Watch("patientFilter")
    @Watch("dateRangeFilter")
    @Watch("items")
    resetPaging() {
        this.pageNumber = 1;
    }

    get patientOptions() {
        return [... new Set(this.items.map(i => i.patientName))];
    }

    openPDFpreview(details: TransferRXRecord) {
        this.$bvModal.show("pdf-preview");
        this.RXdetails = details;
        this.isLoadingPDF = true;
        const config: AxiosRequestConfig = {
            params: { filename: details.docFileName },
            responseType: "blob",
        };
        axios
            .get<any>("StoredPharmacy/get-transfer-document-filename", config)
            .then((res) => {
                const objectUrl = URL.createObjectURL(res.data);
                const options = {
                    pdfOpenParams: {
                        view: "FitW",
                        pagemode: "thumbs",
                        toolbar: 1,
                        scrollbar: 0,
                    },
                };
                PDFObject.embed(objectUrl, "#pdfPreview", options);
                this.isLoadingPDF = false;
            })
            .catch((err) => {
                console.error("Error while viewing a document.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
                this.isLoadingPDF = false;
            });
    }

    reSendRX() {
        const config: AxiosRequestConfig = {
            params: {
                filename: this.RXdetails.docFileName,
                storedPharmacyId: this.RXdetails.storedPharmacyId,
                transferRxId: this.RXdetails.id
            }
        };
        axios
            .get<any>("StoredPharmacy/resend-transfer-document", config)
            .then((res) => {
                this.$notification(NotificationOptions.notificationOptionsPreset("Document has been resent successfully", NotificationOptions.NotificationTypes.success));
            })
            .catch((err) => {
                console.error("Error while resending the document.", { err, response: err.response });
                this.$notification(NotificationOptions.errorSaveNotificationPreset(err.response));
                this.isLoadingPDF = false;
            });
    }

    onPharmacyChange(value: PharmacyRecord) {
        this.loadItems(value?.id ?? 0);
    }

    handlePharmacySave(e: PharmacyRecord) {
        this.items.forEach(i => {
            if (i.storedPharmacyId !== e.id) return;
            i.pharmacyFax = e.faxNumber;
            i.pharmacyName = e.name;
        });

        this.$bvModal.hide('update-pharmacy');
    }
}
</script>

<style scoped>
.form-check {
    margin-top: 0;
    padding-left: 0;
}

.filter-grid {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 430px), 1fr));
}

.small .filter-grid {
    grid-template-columns: 1fr;
}

@media (min-width: 1600px) {
    .filter-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

#pdfPreview {
    height: calc(100vh - 220px);
}
</style>