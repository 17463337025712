<template>
    <navbar :show-navbar="showNavbar">
        <div id="user-stats">
            <b-button variant="link" @click="toggleProductivity">
                <span>Productivity Stats (Month To Date) </span>
                <i v-if="!viewProductivity" class="fas fa-arrow-down"/>
            </b-button>
            <b-collapse :visible="viewProductivity" class="ml-1">
                <div v-if="userProductivity.isPharmacist && !isLoading" class="stats">
                    <!-- Pharmacist Stats -->
                    <div v-if="userProductivity.employee" v-b-tooltip.hover title="You">
                        <i class="fas fa-user"></i>
                        {{ userProductivity.employee || '' }}
                    </div>

                    <div v-b-tooltip.hover title="PV2">
                        <b-icon icon="file-earmark-check-fill"/>
                        {{ userProductivity.pv2 || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="PV2 Cancelled">
                        <b-icon icon="x-octagon-fill"/>
                        {{ userProductivity.cancelled || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Consultations">
                        <i class="fas fa-glasses"/>
                        {{ userProductivity.consultations || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Transfers">
                        <i class="fas fa-exchange-alt"/>
                        {{ userProductivity.transfers || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="PV1">
                        <b-icon icon="file-earmark-check"/>
                        {{ userProductivity.pv1 || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Productivity Score">
                        <i class="fas fa-star-half-alt"/>
                        {{ (userProductivity.productivityReport || 0).toFixed(0) }}
                    </div>
                </div>
                <div v-if="!userProductivity.isPharmacist && !isLoading" class="stats">
                    <!-- Technician Stats -->
                    <div v-if="userProductivity.employee" v-b-tooltip.hover title="You">
                        <i class="fas fa-user"/>
                        {{ userProductivity.employee || '' }}
                    </div>

                    <div v-b-tooltip.hover title="Non E-Scripts (Refills)">
                        <i class=" fas fa-file-signature"/>
                        {{ userProductivity.nonEscripts || '0' }}
                        ({{ userProductivity.nonEscriptsRefills || '0' }})
                    </div>

                    <div v-b-tooltip.hover title="Escripts (Refills)">
                        <i class=" fas fa-file-upload"/>
                        {{ userProductivity.escripts || '0' }}
                        ({{ userProductivity.escriptsRefills || '0' }})
                    </div>

                    <div v-b-tooltip.hover title="Total Scripts">
                        <i class=" fas fa-file-prescription"/>
                        {{ userProductivity.total || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Script Errors">
                        <i class=" fas fa-file-excel"/>
                        {{ userProductivity.errors || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Scripts Without Errors">
                        <i class=" fas fa-file-medical-alt"/>
                        {{ userProductivity.netTotal || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Error %">
                        <i class=" fas fa-tools"/>
                        {{ ((userProductivity.error || 0) * 100).toFixed(2) + '%' || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Total Calls">
                        <i class=" fas fa-phone"/>
                        {{ userProductivity.totalCalls || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Inbound Calls">
                        <i class=" fas fa-headphones"/>
                        {{ userProductivity.inbound || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Outbound Calls">
                        <i class=" fas fa-microphone"/>
                        {{ userProductivity.outbound || '0' }}
                    </div>

                    <div v-b-tooltip.hover title="Productivity Score">
                        <i class=" fas fa-star-half-alt"/>
                        {{ (userProductivity.productivityReport || 0).toFixed(0) }}
                    </div>

                </div>
                <div v-if="isLoading">
                    <b-spinner label="Spinning" class="mr-2 productivity-spinner"/>
                    <span>Loading productivity stats</span>
                </div>
            </b-collapse>
        </div>
        <div class="actions">

            <div class="notifications action-btn" title="Notifications">
                <b-popover ref="notificationPopover" target="notification-bell" triggers="click blur" placement="bottom"
                           custom-class="notification-popover">
                    <NotificationsList small unread-only view-all-link scroll :page-size="10"
                                       @navigate="$root.$emit('bv::hide::popover')"/>
                </b-popover>

                <button id="notification-bell"
                        :class="{ 'notification-icon': true, 'highlight-count': notificationCounter }" type="button">
                    <i :class="{ 'fas fa-bell fa-fw': true, 'shake': notificationShouldShake }"/>
                    <div id="notification-counter" v-show="notificationCounter > 0" class="action-count-badge">
                        {{ notificationCounter }}
                    </div>
                </button>
            </div>

            <div class="dispense-errors action-btn">
                <router-link :to="{ name: 'DispenseErrors', params: { onlyAssigned: true } }">
                    <span :class="{ 'dispense-error-notification-icon': true, 'highlight-count': errorCounter }"
                          :title="errorCounter > 0 ? 'You have dispense errors assigned!' : 'No dispense errors'"
                          v-b-tooltip.hover>
                        <i class="fas fa-exclamation-triangle fa-fw"/>
                        <div id="error-counter" v-show="errorCounter > 0" class="action-count-badge danger">
                            {{ errorCounter }}
                        </div>
                    </span>
                </router-link>
            </div>

            <div title="Drug Price Test" v-b-tooltip.hover @click="showPriceTestModal = true" v-b-modal.toolbox-modal
                 class="action-btn">
                <i class="fas fa-dollar-sign fa-fw"/>
            </div>

            <div title="Log Out" v-b-tooltip.hover @click="logOut" class="action-btn">
                <i class="fas fa-sign-out-alt fa-fw"/>
            </div>

            <PriceTestModal v-if="showPriceTestModal" @hide="showPriceTestModal = false"/>

            <b-modal v-model="showPatientRecentlyViewedModal" hide-footer no-fade centered
                     title="Recently Viewed Patients">
                <PatientRecentlyViewed :keyboard-nav="true" :no-items-message="true"
                                       @change="$router.push('/processing/patient-dashboard/' + $event.id); showPatientRecentlyViewedModal = false;"/>
            </b-modal>
        </div>
    </navbar>
</template>

<script lang='ts'>
import { Component, Vue, Watch } from 'vue-property-decorator';
import axios from "axios";
import Navbar from "@/components/Navbar/Navbar.vue";
import NavbarToggleButton from "@/components/Navbar/NavbarToggleButton.vue";
import { UserProductivity } from "@/models/UserProductivity";
import { ProductivityStatsBase } from "@/models/ProductivityStatsBase";
import { PharmacistProductivity } from "@/models/PharmacistProductivity";
import { logoutCognito } from "@/util/cognito-auth";

@Component({
    name: 'TopNavbar',
    components: {
        Navbar,
        NavbarToggleButton,
        PriceTestModal: () => import('@/components/Drug/PriceTestModalComponent.vue'),
        NotificationsList: () => import("@/components/Notifications/NotificationsList.vue"),
        PatientRecentlyViewed: () => import("@/components/Patient/PatientRecentlyViewed.vue"),
    },
})
export default class TopNavbar extends Vue {
    private activeNotifications = false;
    private showNavbar = false;
    protected userProductivity = {};
    protected viewProductivity = false;
    protected errorCounter = 0;
    protected notificationCounter = 0;
    protected isLoading = false;
    protected showPriceTestModal = false;
    protected notificationShouldShake = false;
    protected showPatientRecentlyViewedModal = false;

    get routeName(): string {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
    }

    @Watch('routeName') changedRoute() {
        this.getProductivity();
    }

    created() {
        this.isLoading = true;
        this.getProductivity();
        if (localStorage.getItem("ViewProductivityStats") == null || localStorage.getItem("ProductivityStatsDate") != new Date().toLocaleDateString()) {
            localStorage.setItem("ViewProductivityStats", 'true');
            this.viewProductivity = true;
        }
        if (localStorage.getItem("ViewProductivityStats") == 'true') {
            this.viewProductivity = true;
        }

        this.$http.get('DispenseError/error-count')
            .then(res => {
                this.errorCounter = res?.data || 0;
            })
            .catch(err => {
                console.error('Error while pulling number of dispense errors assigned.', {
                    err,
                    response: err?.response,
                });
            });

        //Dispense Error Counter live connection setup
        this.$watch("$root.$data.dispenseErrorCount", () => {
            this.errorCounter = this.$root.$data.dispenseErrorCount;
        });

        this.$watch("$root.$data.notificationCount", () => {
            this.notificationCounter = this.$root.$data.notificationCount;
        });

        document.addEventListener("keyup", this.hotKeyListener);
    }

    hotKeyListener(e: any) {
        if (e.code === 'F4') this.showPatientRecentlyViewedModal = true;
    }

    @Watch('notificationCounter')
    notificationCountChange(newValue: number, oldValue: number) {
        if (newValue > oldValue) {
            this.notificationShouldShake = true;
            setTimeout(() => {
                this.notificationShouldShake = false;
            }, 800);
        }
    }

    capitalizeFirstLetter(str: string | null | undefined) {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
    }

    closeDropDown() {
        this.activeNotifications = false;
    }

    toggleNavbar() {
        this.showNavbar = !this.showNavbar;
    }

    toggleProductivity() {
        if (localStorage.getItem("ViewProductivityStats") == 'true') {
            localStorage.setItem("ViewProductivityStats", 'false');
            this.viewProductivity = false;
        } else {
            localStorage.setItem("ViewProductivityStats", 'true');
            this.viewProductivity = true;
        }
    }

    getProductivity() {
        axios.get<ProductivityStatsBase>('reports/GetProductivityReport')
            .then(response => {
                let stats = response.data;
                if (stats.isPharmacist)
                    this.userProductivity = Object.assign(new PharmacistProductivity(), stats);
                else
                    this.userProductivity = Object.assign(new UserProductivity(), stats);
            })
            .catch(err => {
                console.error('Error while getting productivity stats', { err, response: err?.response });
            })
            .finally(() => this.isLoading = false);
    }

    async logOut() {
        if (process.env.VUE_APP_USE_COGNITO == 'true') {
            logoutCognito();
        } else {
            await this.$msal.logout();
        }
        this.$router.push("/login");
    }
};
</script>

<style>
.notification-popover {
    max-width: 400px !important;
    width: 400px;
}
</style>

<style scoped lang="scss">
.action-btn {
    cursor: pointer;
}

.highlight-count {
    color: yellow !important;
}

.productivity-spinner {
    width: 1rem;
    height: 1rem;
}

.action-count-badge {
    position: absolute;
    top: 19px;
    left: 23px;
    border-radius: 20px;
    padding-top: 3px;
    font-size: .75em;
    width: 2em;
    height: 2em;
    text-align: center;
    font-weight: 600;
    background-color: #081c35;
    color: white;
    font-variant-numeric: tabular-nums;
    box-shadow: 0 0 2px rgba(white, .45);
    letter-spacing: .2px;

    &.danger {
        background-color: rgb(233, 0, 0);
    }
}

#user-stats {
    height: 6em;
}

::v-deep(.container-fluid) {
    display: grid !important;
    flex-wrap: nowrap !important;
    gap: 10px;
    grid-template-columns: 1fr 200px;
    align-items: center;
}

.actions {
    display: flex;
    gap: 10px;

    > * {
        flex-grow: 1;
    }

    .fas {
        font-size: 2em;
    }

    .dispense-errors,
    .notifications {
        position: relative;
    }
}

#notification-bell {
    background: transparent;
    border: none;
    color: white;
}

.stats {
    display: flex;
    flex-wrap: wrap;
    gap: 0 2px;

    div {
        border-radius: 7px;
        padding: 4px 8px;
        transition: all .2s;
        border: 1px solid transparent;
    }

    div:hover {
        background: rgba(white, .15);
        border-color: rgba(white, .25);
    }

    i,
    svg {
        margin-right: 2px;
    }
}

.btn-link {
    margin-top: 4px;
    margin-bottom: 4px;
}

@keyframes prominent-shake {
    0% {
        transform: rotate(0deg) scale(1);
    }

    10% {
        transform: rotate(20deg) scale(1.1);
        text-shadow: 0 0 5px white;
    }

    20% {
        transform: rotate(-20deg) scale(1.1);
        text-shadow: 0 0 5px white;
    }

    30% {
        transform: rotate(20deg) scale(1.1);
        text-shadow: 0 0 5px white;
    }

    40% {
        transform: rotate(-20deg) scale(1.1);
        text-shadow: 0 0 5px white;
    }

    50% {
        transform: rotate(10deg) scale(1.05);
        text-shadow: 0 0 2px white;
    }

    60% {
        transform: rotate(-10deg) scale(1.05);
        text-shadow: 0 0 2px white;
    }

    70% {
        transform: rotate(10deg) scale(1.05);
        text-shadow: 0 0 2px white;
    }

    80% {
        transform: rotate(-10deg) scale(1.05);
        text-shadow: 0 0 2px white;
    }

    90% {
        transform: rotate(5deg) scale(1);
    }

    100% {
        transform: rotate(0deg) scale(1);
    }
}

.shake {
    animation: prominent-shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>
