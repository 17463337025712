
.pager-bar {
    position: sticky;
    bottom: 0;
    z-index: 20;
    background: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    min-height: 50px;
    border-top: 1px solid rgba(0, 0, 0, .05);
    box-shadow: inset 0px 7px 6px -6px rgba(0, 0, 0, .1);
    margin: 0 -15px -10px -15px;
    padding: 10px 15px 10px 15px;

    .b-pagination {
        margin-bottom: 0;
    }

    &.no-stick {
        position: inherit;
    }

    .d-flex {
        gap: 8px;
    }
}
