<template>
    <div class="configuration-page">
        <b-overlay :show="loading" rounded>
            <card title="Configuration">
                <b-card v-for="group in configurationGroups" :key="group" class="group">
                    <h5 @click="toggleGroup(group)">
                        <i :class="{ 'fa fa-fw': true, 'fa-chevron-right': true, 'fa-rotate-90': openGroups.includes(group) }"
                            aria-hidden="true" style="transition: all .2s;"></i>
                        {{ group }}
                    </h5>
                    <transition name="fade">
                        <div v-if="openGroups.includes(group)" class="d-flex flex-column" style="gap: 12px;">
                            <div class="grid">
                                <div v-for="name in getKeysForGroup(group, false)" :key="name">
                                    <ConfigurationField v-model="configuration[name]" :label="formatConfigName(name)"
                                        :type="getConfigType(name)" :help="fields[name]?.help"
                                        :add-label="fields[name].addLabel" />
                                </div>
                            </div>
                            <div v-for="name in getKeysForGroup(group, true)" :key="name">
                                <ConfigurationField v-model="configuration[name]" :label="formatConfigName(name)"
                                    :type="getConfigType(name)" :help="fields[name]?.help"
                                    :add-label="fields[name].addLabel" bold-label />
                            </div>
                        </div>
                    </transition>
                </b-card>

                <div class="actions d-flex align-items-center">
                    <div class="flex-fill">
                        <b-button @click="toggleGroups" variant="primary">
                            {{ openGroups.length ? 'Close' : 'Open' }} All Configuration Groups
                        </b-button>
                    </div>
                    <div>
                        <b-button variant="success" @click="saveConfiguration" :disabled="!canSave" size="lg">
                            Save Configuration
                        </b-button>
                    </div>
                </div>
            </card>
        </b-overlay>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import { Configuration } from "@/models/Configuration";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import ConfigurationField from "@/components/Configuration/ConfigurationField.vue";

const entityName = "Configuration";

@Component({
    name: "ConfigurationPage",
    components: {
        ConfigurationField
    }
})
export default class ConfigurationPage extends Vue {
    protected configuration: Configuration = new Configuration();
    protected loading = false;

    get fields(): any {
        return {
            refillTextDays: "Refills",
            oldScriptRefillTextDays: "Refills",
            requireExplicitVerificationPV1: {
                title: "Require Explicit Verification PV1",
                group: "Prescription Verification"
            },
            requireExplicitVerificationPV2: {
                title: "Require Explicit Verification PV2",
                group: "Prescription Verification"
            },
            autoRefillPromptStatement: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementSpanish: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementRussian: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementSomali: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementArabic: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementChineseSimplified: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementVietnamese: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementFarsi: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementKorean: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementRomanian: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementSwahili: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementBurmese: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementNepali: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementAmharic: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoRefillPromptStatementPashto: {
                group: "Auto Refill",
                type: "textarea"
            },
            autoCaseAppNewPatientCreation: "Cases",
            autoCaseTextsNoValidNumbers: ["Cases", "Text Messaging"],
            autoCaseTextsPatientContactFailure: ["Cases", "Text Messaging"],
            autoCaseTextsFailAutoRefill: {
                groups: ["Cases", "Text Messaging", "Auto Refill"],
                help: "Automatically creates a patient case if one doesn't already exist if texts fail to send for an auto refill."
            },
            partialPaymentTextThresholdAmount: {
                groups: ["Text Messaging"],
                help: "When amount owed is over this amount, text will refer patient to call instead of sending a web link."
            },
            npcTextThresholdAmount: {
                groups: ["Text Messaging"],
                title: "NPC Text Threshold Amount",
                help: "When amount owed is over this amount, text will refer patient to call instead of sending a web link."
            },
            infinitrakApiKey: {
                title: "InfiniTRAK API Key",
                groups: ["Integrations"]
            },
            irsReportEmail: {
                hidden: true
            },
            disableMedGuidePrinting: {
                title: "Disable MedGuide Printing",
                groups: ["Printing"]
            },
            inventoryReasons: {
                type: "string-array",
                groups: ["Inventory"],
                addLabel: "Add Reason"
            },
            textMessageTemplates: {
                type: "text-message-templates",
                title: "Text Message Templates",
                groups: ["Text Messaging"],
                fullWidth: true
            },
            readyForPickupTexts: {
                title: "Ready for Pickup Texts",
                groups: ["Text Messaging"]
            },
            disableWebNotifications: {
                title: "Disable System Presented Notifications",
                groups: ["Notifications"],
                help: "Disables presenting notifications using native operating system notifications (ie, Windows notifications)."
            },
            orderCancellationReasons: {
                type: "string-array",
                groups: ["Orders"]
            },
            requireLotsOnInventoryBatches: {
                groups: ["Inventory"]
            },
            inventoryBatchSubmitEndpoint: {
                groups: ["Inventory"],
                help: "Pushes inventory batch data including any lots and expiry dates to provided endpoint."
            }
        };
    }

    private openGroups: Array<string> = [];

    mounted() {
        this.fetchConfiguration();
    }

    get configKeys() {
        return Object.keys(this.configuration)
            .filter(i => this.fields[i]?.hidden !== true);
    }

    get configKeysGrid() {
        return Object.keys(this.configuration)
            .filter(i => this.fields[i]?.hidden !== true && !this.fields[i]?.fullWidth);
    }

    get configKeysFullWidth() {
        return Object.keys(this.configuration)
            .filter(i => this.fields[i]?.hidden !== true && this.fields[i]?.fullWidth);
    }

    get canSave() {
        return this.$user?.isAdmin;
    }

    formatConfigName(name: string): string {
        const explicitTitle = this.fields[name]?.title;
        if (explicitTitle) return explicitTitle;

        let resp = name.replace(/([A-Z])/g, " $1");
        resp = resp.charAt(0).toUpperCase() + resp.slice(1);
        resp = resp.replace(" Api ", " API ");

        return resp;
    }

    getConfigType(name: string): string {
        const explicitType = this.fields[name]?.type;
        if (explicitType) return explicitType as string;

        return this.configuration.typeOf(name);
    }

    async fetchConfiguration() {
        try {
            this.loading = true;
            const fetchResponse = await axios.get<Configuration>("/Configuration");
            this.configuration = new Configuration(fetchResponse.data);
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        } finally {
            this.loading = false;
        }
    }

    async saveConfiguration() {
        try {
            this.loading = true;
            const saveResponse = await axios.post<Configuration>("/Configuration", this.configuration);
            this.configuration = new Configuration(saveResponse.data);
            this.$notification(NotificationOptions.successSaveNotificationPreset(entityName));
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        } finally {
            this.loading = false;
        }
    }

    getGroupsForKey(key: string): string[] {
        const fieldDef = this.fields[key];
        if (!fieldDef) return ["Other"];
        if (fieldDef.group) return [fieldDef.group];
        if (fieldDef.groups) return fieldDef.groups;
        if (Array.isArray(fieldDef)) return fieldDef;
        return [fieldDef];
    }

    get configurationGroups() {
        // @ts-ignore
        return [...new Set(this.configKeys.flatMap(i => this.getGroupsForKey(i)))].toSorted();
    }

    getKeysForGroup(group: string, fullWidth: boolean) {
        if (fullWidth) {
            return this.configKeysFullWidth.filter(i => this.getGroupsForKey(i).includes(group));
        }
        return this.configKeysGrid.filter(i => this.getGroupsForKey(i).includes(group));
    }

    toggleGroup(group: string) {
        if (this.openGroups.includes(group)) {
            this.openGroups = this.openGroups.filter(og => og !== group);
        } else {
            this.openGroups.push(group);
        }
    }

    toggleGroups() {
        if (this.openGroups.length) {
            this.openGroups = [];
        } else {
            this.openGroups = [...this.configurationGroups];
        }
    }

}
</script>

<style scoped>
.group {
    box-shadow: none;
}

.group:first-child {
    margin-top: 0;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr));
    gap: 15px;
    margin-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

h5 {
    cursor: pointer;
    margin-bottom: 0;
}

.actions {
    position: sticky;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(8px);
    z-index: 900;
}

@media screen and (min-width: 1200px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>
