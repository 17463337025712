
::v-deep(.in-alert-col) {
    width: 50px;
    text-align: center;
}

::v-deep(.in-alert-row) {
    font-weight: bold;
}

::v-deep(.actions-col) {
    width: 110px;

    >div {
        display: flex;
        gap: 5px;
    }

}
