<template>
    <div class="user-component">
        <card title="User Permissions">
            <b-row>
                <b-col>
                    <b-table id="user-table" class="text-center" style="cursor:pointer" striped outlined slectable
                        responsive="true" :items="users" selected-variant="warning" @row-clicked="rowClicked" :fields="tableFields">
                        <template #cell(userName)="data">
                            {{ data.value }}
                        </template>
                        <template #cell(initials)="data">
                            {{ data.value }}
                        </template>
                        <template #cell()="data">
                            <i v-if="data.value" class="fas fa-check-square text-success" />
                            <i v-else class="fas fa-times text-danger" />
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </card>
        <b-modal id="user-modal" @ok="handleOk" title="User Management">
            <strong>{{ selectedUser?.userName }}</strong>

            <hr>

            <b-form-group label="Initials" label-for="initials">
                <b-form-input id="initials" name="initials" v-model="selectedUser && selectedUser.initials"
                    maxlength="2" />
            </b-form-group>

            <hr>

            <form ref="form">
                <b-form-group label="Is Admin" label-for="admin-checkbox">
                    <b-form-checkbox id="admin-checkbox" name="admin-checkbox"
                        v-model="selectedUser && selectedUser.isAdmin" />
                </b-form-group>
                <b-form-group label="Is Manager" label-for="manager-checkbox">
                    <b-form-checkbox id="manager-checkbox" name="manager-checkbox"
                        v-model="selectedUser && selectedUser.isManager" />
                </b-form-group>
                <b-form-group label="Is Pharmacist" label-for="pharmacist-checkbox">
                    <b-form-checkbox id="pharmacist-checkbox" name="pharmacist-checkbox"
                        v-model="selectedUser && selectedUser.isPharmacist" />
                </b-form-group>
                <b-form-group label="Is Patients Manager" label-for="patient-manager-checkbox">
                    <b-form-checkbox id="patient-manager-checkbox" name="patient-manager-checkbox"
                        v-model="selectedUser && selectedUser.isPatientsManager" />
                </b-form-group>
                <b-form-group label="Is Inventory Manager" label-for="inventory-manager-checkbox">
                    <b-form-checkbox id="inventory-manager-checkbox" name="inventory-manager-checkbox"
                        v-model="selectedUser && selectedUser.isInventoryManager" />
                </b-form-group>
                <b-form-group label="Is Read Only" label-for="readonly-checkbox">
                    <b-form-checkbox id="readonly-checkbox" name="readonly-checkbox"
                        v-model="selectedUser && selectedUser.isReadOnly" />
                </b-form-group>
                <b-form-group label="Is Compound Manager" label-for="compound-checkbox">
                    <b-form-checkbox id="compound-checkbox" name="compound-checkbox"
                        v-model="selectedUser && selectedUser.isCompoundManager" />
                </b-form-group>
                <b-form-group label="Is PAP Approval" label-for="pap-checkbox">
                    <b-form-checkbox id="pap-checkbox" name="pap-checkbox"
                        v-model="selectedUser && selectedUser.isPapApproval" />
                </b-form-group>
            </form>

            <hr>

            <b-form-group label="InfiniTRAK API Key">
                <b-form-input v-model="selectedUser && selectedUser.infinitrakApiKey"
                    maxlength="64" />
            </b-form-group>

        </b-modal>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator'
import axios from 'axios';
import { User } from '@/models/User';

@Component({
    name: "UserComponent",
    components: {
    }
})
export default class UserComponent extends Vue {
    created() {
        console.log(`%c Created ${this.$options.name}`, "color: green");
        this.fetchAllUsers();
    }

    beforeDestroy() {
        console.log(`%c Destroying ${this.$options.name}`, "color: red");
    }

    private selectedUser: User | null = null;
    private users: User[] = [];

    async fetchAllUsers() {
        try {
            const response = await axios.get<User[]>(`/User`);
            this.users = response.data;
        } catch {
            //ignore
        }
    }

    rowClicked(user: User) {
        this.selectedUser = user;
        this.$bvModal.show("user-modal");
    }

    async handleOk(bvModalEvent: any) {
        bvModalEvent.preventDefault();

        try {
            const response = await axios.post(`/User/SaveCallback`, this.selectedUser);
            const responseUser = response.data;
            this.users = this.users.map(u => u.userName == responseUser.userName ? responseUser : u);
            this.$nextTick(() => {
                this.selectedUser = {} as unknown as User;
                this.$bvModal.hide("user-modal");
            });
        } catch {
            //ignore
        }
    }

    get tableFields() {
        return [
            "userName",
            "initials",
            "isAdmin",
            "isSuperAdmin",
            "isManager",
            "isPharmacist",
            "isPatientsManager",
            "isInventoryManager",
            "isReadOnly",
            "isCompoundManager",
            { key: "isPapApproval", label: "Is PAP Approval" }
        ]
    }
}
</script>

<style scoped>
form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
</style>
