// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
.progress-container{
    position: relative;

    & + .progress-container,
    & ~ .progress-container{
        margin-top: $margin-base-vertical;
    }
    .progress-badge{
        color: $default-color;
        font-size: $font-size-small;
        text-transform: uppercase;

    }

    .progress {
        height: 1px;
        border-radius: 0;
        box-shadow: none;
        background: $opacity-gray-5;
        margin-top: 14px;

        .progress-bar {
            box-shadow: none;
            background-color: $default-color;
        }

        .progress-value{
            position: absolute;
            top: 2px;
            right: 0;
            color: $default-color;
            font-size: $font-size-small;
        }
    }

    &.progress-neutral{
        .progress{
            background: rgba(255, 255, 255, .3);
        }

        .progress-bar{
            background: $white-bg;
        }

        .progress-value,
        .progress-badge{
            color: $white-color;
        }
    }

    &.progress-primary{
        .progress{
            background: $primary-color-opacity;
        }

        .progress-bar{
            background: $brand-primary;
        }

        .progress-value,
        .progress-badge{
            color: $brand-primary;
        }
    }

    &.progress-info{
        .progress{
            background: $info-color-opacity;
        }

        .progress-bar{
            background: $brand-info;
        }

        .progress-value,
        .progress-badge{
            color: $brand-info;
        }
    }

    &.progress-success{
        .progress{
            background: $success-color-opacity;
        }

        .progress-bar{
            background: $brand-success;
        }

        .progress-value,
        .progress-badge{
            color: $brand-success;
        }
    }

    &.progress-warning{
        .progress{
            background: $warning-color-opacity;
        }

        .progress-bar{
            background: $brand-warning;
        }

        .progress-value,
        .progress-badge{
            color: $brand-warning;
        }
    }

    &.progress-danger{
        .progress{
            background: $danger-color-opacity;
        }

        .progress-bar{
            background: $brand-danger;
        }

        .progress-value,
        .progress-badge{
            color: $brand-danger;
        }
    }
}
