<template>
    <div>
        <div v-show="!disabled">
            <b-row align-v="end">
                <b-col cols="6">
                    <search-component v-model="drug" label="Drug" search-u-r-l="/Drug" :params="{ onlyActive: false }"
                        :disabled="disabled" clearable />
                </b-col>
                <b-col>
                    <fg-input label="Start Date">
                        <el-date-picker v-model="otherMedication.startDate" format="MM/dd/yyyy" type="date"
                            :disabled="disabled" />
                    </fg-input>
                </b-col>
                <b-col>
                    <fg-input label="End Date">
                        <el-date-picker v-model="otherMedication.endDate" format="MM/dd/yyyy" type="date"
                            :disabled="disabled" :picker-options="datePickerOptions(otherMedication.startDate)" />
                    </fg-input>
                </b-col>
                <b-col cols="1">
                    <b-button variant="success" @click="addDrug" :disabled="disabled">Add</b-button>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col>
                <b-table ref="other-medication-table" api-url="/api/OtherMedication" :items="tableProvider"
                    :fields="tableFields" :filter="tableFilter" select-mode="single" selectable selected-variant=""
                    no-select-on-click>
                    <template #cell(endDate)="row">
                        <fg-input v-if="row.rowSelected">
                            <el-date-picker v-model="row.item.endDate" format="MM/dd/yyyy" type="date"
                                :picker-options="datePickerOptions(row.item.startDate)" :disabled="disabled" />
                        </fg-input>
                        <div v-else>
                            {{ row.value }}
                        </div>
                    </template>
                    <template #cell(edit)="row">
                        <b-button variant="link" @click="edit(row)" :disabled="disabled">
                            <b-icon variant="info" icon="clipboard-data" />
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>

    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import SearchComponent from "@/components/SearchComponent.vue";
import { Patient } from "@/models/Patient";
import axios, { AxiosRequestConfig } from "axios";
import { OtherMedication, OtherMedicationViewModel } from "@/models/Drug/OtherMedication";
import { DatePicker } from "element-ui";
import { Drug } from "@/models/Drug/Drug";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import { BTable, BvTableCtxObject, BvTableFieldArray } from "bootstrap-vue";
import moment from "moment";
import { DatePickerOptions } from "element-ui/types/date-picker";

@Component({
    components: {
        SearchComponent,
        [DatePicker.name]: DatePicker,
    }
})
export default class OtherMedicationsComponent extends Vue {
    drug: Drug = new Drug();
    @Prop({ required: true }) patient!: Patient;
    @Prop({ default: false, type: Boolean }) disabled!: boolean;
    @Prop({ default: false, type: Boolean }) onlyActive!: boolean;
    @Ref("other-medication-table") medTable!: BTable;
    otherMedication: OtherMedication = {
        startDate: new Date(),
    } as OtherMedication;

    async edit(row: any) {
        if (row.rowSelected) {
            row.unselectRow();
            await axios.post("/api/OtherMedication", row.item);
            this.medTable.refresh();
        } else {
            this.medTable.clearSelected();
            row.selectRow();
        }
    }

    async addDrug() {

        if (!this.patient?.id) {
            this.$notification(NotificationOptions.error("Must save new patient before adding a drug"));
            return;
        }

        if (!this.drug.drugId) {
            this.$notification(NotificationOptions.error("Select a drug first"));
            return;
        }

        this.otherMedication.drugId = this.drug.drugId;
        this.otherMedication.source = this.drug.source;
        this.otherMedication.patientId = this.patient.id;

        try {
            await axios.post(`/api/OtherMedication`, this.otherMedication);
            this.medTable.refresh();
        } catch (err) {
            this.$notification(NotificationOptions.error(err));
        }
    }

    datePickerOptions(startDate: any): DatePickerOptions {
        return {
            disabledDate(date) {
                return moment(date).isBefore(startDate);
            }
        };
    }

    get tableFields(): BvTableFieldArray {
        return [
            { key: "patientName" },
            { key: "drugName" },
            { key: "startDate", formatter: value => moment(value).format("L") },
            { key: "endDate", formatter: value => value ? moment(value).format("L") : "", class: "w100" },
            { key: "edit", label: "", class: this.disabled ? "d-none" : "", },
        ];
    }

    get tableFilter() {
        const config: AxiosRequestConfig = { params: { patientId: this.patient.id } };
        return config;
    }

    async tableProvider(ctx: BvTableCtxObject) {
        const uri = ctx.apiUrl + "";
        const config = ctx.filter as AxiosRequestConfig;
        try {
            const resp = await axios.get<OtherMedicationViewModel[]>(uri, config);
            if (!this.onlyActive) return resp.data;
            return resp.data.filter(d => moment(d.startDate).isSameOrBefore() && (!d.endDate || moment(d.endDate).isAfter()));
        } catch (err) {
            return [];
        }
    }

    @Watch("patient")
    resetDrug() {
        this.drug = new Drug();
    }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
>>>.w100 {
    width: 200px;
}
</style>
