<template>
    <component :is="modal ? 'b-modal' : 'div'" id="price-test-modal" title="Drug Price Test" centered
        @hide="$emit('hide')" no-fade hide-footer size="lg">

        <b-form @submit.prevent="getPrice">
            <SearchComponent v-if="showSchedulePicker" v-model="selectedPriceSchedule" label="Price Schedule"
                search-u-r-l="/PriceSchedule" prepopulate clearable />
            <b-form-group v-else-if="priceSchedule" label="Price Schedule:">
                <b-input :value="priceSchedule.name" disabled />
            </b-form-group>

            <search-component v-model="drug" label="Drug" :params="{ onlyActive: true }" search-u-r-l="/api/Drug" />

            <b-form-group label="Quantity:">
                <b-form-input type="number" v-model.number="quantity" min="0" step=".001" />
            </b-form-group>

            <b-button type="submit" :disabled="!formIsValid">Get Price</b-button>
        </b-form>

        <div v-if="!error && loaded && priceData">
            <hr>
            <b-row>
                <b-col>
                    <strong>Total Price:</strong>
                    <h4 class="my-0">
                        {{
            priceData.totalPrice.toLocaleString("en-US", {
                style: "currency", currency: "USD",
                minimumFractionDigits: 2
            })
        }}
                    </h4>
                </b-col>
                <b-col>
                    <strong>Unit Price:</strong>
                    <h4 class="my-0">
                        {{ priceData.unitPrice.toLocaleString("en-US", {
            style: "currency", currency: "USD",
            minimumFractionDigits: 2
        }) }}
                    </h4>
                </b-col>
            </b-row>

            <hr>
            <h6>Quantity Pricing for {{ drug.productNameShort }}</h6>
            <QuantityPricingComponent :price-schedule="scheduleToUse" :default-drug="drug" :hide-title="true"
                :highlight-quantity="priceData.quantity" :on-add="onAdd" @added="getPrice" @edited="getPrice"
                @removed="getPrice" />
        </div>

        <div v-if="error">
            <hr>
            <b-alert show variant="danger">
                {{ error }}
            </b-alert>
        </div>
    </component>
</template>

<script lang="ts">
import { Drug } from '@/models/Drug/Drug';
import { DrugProduct } from '@/models/Drug/DrugProduct';
import { PriceSchedule } from '@/models/PriceSchedule';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SearchComponent from "@/components/SearchComponent.vue";
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import QuantityPricingComponent from "@/components/Drug/QuantityPricingComponent.vue";

const lastPriceScheduleIdStorageKey = "price-test-selected-schedule-id";

@Component({
    name: "PriceTestModalComponent",
    components: {
        SearchComponent,
        QuantityPricingComponent
    }
})
export default class PriceTestModalComponent extends Vue {

    @Prop({ type: PriceSchedule }) protected priceSchedule!: PriceSchedule;
    @Prop({ required: false }) onAdd?: Function;
    @Prop({ default: true }) protected modal: boolean = true;

    showSchedulePicker = false;
    selectedPriceSchedule = new PriceSchedule();

    drug: DrugProduct = new Drug();
    quantity: number = 0;

    priceData?: any = {};
    error?: string = "";
    loaded: boolean = false;

    mounted() {
        this.$bvModal.show("price-test-modal");
        if (!this.priceSchedule) {
            const lastPriceScheduleId = localStorage.getItem(lastPriceScheduleIdStorageKey);
            if (lastPriceScheduleId) {
                this.selectedPriceSchedule.id = Number(lastPriceScheduleId);
            }
            this.showSchedulePicker = true;
        }
    }

    get scheduleToUse() {
        return this.priceSchedule ? this.priceSchedule : this.selectedPriceSchedule;
    }

    async getPrice() {
        if (!this.drug.id) {
            this.priceData = undefined;
            this.error = undefined;
            return;
        }

        try {
            const params: any = {
                priceScheduleId: this.scheduleToUse.id,
                drugId: this.drug.id,
                quantity: this.quantity,
                drugSource: this.drug.source,
            }

            const config = {
                params: params
            } as AxiosRequestConfig;

            const priceData = (await axios.get(`/api/PriceSchedule/price-preview`, config)).data;
            this.priceData = priceData;
            this.priceData.unitPrice = priceData.totalPrice / params.quantity;
            this.priceData.quantity = params.quantity;
            this.error = "";
            this.loaded = true;
        } catch (err) {
            this.error = (err as AxiosError)?.response?.data.message ?? "There was an unknown error";
        }
    }

    get formIsValid() {
        return this.quantity > 0 && this.drug?.id;
    }

    @Watch("selectedPriceSchedule")
    setLastSelectedPriceSchedule() {
        localStorage.setItem(lastPriceScheduleIdStorageKey, this.selectedPriceSchedule.id.toString());
    }

}

</script>

<style scoped>
::v-deep(tr.table-success) {
    font-weight: bolder;
}
</style>
