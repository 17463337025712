
.child-notes {
    font-size: .88em;
    margin-left: 27px;
    margin-top: -10px;
    margin-bottom: 10px;
}

.reply-form {
    margin-left: -15px;
    margin-right: -15px;
}

.show-more-replies {
    cursor: pointer;
    background: white;
    color: rgba(black, .8);
    border-color: rgba(black, .5);
    margin-right: -15px;
    border-bottom-right-radius: 0;

    &:hover {
        color: black;
        border-color: black;
    }
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
    position: absolute;
}
